var mappings = {step2 : {'manufacturers' : [], 'ids': {}, 'jobs':{}, 'hierarchy' : {}}, step3 : {'filters' : [], 'parts' : []}};
var isFiltering = false;
var logicalFilter = [];
function removeLogicalFilter(id){
    for(var i = logicalFilter.length-1; i>=0; i-- ){
        for(var j = logicalFilter[i].filters.length-1; j>=0; j-- ){
            if (id == logicalFilter[i].filters[j]) {
                logicalFilter[i].filters.splice(j, 1);
            }
        }
        if(!logicalFilter[i].filters.length){
            logicalFilter.splice(i, 1);
        }
    }
}

var SortPartsBy = new function(){ // Develop as pluginable
    var _ = this;
    function ASC($a,$b,$type){
        function s(a,b){
            if(isNaN(a) && isNaN(b)) return a < b ? -1 : a > b ? 1 : 0; // Text compare
            a = parseFloat(a);
            b = parseFloat(b);
            if(!a) a = Infinity; // NO Price available always in the end
            if(!b) b = Infinity;
            if(a == b) return 0; // Compare Infinity
            return a-b;
        }

        if($type)
            return s($a.dataset[$type], $b.dataset[$type]);
        else
            for(var i in type){
                var r = 0;
                if(type[i].startsWith('-')){
                    var st = type[i].substring(1);
                    r = DESC($a,$b,st);
                }   
                else
                    r = s($a.dataset[type[i]], $b.dataset[type[i]]);
                if(r!=0) return r;
            }
        return 0;
    }
    function DESC($a,$b,$type){
        function s(a,b){
            if(isNaN(a) && isNaN(b)) return a < b ? 1 : a > b ? -1 : 0; // Text compare
            a = parseFloat(a);
            b = parseFloat(b);
            if(!a) a = -Infinity; // NO Price available always in the end
            if(!b) b = -Infinity;
            if(a == b) return 0; // Compare Infinity
            return b-a; 
        }
        if($type)
            return s($a.dataset[$type], $b.dataset[$type]);
        else
            for(var i in type){
                var r = 0;
                if(type[i].startsWith('-')){
                    var st = type[i].substring(1);
                    r = ASC($a,$b,st);
                }   
                else
                    r = s($a.dataset[type[i]], $b.dataset[type[i]]);
                if(r!=0) return r;
            }
        return 0;
    }

    var type = ["manufacturer","partnumber"] //dataset partnumber, unitprice ...
    var sort = ASC;
    jQuery('document').ready(function() {
        jQuery('.js-container-sort select').on('change',function(){
            type = this.value.split(',');
            sort = $(this).find(':selected').data('order')=='DESC'?DESC:ASC;
            _.sort();
        });
        jQuery('.js-container-sort-store select').on('change',function(){
            type = this.value.split(',');
            sort = $(this).find(':selected').data('order')=='DESC'?DESC:ASC;
            _.sort('.js-map-stores-holder');
        });
        jQuery('.js-container-sort select').trigger('change');
    });
    _.sort = function(dom){
        if(dom != undefined){
            var elements = [];
            $(dom).children('.js-part').each(function(){
                elements.push(this);
                //$(this).remove();
            })
            elements.sort(sort);
            elements.forEach(function(el){
                $(dom).append(el);
            });
        }
        else{
            $('.js-parttype').each(function(){
                if($(this).children('.js-parttype-header').children('.fa-plus-circle').length) return;
                _.sort(this);
            })
        }
    }
}

var hasExpanded = false;
jQuery('document').ready(function() {
    /*****************************************************
     * SETTINGS PRODUCT DIRECTORY
     ****************************************************/
      jQuery('.js-settings--catalog .js-manufacturers-list').on('click', '.js-container-droplist-part-type', function(event) {
        dropdown_icon = jQuery(this).find('.js-dropdown-icon');
        var brand = jQuery(this).find('.name').text();
        if(jQuery('.js-btn--save-catalog').length) {
            disabled = 0;
        } else {
            disabled = 1;
        }
        showPartType(event, dropdown_icon, brand, disabled);
    });
    jQuery('.js-settings--catalog .js-brand-item').on('click',  function(){
        item_check = jQuery(this).siblings('.js-chk');
        brand = item_check.val();
        if(!jQuery(this).is(':indeterminate')){
            //jQuery(this).siblings('.js-partial-selection').addClass('hide');
            checkbox.prop('indeterminate', false);
            item_check.prop("checked", false);
            jQuery('.js-brand-delete').append(jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"]'));
            removeBrandUserPref(brand);
        } else {
            item_check.prop("checked", !item_check.prop("checked"));
            itemCheck(item_check);
            setTimeout(function() {
                if(item_check.prop("checked") == true){
                   addUserPref(brand, item_check);
                   restoreBrand(brand);
                } else {
                    target = jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"]');
                    deleteBrand(target);
                    removeBrandUserPref(brand);
                }
            },1);
        }
        toggleSaveButton();
    });
    jQuery('.js-settings--catalog').on('change', '.js-dropdown-manufacturer-chk', function(){
        brand = jQuery(this).data('name');
        parttype = jQuery(this).attr('id');
        brand_added = 0;
        if (jQuery(this).is(':checked')){
            restorePart(parttype, brand);
            if (jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"]').length == 0) {
                var targetList = jQuery(".js-brands-selected .js-brand-active");
                jQuery(targetList).each(function() {
                    //check alpha order
                    if (jQuery(this).data('brand').toString().toLowerCase() > brand.toString().toLowerCase()) {
                        //append brand
                        jQuery('<div data-brand="' + brand + '" class="js-brand-active container-fluid">' +
                            '<span class="text-bold col-sm-8 col-xs-8 px0 mt1 pb1 js-toggle-list hover"><i class="fa fa-plus-circle"></i> ' +
                            brand + '<i class="fa fa-cog fa-spin hide color--primary"></i></span>' +
                            '<span class="col-sm-4 col-xs-4 px0 mt1 pb1"><i class="fa fa-trash pull-right js-btn-delete-brand"></i>' +
                            '</span></div>').insertBefore(jQuery(this));
                        brand_added = 1;
                        return false;
                    }
                });
                //append brand last
                if(brand_added == 0) {
                    jQuery('.js-brands-selected .box--gray').append('<div data-brand="' + brand +
                        '" class="js-brand-active container-fluid"><span class="text-bold col-sm-8 col-xs-8 px0 mt1 pb1 js-toggle-list hover">'+
                        '<i class="fa fa-plus-circle"></i> ' + brand +
                        '<i class="fa fa-cog fa-spin hide color--primary"></i></span><span class="col-sm-4 col-xs-4 px0 mt1 pb1">'+
                        '<i class="fa fa-trash pull-right js-btn-delete-brand"></i></span></div>');
                }
            }
            // append brand to tmp add list
            if (jQuery('.js-brand-add [data-brand="' + brand + '"]').length == 0) {
                 jQuery('.js-brand-add').append('<div data-brand="' + brand + '" class="js-brand-active container-fluid">' +
                        '<span class="text-bold col-sm-8 col-xs-8 px0 mt1 pb1 js-toggle-list hover"><i class="fa fa-plus-circle"></i> ' +
                        brand + '<i class="fa fa-cog fa-spin hide color--primary"></i></span>' +
                        '<span class="col-sm-4 col-xs-4 px0 mt1 pb1"><i class="fa fa-trash pull-right js-btn-delete-brand"></i>' +
                        '</span></div>');
            }
            if(jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"] .js-part').hasClass("hide")){
                class_hide = "hide";
            } else {
               class_hide = "";
            }
            //append parttype under brand
            jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"], .js-brand-add [data-brand="' + brand +
                '"]').append('<div class="mt1 js-part ' + class_hide + '" data-part="' +  parttype +
                '"><span class="col-sm-8 col-xs-8 px0 pb1" data-part="' +   parttype + '">' +  parttype + '</span>'+
                '<span class="col-sm-4 col-xs-4 px0 pb1"><i class="fa fa-trash pull-right js-btn-delete-part"></i></span></div>');
        } else {
            delete_brand_clone = jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"]').clone().appendTo(".js-brand-delete");
            delete_item = jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"] .js-part[data-part="' + parttype + '"]');
            delete_brand_clone.empty();
            delete_item.appendTo(delete_brand_clone);
            jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"] div[data-part="' + parttype + '"],  .js-brand-add [data-brand="' + brand + '"] div[data-part="' + parttype + '"]').remove();
            if (jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"] div').length == 0) {
                jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"], .js-brand-add [data-brand="' + brand + '"]').remove();
                jQuery('.js-chk[value="' + brand + '"]').prop('checked', false);
            }
        }
        toggleBrandCheckbox(brand);
        toggleSaveButton();
    });
    //DELETE ALL BRANDS
    jQuery('.js-settings--catalog').on('click', '.js-btn--reset-catalog', function() {
        new popupConfirmation(__("popup.reset_directory.text"), function(e){
            jQuery('.js-icon-loading,.js-mobile-overlay').show();
            jQuery.ajax({
                url: '/ajax/resetlinecode',
                dataType: 'JSON',
                type: 'POST',
                success: function(data){
                    location.reload();
                },
            });
            //$('.js-btn-delete-brand').trigger('click');
        },$(this).text().trim())
        //
    })
    //DELETE BRAND
    jQuery('.js-settings--catalog').on('click', '.js-btn-delete-brand', function() {
        var target = jQuery(this).parent().parent();
        deleteBrand(target);
        toggleSaveButton();
     });
     //DELETE PART
     jQuery('.js-settings--catalog').on('click', '.js-btn-delete-part', function() {
        var target = jQuery(this).parent().parent();
        deletePart(target);
        toggleSaveButton();
     });
     jQuery('.js-tabs').on('click', '.js-tab', function() {
         tab = jQuery(this).data('tab');
         jQuery('.js-tab').removeClass('active');
         jQuery('.js-tab[data-tab=' + tab + ']').addClass('active');
         jQuery('.js-brands-all, .js-brands-selected').addClass('hide');
         jQuery('.js-' + tab).removeClass('hide');
     });
     if (jQuery(window).width() < 768 && Tools.IsMobile()) {
         jQuery('.js-brands-selected').addClass('hide');
    }
    jQuery('.js-settings--catalog').on('change', '.js-toggle-mode', function() {
        if(!jQuery('.js-brands-all').hasClass('hide')) {
            jQuery('.js-brands-selected, .js-mode-all').removeClass('hide');
            jQuery('.js-brands-all, .js-mode-selected').addClass('hide');
        } else {
            jQuery('.js-brands-selected, .js-mode-all').addClass('hide');
            jQuery('.js-brands-all, .js-mode-selected').removeClass('hide');
        }
    });
    jQuery('.js-settings--catalog').on('click', '.js-toggle-list', function() {
        jQuery('.js-brands-selected').find('.js-part').addClass('hide');
        if (jQuery(this).find('.fa').not('.fa-cog').hasClass('fa-minus-circle')) {
            jQuery(this).parent().find('.js-part').addClass('hide');
            jQuery(this).find('.fa').not('.fa-cog').addClass('fa-plus-circle').removeClass('fa-minus-circle');
        } else {
            jQuery('.js-brands-selected').find('.fa').not('.fa-cog').addClass('fa-plus-circle').removeClass('fa-minus-circle');
            jQuery(this).parent().find('.js-part').removeClass('hide');
            jQuery(this).find('.fa').not('.fa-cog').removeClass('fa-plus-circle').addClass('fa-minus-circle');
        }
    });
    jQuery('.js-settings--catalog').on('click', '.js-btn--save-catalog', function() {
        if(!jQuery(this).hasClass('disabled')){
            var preferences = new Array();
            jQuery(this).find('span').addClass('hide');
            jQuery(this).find('.fa-cog').removeClass('hide');
            jQuery('.js-settings--catalog .js-brand-add .js-brand-active').each(function() {
                brand = jQuery(this).data('brand');
                jQuery(this).find('.js-part').each( function() {
                    var preference_el = new Array();
                    parttype = jQuery(this).find('span[data-part]').data("part");
                    linecode = '';
                    preference_el.push(brand);
                    preference_el.push(parttype);
                    preference_el.push(linecode);
                    visibility = 0;
                    preference_el.push(visibility);
                    preferences.push(preference_el);
                });
            });
            jQuery('.js-settings--catalog .js-brand-delete .js-brand-active').each(function() {
                brand = jQuery(this).data('brand');
                jQuery(this).find('.js-part').each( function() {
                    var preference_el = new Array();
                    parttype = jQuery(this).find('span[data-part]').data("part");
                    linecode = '';
                    preference_el.push(brand);
                    preference_el.push(parttype);
                    preference_el.push(linecode);
                    visibility = 2;
                    preference_el.push(visibility);
                    preferences.push(preference_el);
                });
            });
            jQuery.ajax({
                url: '/ajax/addlinecode',
                dataType: 'JSON',
                type: 'POST',
                data: {
                    preferences : preferences
                },
                success: function(data){
                    jQuery(".js-btn--save-catalog").find('span').removeClass('hide');
                    jQuery(".js-btn--save-catalog").find('.fa-cog').addClass('hide');
                    jQuery('.js-settings--catalog .js-brand-add, .js-settings--catalog .js-brand-delete').empty();
                },
                error: function() {
                    jQuery(".js-btn--save-catalog").find('span').removeClass('hide');
                    jQuery(".js-btn--save-catalog").find('.fa-cog').addClass('hide');
                    jQuery('.js-settings--catalog .js-brand-add, .js-settings--catalog .js-brand-delete').empty();
                },
                done: function() {
                    jQuery(".js-btn--save-catalog").find('span').removeClass('hide');
                    jQuery(".js-btn--save-catalog").find('.fa-cog').addClass('hide');
                    jQuery('.js-settings--catalog .js-brand-add, .js-settings--catalog .js-brand-delete').empty();
                }
            });
        }
    });

    jQuery('.js-settings--catalog').on('change', '.js-chk', function(){
        checkbox = jQuery(this);
        brand = checkbox.val();
        setTimeout(function() {
            if(checkbox.prop("checked") == true){
               addUserPref(brand, item_check);
               restoreBrand(brand);
            } else {
                    target = jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"]');
                deleteBrand(target);
                removeBrandUserPref(brand);
            }
        },1);
        toggleSaveButton();
    });

    jQuery('.js-bestseller').on('click', function(e){
        e.preventDefault();
        var modal = jQuery('.js-container-search-tool--modal');
        var background = jQuery('.js-container-search-tool--overlay');
        
        background.fadeIn();
        modal.fadeIn();
        //changeTab(jQuery('.js-tab-default'));

        if(jQuery(this).data('url')){
            var current_url =  modal.find('.js-vehiculedefinition').parent().data('url');
            modal.find('.js-vehiculedefinition').parent().data('url', jQuery(this).data('url'));
            var resetUrl = function (){
                modal.find('.js-vehiculedefinition').parent().data('url', current_url);
                modal.find('.js-close-modal-best-seller').off('click',resetUrl);
            }
            modal.find('.js-close-modal-best-seller').on('click',resetUrl)
        }

        modal.find('#parttype-modal').val(jQuery(this).data('parttype'));
        modal.find('#manufacturer-modal').val(jQuery(this).data('manufacturer'));
        modal.find('#category-modal').val(jQuery(this).data('category'));
        modal.find('#subcategory-modal').val(jQuery(this).data('subcategory'));
    });

    jQuery('.js-container-search-tool--overlay').on('click',function(){
        jQuery(this).fadeOut();
        jQuery('.js-container-search-tool--modal').fadeOut(); 
    })

    /****************
     * Step 2 OTTO
     */
    $('[data-key-name-button]').click(function(){
        var keyName=$(this).attr('data-key-name-button');
        var htmlContent=$('[data-displayer-key-name="'+keyName+'"]').html();
        var sixId=$(this).attr('data-six-row-displayer');
        var threeId=$(this).attr('data-three-row-displayer');
        var twoId=$(this).attr('data-two-row-displayer');
        var tenId=$(this).attr('data-ten-row-displayer');

        var isActive=$(this).hasClass('active');

        if(isActive){
            $('[data-key-name-button]').removeClass('active');
        }
        else{
            $('[data-key-name-button]').removeClass('active');
            $(this).addClass('active');
        }

        $("[data-ten-row-displayer-id='"+tenId+"']").html(htmlContent)
        $("[data-six-row-displayer-id='"+sixId+"']").html(htmlContent)
        $("[data-three-row-displayer-id='"+threeId+"']").html(htmlContent)
        $("[data-two-row-displayer-id='"+twoId+"']").html(htmlContent)

        if(isActive){
            closeSection('data-ten-row-displayer-id',tenId);
            closeSection('data-six-row-displayer-id',sixId);
            closeSection('data-three-row-displayer-id',threeId);
            closeSection('data-two-row-displayer-id',twoId);
        }
        else{
            expandSection('data-ten-row-displayer-id',tenId);
            expandSection('data-six-row-displayer-id',sixId);
            expandSection('data-three-row-displayer-id',threeId);
            expandSection('data-two-row-displayer-id',twoId);
        }
        initSubcatJS();
    });

    function initSubcatJS(){
        $('[data-sub-category-key-name]').click(function(){
            var targetedElem = $(this).find('.displayer__content__sub-category__sub-container');
            var wasActive = targetedElem.hasClass("active");
            $('.displayer__content__sub-category__sub-container').removeClass("active");
            $('[data-sub-category-key-name]').removeClass("active");
            $('.displayer__content__sub-category__sub-container').css('height','0');

            if(!wasActive){
                $(this).addClass("active");
                targetedElem.addClass("active");
                targetedElem.css('height','auto');
                var heightCalc = targetedElem.height();

                targetedElem.css('height','0');
                setTimeout(function(){
                    targetedElem.css('height', heightCalc + 'px');
                }
                ,1);
            }
        });
        $('[data-parttype-name-key-name-button]').click(function(){
            $uniqueKey=$(this).attr('data-parttype-name-key-name-button');
    
            $("[data-parttype-name-key-name-checkbox='"+$uniqueKey+"']").click();
            $('.js-getresults').click();
        });
    }

    $('[data-manufacturer-button]').click(function(e){
        e.preventDefault();
        var keyName=$(this).attr('data-manufacturer-button');
        var htmlContent=$('[data-displayer-manufacturer="'+keyName+'"]').html();
        var sixId=$(this).attr('data-ten-row-manufactirer-displayer-button-id');
        var threeId=$(this).attr('data-six-row-manufactirer-displayer-button-id');
        var twoId=$(this).attr('data-three-row-manufactirer-displayer-button-id');
        var parttypes=$(this).attr('data-parttypes');

        $("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").html("<i class=\"fa fa-circle-o-notch fa-spin fa-fw\"></i>");
        $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").html("<i class=\"fa fa-circle-o-notch fa-spin fa-fw\"></i>");
        $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").html("<i class=\"fa fa-circle-o-notch fa-spin fa-fw\"></i>");

        var isActive=$(this).hasClass('selected');

        if(isActive){
            $('[data-manufacturer-button]').removeClass('selected');
        }
        else{
            $('[data-manufacturer-button]').removeClass('selected');
            $('[data-manufacturer-button="'+keyName+'"]').addClass('selected');
        }




        if(isActive){
            closeSection('data-ten-row-manufactirer-displayer-id',sixId);
            closeSection('data-six-row-manufactirer-displayer-id',threeId);
            closeSection('data-three-row-manufactirer-displayer-id',twoId);
        }
        else{
            expandSection('data-ten-row-manufactirer-displayer-id',sixId);
            expandSection('data-six-row-manufactirer-displayer-id',threeId);
            expandSection('data-three-row-manufactirer-displayer-id',twoId);
        }
        //setTimeout(function(){$("[data-manufacturer-button='"+keyName+"']").find('.js-container-droplist-part-type').click();},1000)
        $("[data-manufacturer-button='"+keyName+"']").find('.js-container-droplist-part-type').each(function(){

            dropdown_icon = jQuery(this).find('.js-dropdown-icon');

            htmlContent = showDisplayerPartType(event, dropdown_icon,$());

        });

        /*$(htmlContent).click('[data-part-type-id]',function(){
            idTriger=$(this).attr('data-part-type-id');
            $('.js-dropdown-manufacturer').find("[value='"+idTriger+"']").click();
            setTimeout(function(){
                $('.js-getresults').click();
            },100)
        });*/

        $("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").html(htmlContent);
        $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").html(htmlContent);
        $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").html(htmlContent);

        $("[data-ten-row-manufactirer-displayer-id='"+sixId+"'],[data-six-row-manufactirer-displayer-id='"+threeId+"'],[data-three-row-manufactirer-displayer-id='"+twoId+"']")
            .off()
            .on('click', '[data-part-type-id]', function(){
                idTriger=$(this).attr('data-part-type-id');
                $('.js-dropdown-manufacturer').find("[value='"+idTriger+"']").click();
                setTimeout(function(){
                    $('.js-getresults').click();
                },100)
            })
        ;

        var minHeight=0;
        var elemHeight;
        /*$("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").find('.displayer__content__manufacturer__parttype').each(function(){
            elemHeight=$(this).height();
            if(elemHeight>minHeight)minHeight=elemHeight;
            $("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").find('.displayer__content__manufacturer__parttype').css('min-height',(minHeight+22)+'px')
        });
        var minHeight=0;
        var elemHeight;
        $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").find('.displayer__content__manufacturer__parttype').each(function(){
            elemHeight=$(this).height();
            if(elemHeight>minHeight)minHeight=elemHeight;
            $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").find('.displayer__content__manufacturer__parttype').css('min-height',(minHeight+22)+'px')
        });
        var minHeight=0;
        var elemHeight;
        $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").find('.displayer__content__manufacturer__parttype').each(function(){
            elemHeight=$(this).height();
            if(elemHeight>minHeight)minHeight=elemHeight;
            $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").find('.displayer__content__manufacturer__parttype').css('min-height',(minHeight+22)+'px')
        });*/


        initSubcatJS();
        //htmlContent = getPartsContent(parttypes);
    })

    function expandSection(sectionData,sectionId){
        $('['+sectionData+']').removeClass('active');
        $('['+sectionData+'="'+sectionId+'"]').addClass('active');
    }
    function closeSection(sectionData,sectionId){
        $('['+sectionData+'="'+sectionId+'"]').removeClass('active');
    }

    /* ********************************* */

    function toggleBrandCheckbox(brand) {
        total_part = jQuery('.js-dropdown-manufacturer .js-dropdown-manufacturer-chk').length;
        total_part_checked = jQuery('.js-dropdown-manufacturer .js-dropdown-manufacturer-chk:checked').length;
        /*if(total_part_checked == 0) {
            jQuery('.js-chk[value="'+ brand + '"]').prop('checked', false).prop('indeterminate',false);
            //jQuery('.js-chk[value="'+ brand + '"]').next('.js-partial-selection').addClass('hide');
        } else if(total_part_checked == total_part) {
            jQuery('.js-chk[value="'+ brand + '"]').prop('indeterminate',false).prop('checked', true);
            //jQuery('.js-chk[value="'+ brand + '"]').next('.js-partial-selection').addClass('hide');
        } else {
            jQuery('.js-chk[value="'+ brand + '"]').prop('checked', false).prop('indeterminate',true);
            //jQuery('.js-chk[value="'+ brand + '"]').next('.js-partial-selection').removeClass('hide');
        }*/
    }
    function restoreBrand(target) {
        restore_brand_name = target;
        jQuery(".js-brand-delete [data-brand='" + restore_brand_name + "']").remove();
    }
    function restorePart(target, target_brand) {
        restore_part_name = target;
        restore_brand_name = target_brand;
        jQuery(".js-brand-delete [data-brand='" + restore_brand_name + "'] [data-part='" + restore_part_name + "']").remove();
        jQuery(".js-brand-delete [data-brand='" + restore_brand_name + "']").each(function(){
            if(jQuery(this).find('.js-part').length == 0)
                jQuery(this).remove();
        });
    }
    function deleteBrand(target) {
        delete_brand = target;
        delete_brand_name = delete_brand.data("brand");
        jQuery(delete_brand).appendTo(".js-brand-delete");
        jQuery(".js-manufacturers-list .js-chk[value='"+delete_brand_name+"']").prop("checked", false);
        jQuery(".js-manufacturers-list .js-chk[value='"+delete_brand_name+"']").next().next().addClass("hide");
    }
    function deletePart(target) {
        delete_item = target;
        delete_brand = target.parent();
        delete_brand_name = delete_brand.data("brand");
        delete_brand_clone = jQuery(delete_brand).clone().appendTo(".js-brand-delete");
        delete_brand_clone.empty();
        delete_item.appendTo(delete_brand_clone);
        jQuery(".js-chk[value='" + delete_brand_name + "']").prop('checked', false);
        if(delete_brand.find('.js-part').length == 0){
            jQuery(".js-chk[value='" + delete_brand_name + "']").prop('indeterminate',false);//.next(".js-partial-selection").addClass("hide");
            delete_brand.remove();
        } else {
            jQuery(".js-chk[value='" + delete_brand_name + "']").prop('indeterminate',true);//.next(".js-partial-selection").removeClass("hide");
        }
    }
    function removeBrandUserPref(brand) {
        jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"], .js-brand-add [data-brand="' + brand + '"]').remove();
    }
    function addUserPref(brand, checkbox) {
        var brand_added = 0;
        if (checkbox.is(':checked')){
            if (jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"]').length == 0 || true) {
                var targetList = jQuery(".js-brands-selected .js-brand-active");
                jQuery(targetList).each(function() {
                    if (jQuery(this).data('brand').toString().toLowerCase() > brand.toString().toLowerCase()) {
                        jQuery('<div data-brand="' + brand + '" class="js-brand-active container-fluid">'+
                            '<span class="text-bold col-sm-8 col-xs-8 px0 mt1 pb1 js-toggle-list hover"><i class="fa fa-plus-circle"></i> ' +
                            brand + '<i class="fa fa-cog fa-spin hide color--primary"></i></span><span class="col-sm-4 col-xs-4 px0 mt1 pb1">'+
                            '<i class="fa fa-trash pull-right js-btn-delete-brand"></i>'+
                            '</span></div>').insertBefore(jQuery(this));
                            jQuery('.js-brand-add').append('<div data-brand="' + brand + '" class="js-brand-active container-fluid">'+
                            '<span class="text-bold col-sm-8 col-xs-8 px0 mt1 pb1 js-toggle-list hover"><i class="fa fa-plus-circle"></i> ' +
                            brand + '<i class="fa fa-cog fa-spin hide color--primary"></i></span><span class="col-sm-4 col-xs-4 px0 mt1 pb1">'+
                            '<i class="fa fa-trash pull-right js-btn-delete-brand"></i>'+
                            '</span></div>');
                        brand_added = 1;
                        return false;
                    }
                });
                if(brand_added == 0) {
                    jQuery('.js-brands-selected .box--gray, .js-brand-add').append('<div data-brand="' + brand + '" class="js-brand-active container-fluid">'+
                        '<span class="text-bold col-sm-8 col-xs-8 px0 mt1 pb1 js-toggle-list hover"><i class="fa fa-plus-circle"></i> ' + brand +
                        '<i class="fa fa-cog fa-spin hide color--primary"></i></span><span class="col-sm-4 col-xs-4 px0 mt1 pb1">'+
                        '<i class="fa fa-trash pull-right js-btn-delete-brand"></i></span></div>');
                }
                getPartData(brand);
            }
            for(var i = 0; i < data.length; i++) {
                jQuery('.js-brands-selected .box--gray [data-brand="' + brand + '"], .js-brand-add [data-brand="' + brand + '"]').append('<div class="mt1 js-part hide"  data-part="' +  data[i].name + '"><span class="col-sm-8 px0 pb1" data-part="' +  data[i].name + '">' +  data[i].name + '</span><span class="col-sm-4 px0 pb1"><i class="fa fa-trash pull-right js-btn-delete-part"></i></span></div>');
            }
        } else {
            jQuery('.jjQuery(\'.js-brands-selected .box--gray [data-brand="\' + brand + \'"s-brands-selected .box--gray [data-brand="' + brand + '"]').remove();
        }
    }
    function toggleSaveButton() {
          if(jQuery('.js-brands-selected .js-brand-active').length > 0) {
            jQuery('.js-btn--save-catalog').removeClass('disabled');
            jQuery('.js-brands-selected-text').addClass('hide');
        } else {
            jQuery('.js-btn--save-catalog, .js-brands-selected-text').addClass('disabled');
            jQuery('.js-brands-selected-text').removeClass('hide');
        }
    }
    /*****************************************************
     * STEP 2
     ****************************************************/
    function itemCheck(item_check){
        var checked = item_check.is(':checked');
        item_check.prop('indeterminate',false);//.parent().find('.js-partial-selection').addClass('hide')
        jQuery('.js-categories .js-manufacturer .js-chk[value="' + item_check.val() + '"]').each(function(){
            jQuery(this).prop('checked', checked);
        });
        new Analytic.Event('search-engine','itemCheck',item_check.val(), checked?'checked':'');
        updateStep2FiltersState();
    }
    function containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].name === obj.name) {
                return true;
            }
        }
        return false;
    }
    function getPartData(name) {
        data = [];
        for(var key in mappings.step2.manufacturers) {
            if (mappings.step2.manufacturers[key].name == name) {
                for(var i = 0; i < mappings.step2.manufacturers[key].info.pt.length; i++) {
                    for(var ptName in mappings.step2.ids) {
                        if (mappings.step2.ids[ptName] == mappings.step2.manufacturers[key].info.pt[i]) {
                            obj_to_validate = {'name' : ptName.replace('pt-', ''), 'id' : mappings.step2.ids[ptName]};
                            if(containsObject(obj_to_validate, data) == false)
                                data.push(obj_to_validate);
                        }
                    }
                }
            }
        }
        data.sort(function(a, b){return a.name.localeCompare(b.name);});
        return data;
    }



    function showPartType(event, dropdown_icon, brand, disabled){
        $('.js-loading-overlay').removeClass('hide');
        event.stopPropagation();
        var name = dropdown_icon.closest('.js-manufacturer').find('.js-chk').first().val();
        var original = jQuery('.js-dropdown-template');
        var panel = original.clone();
        var currentPos = dropdown_icon.offset();
        var framePos = {x:currentPos.left - original.width() + 15, y: (jQuery(window).height() / 2) - 150};
        var diff_posY = window.innerHeight - (currentPos.top - jQuery(window).scrollTop());
        var diff_posX = window.innerWidth - (currentPos.left - jQuery(window).scrollLeft());
        var disabled_class = disabled;
        jQuery('.js-dropdown-manufacturer').remove();
        jQuery('.js-parttype-pop-header, .js-parttype-pop-footer').css('display', 'none');
        panel_top = framePos.y;
        panel.css('top', panel_top + 'px');
        panel.removeClass('js-dropdown-template');
        panel.addClass('js-dropdown-manufacturer');
        var rowTemplate = panel.find('.js-row-template');
        var select;
        rowTemplate.removeClass('js-row-template');
        if (jQuery(window).width() > 480) {
            var header_width = '500px';
        } else {
            var header_width = '85%';
        }
        if (!Tools.IsMobile()) {
            jQuery('.js-mobile-overlay').css('z-index', 201);
        }
        jQuery('.js-icon-loading').show();
        jQuery('.js-mobile-overlay').fadeIn(500, function() {

            data = [];
            for(var key in mappings.step2.manufacturers) {
                if (mappings.step2.manufacturers[key].name == name) {
                    for(var i = 0; i < mappings.step2.manufacturers[key].info.pt.length; i++) {
                        for(var ptName in mappings.step2.ids) {
                            if (mappings.step2.ids[ptName] == mappings.step2.manufacturers[key].info.pt[i]) {
                                obj_to_validate = {'name' : ptName.replace('pt-', ''), 'id' : mappings.step2.ids[ptName]};
                                if(containsObject(obj_to_validate, data) == false)
                                    data.push(obj_to_validate);
                            }
                        }
                    }
                }
            }
            data.sort(function(a, b){return a.name.localeCompare(b.name);});
            //return data;

            var count_checked = 0;
            var count_checked_settings = 0;
            var searchData ='';
            var select;
            var htmlContent='';
            var wordIntelligentSearchIndex=[];
            var idsIntelligentSearchIndex=[];
            for(var i = 0; i < data.length; i++) {

                // var row = rowTemplate.clone();
                // var checkbox = row.find('.js-dropdown-manufacturer-chk');
                // var label = row.find('.js-name');
                // row.find('.js-name').attr('for', data[i].name).attr('data-id-for', data[i].id);
                // checkbox.val(data[i].id);
                // checkbox.attr('data-name', name);
                // checkbox.attr('id', data[i].name);
                // checkbox.attr('data-brand', name);
                // checkbox.attr('data-parttypeID', data[i].name);
                // checkbox.attr('data-id', data[i].id);
                // checkbox.attr('data-text', data[i].name);
                // checkbox.attr('data-map', 'intelligent-search');
                if(brand) {
                    var isChecked = jQuery(".js-brands-selected .js-brand-active[data-brand='" + brand +"']").find(".js-part[data-part='" + data[i].name + "']").length > 0;
                } else {
                    var isChecked =  jQuery('.js-categories .js-parttype .js-chk[value="' + data[i].id +
                        '"]').closest('.js-container').find('.js-manufacturer').find('.js-chk[value="' + name + '"]').is(':checked');
                }

                if (jQuery(".js-brands-selected .js-brand-active[data-brand='" + brand + "']").find(".js-part[data-part='" + data[i].name + "']").length > 0) {
                    count_checked++;
                }
                if(jQuery(".js-brand-active #" + data[i].id).length > 0){
                    //checkbox.prop('checked', true);
                    var isChecked = true;
                    count_checked_settings++;
                }

                ///set index


                $.each( data[i].name.split(' '), function( index, value ) {

                    if( jQuery.inArray( value, wordIntelligentSearchIndex) == -1){
                        wordIntelligentSearchIndex[wordIntelligentSearchIndex.length]=value;
                    }

                    //dsIntelligentSearchIndex

                    if(!idsIntelligentSearchIndex[jQuery.inArray( value, wordIntelligentSearchIndex)])
                        idsIntelligentSearchIndex[jQuery.inArray( value, wordIntelligentSearchIndex)] = data[i].id;
                    else
                        idsIntelligentSearchIndex[jQuery.inArray( value, wordIntelligentSearchIndex)] += ',' + data[i].id;

                });
                //row.find('.js-name').html(data[i].name);

                // if(data.length>8)
                //     searchData+="<output class='display-none' data-map='intelligent-search' data-brand='"+name+"' data-id='"+data[i].id+"'  data-text='"+data[i].name+"'/>";
                var disabled = jQuery("#manufacturer-"+brand+".js-chk").is(':disabled');
                htmlContent+=   "<div class='dropdown-template-row' data-dropdown-value='"+data[i].id+"'>" +
                                "<input ";
                                if(isChecked) htmlContent+=" checked ";
                                    htmlContent += (disabled?'disabled ':'')+"type='checkbox' class='js-dropdown-manufacturer-chk dropdown-manufacturer-chk input-hidden' value='"+data[i].id+"' " +
                                "id='"+data[i].name+"' data-name='"+name+"' data-brand='"+name+"' data-parttypeid='"+data[i].name+"' data-id='"+data[i].id+"' data-text='"+data[i].name+"' data-map='intelligent-search'>" +
                                "<label class=\"input-checkbox--fake\" for='"+data[i].name+"'></label>"+
                                "<label class='js-name dropdown-manufacturer-label' for='"+data[i].name+"' data-id-for='"+data[i].id+"'>"+data[i].name+"</label>" +
                                "</div>";
            }

            panel.find('.scrollable-body').append(htmlContent);
            panel.hide();



            if(data.length>8){
                select= searchData +
                    "<input id='intelligent-search' class='dropdown-template__search' placeholder='"+__('Keywords')+"'>" +
                    "<script>" +
                    "var wordIntelligentSearchIndex = [";

                $.each( wordIntelligentSearchIndex, function( index, value ) {
                    select=select+ "'"+value.toLowerCase()+"',";
                });

                select=select+ "];var idsIntelligentSearchIndex = [";


                $.each( idsIntelligentSearchIndex, function( index, value ) {
                    select+= "'"+value+"',";
                });
                select=select+ "];</script>";

                select=select+ "<script>" +
                    "var intelligentSearchTimer=null;" +

                    "$('#intelligent-search').change(function(){" +"var searchOn=true;" +

                    "}).keyup(function(){" +
                    "   var elemText=$(this).val();" +
                    "           console.log(elemText.length);" +
                    "           initIntelligentSearch(elemText,wordIntelligentSearchIndex,idsIntelligentSearchIndex,true,intelligentSearchTimer);" +
                    "});" +
                    "function initIntelligentSearch(elemText,wordIntelligentSearchIndex,idsIntelligentSearchIndex,searchOn,intelligentSearchTimer){" +
                    "   var numSearch = 500;" +
                    "   console.log(wordIntelligentSearchIndex.length*idsIntelligentSearchIndex.length);" +
                    "if(wordIntelligentSearchIndex.length*idsIntelligentSearchIndex.length>15000){" +
                    "       $('.search-partstypes-loader').removeClass('display-none');" +
                    "       if(intelligentSearchTimer) intelligentSearchTimer=null;" +
                    "       intelligentSearchTimer = setTimeout(function(){" +
                    "               searchOn=true;" +
                    "               intelligentSearch(elemText, $(\"[data-map='intelligent-search']\"),wordIntelligentSearchIndex,idsIntelligentSearchIndex,searchOn);" +
                    "           },100);" +
                    "   }" +
                    "else" +
                    "   {" +
                    "       if(intelligentSearchTimer) intelligentSearchTimer=null;" +
                    "               $('.search-partstypes-loader').removeClass('display-none');" +
                    "               intelligentSearch(elemText, $(\"[data-map='intelligent-search']\"),wordIntelligentSearchIndex,idsIntelligentSearchIndex,searchOn);" +
                    "   }" +
                    "}" +
                    "function intelligentSearch( text, intelligentSearchId,wordIntelligentSearchIndex,idsIntelligentSearchIndex,searchOn){" +
                    "   var textsSearch = text.split(' ');" +
                    "   var valuesToShow='';" +
                    "   var idToShow;" +
                    "       $('#intelligent-search')" +
                    "         .keyup(function(){" +
                    "           searchOn =false;" +
                    "       });" +

                    "   if(searchOn)" +
                    "   $.each( textsSearch, function( index, value ) {" +
                    "       var found = wordIntelligentSearchIndex.filter(function (item) {" +
                    "                        return item.indexOf(value) >= 0;" +
                    "                    });" +
                    "       var aValuesToShow;" +
                    "       if(searchOn)" +
                    "       $.each( found, function(i,v){" +
                    "           aValuesToShow=idsIntelligentSearchIndex[$.inArray( v, wordIntelligentSearchIndex)];" +

                    "           if(!valuesToShow){" +
                    "                   filteredValuesToShow=idsIntelligentSearchIndex[$.inArray( v, wordIntelligentSearchIndex)];" +
                    "           }" +
                    "           else{" +
                    "                   filteredValuesToShow=valuesToShow+idsIntelligentSearchIndex[$.inArray( v, wordIntelligentSearchIndex)];" +
                    "           }" +
                    "          valuesToShow=filteredValuesToShow;" +

                    "       });" +
                    "   });" +
                    "   var valuesToShowArray = valuesToShow.split(',');" +
                    "   $('[data-dropdown-value]').addClass('display-none');" +

                    "$.each( valuesToShowArray,function(index, value){" +

                    "   $(\"[data-dropdown-value='\" + value + \"']\").removeClass('display-none');" +
                    "   $(\"[data-dropdown-value='\" + value + \"']\").find('.js-name').each(function(){" +

                    "   var textBolded=$(this).attr('for');" +
                    "   textBolded=textBolded.toLowerCase();" +

                    "       $.each( textsSearch, function( ii, vv ) {" +
                    "           if(vv) textBolded=textBolded.replace( vv.toLowerCase(), '<b>' + vv.toLowerCase() + '</b>');" +
                    "       });" +

                    "       $(this).html(textBolded)" +


                    "   });" +

                    "});" +

                    "if(true){" +

                    "               $('.search-partstypes-loader').addClass('display-none');" +


                    "}" +
                    "else{" +
                    "$('.dropdown-template-row').removeClass('display-none');" +
                    "}" +
                    "}" +
                    //"$('.dropdown-template').addClass('active-search');" +
                    //"$('.parttype-pop-footer').addClass('parttype-pop-footer-active');" +
                    //"$('.js-parttype-pop-header').addClass('js-parttype-pop-header-active');" +
                    "$('.parttype-pop-footer-active').css('top',$(window).height()/2+$(window).height()/2-108);" +
                    "setTimeout(function(){$('#intelligent-search').focus();},200);" +
                    " $('.js-loading-overlay').addClass('hide');" +
                    "</script>" +
                    "" +
                    "<i class=\"search-partstypes-loader display-none fa fa-cog fa-spin fa-fw\"></i>" +
                    "";
            }

            else select="<script>" +
                    "$('.parttype-pop-footer').removeClass('parttype-pop-footer-active');" +
                    "$('.dropdown-template').removeClass('active-search');" +
                    " $('.js-loading-overlay').addClass('hide');" +
                    "</script>";
                $('.js-get-brand').html(name);
                panel.find('.fixed').prepend(select);
                panel.fadeIn(200);
                $( window ).resize(function() {
                    $('.parttype-pop-footer-active').css('top',$(window).height()/2+$(window).height()/2-116);
                    $('.active-search').css('top',$(window).height()/2-158);
                    $('.js-parttype-pop-header-active').css('top',$(window).height()/2-197);
                    setTimeout(function(){
                        $('.js-mobile-overlay').css('display','block');
                        $('.js-mobile-overlay').css('opacity','1');
                    },400);
            });

            rowTemplate.remove();
            part_type_header = jQuery('.parttype-pop-header');
            part_type_header.addClass('js-parttype-pop-header');
            part_type_header.css('top', panel_top - 40 + 'px');
            part_type_header.css('position', 'fixed');
            part_type_header.css('z-index', '201');
            part_type_header.css('width',  header_width);
            part_type_header.css('padding',  '10px');
            part_type_header.find('h4').css('display', 'inline-block');
            part_type_header.find('.slick-lightbox-close').css({'top' : '-5px', 'width' : '0', 'height' : '0', 'right' : '40px'});
            part_type_footer = jQuery('.parttype-pop-footer');
            part_type_footer.addClass('js-parttype-pop-footer');
            if(disabled_class == 1) {
               part_type_footer.addClass('disabled');
            }
            part_type_footer.addClass('btn');
            part_type_footer.css('top', panel_top + 250 + 'px');
            part_type_footer.css('position', 'fixed');
            part_type_footer.css('z-index', '201');
            part_type_footer.css('font-weight', 'bold');
            part_type_footer.css('width',  header_width);
            part_type_footer.css('padding',  '10px');
            part_type_footer.css('text-align', 'center');
            part_type_header.css('display',  'none');
            part_type_footer.css('display',  'none');
            jQuery('.js-submenu-target').append(panel).before(part_type_header.fadeIn(200));
            jQuery('.js-submenu-target').append(panel).before(part_type_footer.fadeIn(200));
             jQuery('.js-icon-loading').hide();
        });
    }

    function showDisplayerPartType(event, dropdown_icon){
        event.stopPropagation();
        jQuery('.js-dropdown-manufacturer').remove();
        jQuery('.js-parttype-pop-header, .js-parttype-pop-footer').css('display', 'none');
        var name = dropdown_icon.closest('.js-manufacturer').find('.js-chk').first().val();
        var original = jQuery('.js-step2 .js-dropdown-template');
        var panel = original.clone();
        var currentPos = dropdown_icon.offset();
        var framePos = {x:currentPos.left - original.width() + 15, y:currentPos.top - 150};
        var diff_posY = window.innerHeight - (currentPos.top - jQuery(window).scrollTop());
        var diff_posX = window.innerWidth - (currentPos.left - jQuery(window).scrollLeft());
        var header_width = '500px';
        
        if (jQuery(window).width() > 768) {
             panel_left = framePos.x;
             if (diff_posY < 400) {
                panel_top = framePos.y - 430;
             } else {
                panel_top = framePos.y + 50;
             }
        } else if(jQuery(window).width() > 480) { 
            if (diff_posX > 270) {
                panel_left = framePos.x + 348;
             } else {
                panel_left = framePos.x;
             }
             if (diff_posY < 300) {
                panel_top = framePos.y - 460;
             } else {
                panel_top = framePos.y - 20;
             }

        } else {
            panel_top = jQuery(window).scrollTop() + 50;
            panel_left = 20;
            header_width = '85%';
          
        }

       

        panel.css('top', panel_top + 'px');
        panel.css('left', panel_left + 'px');
       
        panel.removeClass('js-dropdown-template');
        panel.addClass('js-dropdown-manufacturer').addClass('display-none');
        var rowTemplate = panel.find('.js-row-template');
        rowTemplate.removeClass('js-row-template');
        var data = [];

        for(var key in mappings.step2.manufacturers)
        {
            if (mappings.step2.manufacturers[key].name == name)
            {
                for(var i = 0; i < mappings.step2.manufacturers[key].info.pt.length; i++)
                {
                    for(var ptName in mappings.step2.ids)
                    {
                        if (mappings.step2.ids[ptName] == mappings.step2.manufacturers[key].info.pt[i])
                        {
                            data.push({'name' : ptName.replace('pt-', ''), 'id' : mappings.step2.ids[ptName]});
                        }
                    }
                }
            }
        }

        data.sort(function(a, b){return a.name.localeCompare(b.name);});
        var htmlContent='';
        for(var i = 0; i < data.length; i++)
        {
            var row = rowTemplate.clone();
            var checkbox = row.find('.js-dropdown-manufacturer-chk');
            var label = row.find('.js-name');
            checkbox.val(data[i].id);
            checkbox.attr('data-name', name);
            checkbox.attr('id', data[i].name);
            label.attr('for', data[i].name);
            checkbox.prop('checked', jQuery('.js-step2 .js-categories .js-parttype .js-chk[value="' + data[i].id + '"]').closest('.js-container').find('.js-manufacturer').find('.js-chk[value="' + name + '"]').is(':checked'));
            row.find('.js-name').html(data[i].name);
            htmlContent+="<div class='displayer__content__manufacturer__parttype' data-part-type-id='"+data[i].id+"'>"+data[i].name+"</div>";
            panel.append(row).hide().fadeIn(200);
        }
        rowTemplate.remove();

        part_type_header = jQuery('.parttype-pop-header');
        part_type_header.addClass('js-parttype-pop-header');
        part_type_header.css('left', panel_left + 'px');
        part_type_header.css('top', panel_top - 40 + 'px');
        part_type_header.css('position', 'absolute');
        part_type_header.css('z-index', '99');
        part_type_header.css('background-color', '#DCDCDC');
        part_type_header.css('width',  header_width); 
        part_type_header.css('padding',  '10px');
        part_type_header.find('h4').css('display', 'inline-block');
        part_type_header.find('.slick-lightbox-close').css({'top' : '-5px', 'width' : '0', 'height' : '0', 'right' : '40px'});
        part_type_header.find('.slick-lightbox-close').addClass('override-color-gray');

        part_type_footer = jQuery('.parttype-pop-footer'); 
        part_type_footer.addClass('js-parttype-pop-footer').addClass('display-none');
        part_type_footer.css('left', panel_left + 'px');
        part_type_footer.css('top', panel_top + 300 + 'px');
        part_type_footer.css('position', 'absolute');
        part_type_footer.css('z-index', '99');
        part_type_footer.css('background-color', '#eb2131');
        part_type_footer.css('color', '#fff');
        part_type_footer.css('font-weight', 'bold');
        part_type_footer.css('width',  header_width); 
        part_type_footer.css('padding',  '10px');
        part_type_footer.css('text-align', 'center');
        part_type_footer.css('cursor', 'pointer');
        
        part_type_header.css('display',  'none');
        part_type_footer.css('display',  'none');
        jQuery('.js-step2').append(panel).before(part_type_header.fadeIn(200));
        jQuery('.js-step2').append(panel).before(part_type_footer.fadeIn(200));

       // if (Tools.IsMobile())
           // jQuery('.js-mobile-overlay').fadeIn();
        return htmlContent;
    }

    function partialSelect(t, checkbox, name) {
        if (t.parent().parent().find('input[type="checkbox"]:checked').length == 0) {
            checkbox.prop('checked', false);
            //jQuery('.js-manufacturers-list .js-chk[value="' + name + '"]').prop('indeterminate',false).prop('checked', false);//.parent().find('.js-partial-selection').addClass('hide');
        } else if (t.parent().parent().find('input[type="checkbox"]:checked').length === t.parent().parent().find('input[type="checkbox"]').length) {
            checkbox.prop('checked', true);
            //jQuery('.js-manufacturers-list .js-chk[value="' + name + '"]').prop('indeterminate',false).prop('checked', true);//.parent().find('.js-partial-selection').addClass('hide');
            jQuery('.js-parttype-pop-footer').removeClass('disabled');
        } else {
            checkbox.prop('checked', true);
            /*if(!jQuery('.js-chk[value="' + name + '"]').parent().find('.js-chk').hasClass('hide')) {
                jQuery('.js-manufacturers-list .js-chk[value="' + name + '"]').prop('indeterminate',true).prop('checked', true);//.parent().find('.js-partial-selection').removeClass('hide');
            }*/
            jQuery('.js-parttype-pop-footer').removeClass('disabled');
        }
    }
    /**
     * Maps the submenu events of the cateogries list (opens/closes dropdown)
     */
    jQuery('.js-step2, .js-step3').on('click', '.js-submenu', function(){
        var target = jQuery(this).parent().find(jQuery(this).data('target'));
        var icon = jQuery(this).find('.js-icon');
        if (target.hasClass('js-closed')) {
            target.slideDown('fast', function(){
                jQuery(this).removeClass('js-closed');
            });
            icon.removeClass('fa-angle-right');
            icon.addClass('fa-angle-down');
            new Analytic.Event('search-engine','click',$(this).text(),'open')
        } else {
            target.slideUp('fast', function(){
                jQuery(this).addClass('js-closed');
            });
            icon.removeClass('fa-angle-down');
            icon.addClass('fa-angle-right');
            new Analytic.Event('search-engine','click',$(this).text(),'close');
        }
    });
    jQuery('.js-step2 .js-categories, .js-step3 .js-categories').on('click', 'input[type=checkbox],.input-checkbox--fake', function(event){
        event.stopPropagation();
    });
    /**
     * Binds the manufacturers with the categories
     */
    jQuery('.js-step2, .js-settings--catalog').find('.js-searchmap').each(function() {
        var data = jQuery(this).val();
        data = atob(data);
        data = jQuery.parseJSON(data);
        mappings.step2.manufacturers.push(data);
    });
    jQuery('.js-step2, .js-settings--catalog').find('.js-namemap').each(function() {
        var data = jQuery(this).val();
        data = atob(data);
        data = jQuery.parseJSON(data);
        mappings.step2.ids = data;
    });

    jQuery('.js-step2 .js-manufacturers-list').on('click', '.js-brand-item', function() {
      item_check =  jQuery(this).siblings('.js-chk');
      item_check.prop("checked", !item_check.prop("checked"));
      itemCheck(item_check);
      item_check.prop('indeterminate',false);
      //jQuery('.js-partial-selection').addClass('hide');
    });
    jQuery('.js-step2 .js-manufacturers-list').on('click', '.js-container-droplist-part-type', function(event){
        dropdown_icon = jQuery(this).find('.js-dropdown-icon');
        new Analytic.Event('search-engine','showPartType',$(this).find('.name').text().trim());
        showPartType(event, dropdown_icon);
    });
    jQuery('.page-template-tpl-search-php, .page-template-tpl-product-directory-php').on('click', '.js-parttype-pop-footer, .js-parttype-pop-header .js-close', function() {
        jQuery('.js-mobile-overlay').fadeOut();
        jQuery('.js-parttype-pop-footer, .js-parttype-pop-header, .js-dropdown-manufacturer, .js-mobile-overlay').css('display', 'none');
    });
     jQuery('.js-step2 .js-manufacturers-list, .js-settings--catalog .js-manufacturers-list').on('change', '.js-chk', function() {
        item_check =  jQuery(this);
        itemCheck(item_check);
    });
    jQuery('.js-step2 .js-categories, .js-settings--catalog .js-categories').on('change', '.js-chk', function(e) {
        var checked = jQuery(this).is(':checked');
        var categ = $(this).closest('.js-categories');
        new Analytic.Event('search-engine','js-categories',$(this).parent().children('label').text().trim(),checked?'checked':'');
        jQuery(this).closest('.js-container').find('.js-chk').prop('checked', checked);
        jQuery(this).closest('.js-container').find('.js-chk[data-type=manufacturer]').each(function() {
            var val = $(this).val();
            var tot = categ.find('.js-chk[data-type=manufacturer][value="'+val+'"]').length;
            var chks = categ.find('.js-chk[data-type=manufacturer][value="'+val+'"]:checked').length;
            if(tot == chks)
                jQuery('.js-manufacturers-list .js-chk[value="' + val + '"]').prop('indeterminate',false).prop('checked', true);
            else if(chks == 0)
                jQuery('.js-manufacturers-list .js-chk[value="' + val + '"]').prop('indeterminate',false).prop('checked', false);
            else
                jQuery('.js-manufacturers-list .js-chk[value="' + val + '"]').prop('indeterminate',true).prop('checked', true);
        });
        var shouldEnable = false;
        jQuery(this).parents('.js-container').each(function(){
            var checkbox = jQuery(this).find('.js-submenu').first().find('.js-chk');
            var indetermined = false;
            var t = jQuery(this);
            jQuery(this).find('.js-container').find('.js-chk').each(function() {
                if (jQuery(this).is(':checked') != checked) {
                    indetermined = true;
                }
            });
            checkbox.prop('checked', checked);
            checkbox.prop('indeterminate', indetermined);
            if (checked || indetermined) {
                shouldEnable = true;
                jQuery(this).find('.js-submenu').first().addClass('chk-selected');
            } else {
                jQuery(this).find('.js-submenu').first().removeClass('chk-selected');
            }
        });
        if (shouldEnable) {
            jQuery('.js-getresults').removeClass('disabled');
            jQuery('.js-reset').removeClass('disabled');
        } else {
            jQuery('.js-getresults').addClass('disabled');
            jQuery('.js-reset').addClass('disabled');
        }
    });
    jQuery('.js-step2').on('change', '.js-dropdown-manufacturer-chk', function() {
        var t = jQuery(this);
        var id = t.val();
        var name = t.data('name');
        var checkbox = jQuery('.js-categories .js-parttype .js-chk[value="' + id +
            '"]').closest('.js-container').find('.js-manufacturer').find('.js-chk[value="' + name + '"]');
        new Analytic.Event('search-engine','partialSelect',name+' - '+$(this).attr('id'), t.prop('checked')?'checked':'');
        console.log(checkbox);
        partialSelect(t, checkbox, name);
        checkbox.change();
    });
    jQuery('body').on('click', '.js-dropdown-manufacturer', function(event) {
        event.stopPropagation();
    });
    jQuery('body').on('click', function() {
        if (!Tools.IsMobile())  {
            $('.js-dropdown-manufacturer').css('display','none');
            jQuery('.js-parttype-pop-header, .js-parttype-pop-footer, .js-dropdown-manufacturer, .js-mobile-overlay').fadeOut();

        }
    });
    /**
     * Job change actions
     */
    jQuery(".js-step2").on("change", ".js-jobs-select", function() {
        // $("[data-loader-id='1']").addClass('active');
        // setTimeout(function(){
        //     $("[data-loader-id='1']").addClass('visible');
        // },100);
        jQuery('.js-chk').prop('checked', false);
        var e = jQuery(this)[0].selectedIndex;
        jQuery.each(mappings.step2.jobs[e], function (id, v) {
           var i = jQuery(".js-step2 .js-chk[data-type=parttype][value=" + v.PartType + "]");
            i = jQuery(i).closest("li").find(".js-chk[data-type=manufacturer][value='" + v.Manufacturer + "']");
            i.prop("checked", !0), i.change()
        });
        updateStep2FiltersState();

        if($('.categories__category').length){
            $('.js-getresults').click();
        }
        // setTimeout(function(){
        //     $("[data-loader-id='1']").removeClass('visible');
        //     setTimeout(function(){
        //         $("[data-loader-id='1']").removeClass('active');
        //     },500);
        // },3000);
    });
    jQuery(".js-step2").on("change", ".js-jobs-select-images", function() {
        var container = $(this).closest('.superswitch__b2c').find('.search-top-container__elements');
        if($(this).val() == ''){
            container.find('.search-top-container__element').removeClass('hide').removeClass('show');
            //$(this).closest('.superswitch__b2c').find('.js-displayer').removeClass('hide').change();
            $(this).parent().find('.js-reset-select').addClass('hide');
            updateJobBtn()
        }
        else{
            var $array = $(this).val().split(',');
            $(this).parent().find('.js-reset-select').removeClass('hide')
            container.find('.search-top-container__element').addClass('hide').removeClass('show');
            $(this).closest('.superswitch__b2c').find('.js-displayer').addClass('hide');
            for(var id in $array){
                var val = $array[id];
                $(this).closest('.superswitch__b2c').find('#jobID-'+val).removeClass('hide').addClass('show');
            }
        }
        container.css('justify-content',container.find('.search-top-container__element:not(.hide)').length>10?'left':'');
    })

    jQuery(".js-step2").on("change", ".js-jobs-urls", function(e) {
        window.location.href = $(this).val();
    })

    function updateJobBtn(){
        var btn = jQuery('.js-displayer');
        var container = btn.parent().parent();
        if(container.find('li').length>10 ) btn.removeClass('hide');
        
        var text = container.find('li.show').length>0?btn.data('less'):btn.data('more');
        btn.html(text);
    }

    jQuery(".js-step2").find('.js-displayer').click(function(){
        var scroll = document.documentElement.scrollTop?document.documentElement.scrollTop:document.body.scrollTop;
        jQuery(this).parent().parent().find('li:not(.always_on_display)').toggleClass('show');
        window.scrollTo(0,scroll);
        updateJobBtn();
    })

    updateJobBtn()


    jQuery(".js-step2").on("click", ".js-reset-select", function() {
       // $(this).addClass('hide');
        $(this).parent().find('select').val('').trigger('change');
    })
    /**
     * End of the step 2 by calling the step 3
     */
    jQuery('.js-step2, .js-step2-mobile').on('click', '.js-getresults', function() {
        new Analytic.Event('search-engine','getresults','step2');
        var $url = this.dataset.url != undefined ? this.dataset.url : '';
        var checks = jQuery('.js-step2 .js-categories .js-manufacturer .js-chk:checked');
        if (checks.length > 0) {
            jQuery('.js-loading-overlay').removeClass('hide');
            var form = jQuery('<form action="'+$url+'" method="POST"></form>');
            var data = [];
            checks.each(function() {
                var selection = {};
                selection.Manufacturer = jQuery(this).val();
                selection.PartTerminology = jQuery(this).closest('.js-parttype').find('.js-submenu').find('.js-chk').val();
                selection.SubCategory = jQuery(this).closest('.js-subcategory').find('.js-submenu').find('.js-chk').val();
                selection.Category = jQuery(this).closest('.js-category').data('id');
                selection.isNA = jQuery(this).data('isna') == '1';
                for(var name in mappings.step2.ids)
                {
                    if (mappings.step2.ids[name] == selection.PartTerminology) {
                        selection.PartTerminology = name.substr(3);
                    } else if (mappings.step2.ids[name] == selection.SubCategory) {
                        selection.SubCategory = name.substr(3);
                    } else if (mappings.step2.ids[name] == selection.Category) {
                        selection.Category = name.substr(3);
                    }
                }
                data.push(selection);
            });
            form.append(jQuery('<input />', {
                type : 'hidden',
                name : 'selection',
                value : JSON.stringify(data)
            }));
            form.append(jQuery('<input />', {
                type : 'hidden',
                name : 'token',
                value : jQuery('.js-step2 #token').val()
            }));
            jQuery('body').append(form);

            /*form.on('submit',function(e){
                e.preventDefault();
                $.ajax({
                    url: form.attr('action'),
                    method:'POST',
                    data: { 
                        'selection' : JSON.stringify(data),
                        'token' : jQuery('.js-step2 #token').val(),
                    },
                    success: function(rsp,status,xhr){
                        window.testxhr = xhr;
                        if(xhr.status == 280) {
                            if(rsp !=""){
                                console.log(rsp)
                                new popupConfirmation(rsp, xhr.getResponseHeader('Redirect'), 'Display All',false);
                            }
                            else
                                window.location = xhr.getResponseHeader('Redirect');
                        }
                        else if(xhr.status == 281)
                            window.location.reload();
                        else //Error append 
                            window.location.reload();
                    }
                });
            })*/
            form.submit();
        }
    });
    /**
     * Resets all the checkboxes for the step2
     */
    jQuery('.js-step2, .js-step2-mobile').on('click', '.js-reset', function() {
        new Analytic.Event('search-engine','reset','step2');
        jQuery('.js-step2 .js-chk').prop('checked', false).prop('indeterminate', false);
        //jQuery('.js-partial-selection').addClass('hide');
        jQuery('.js-step2 .js-categories .js-submenu.chk-selected').removeClass('chk-selected');
        jQuery('.js-step2 .js-reset, .js-step2 .js-getresults,  .js-step2-mobile .js-getresults, .js-step2-mobile .js-reset').addClass('disabled');
        jQuery('.js-subcategories, .js-parttypes, .js-manufacturers').addClass('js-closed').css('display', 'none').parent();
        var icon = jQuery('.js-submenu').find('.js-icon');
        icon.removeClass('fa-angle-down');
        icon.addClass('fa-angle-right');
        jQuery('html, body').animate({scrollTop : 0}, 800);
    });
    jQuery('.js-step2').on('change', '.js-na-check', function() {
        if (jQuery(this).prop('checked')) {
            setTimeout(function() {
                Tools.InsertParam('nap', '');
            }, 150);
        } else {
            setTimeout(function() {
                Tools.RemoveParam('nap');
            }, 150);
        }
    });
    /**
     * Part types list selection that will check items based on the user's selection
     */
    jQuery('.js-step2').on('change', '.js-parttype-select', function() {

        var is_multiple = false
        if($(this).hasClass('js-multiple')) is_multiple = true;
        var value = jQuery(this).val();
        var get_result = $(this).hasClass('get-result');
        if (value != '') {
            jQuery('.js-step2 .js-categories').first().find('.js-chk[value="' + value + '"]').each(function() {
                jQuery(this).prop('checked', true);
                jQuery(this).change();
                jQuery(this).parents('.js-container').each(function() {
                    if (jQuery(this).find('.js-level').first().hasClass('js-closed')){
                        jQuery(this).find('.js-submenu').first().click();
                    }
                });
            });
            var ddl = jQuery(this);
            if(get_result){
                $('.js-getresults').click();
            }
            else
            setTimeout(function() {
                ddl.find('option').prop('selected', false);
                ddl.find('option[value=""]').prop('selected', true);
                ddl.change();
            }, 50);
        }
    });
     jQuery('.js-step2 .js-jobs-select').find('option').each(function(index) {
        var data = jQuery(this).attr('data-mappings');
        if (data) {
            data = atob(data);
            data = jQuery.parseJSON(data);
            mappings.step2.jobs[index] = data;
        }
    });
    jQuery('.js-step2 .js-hierarchy').each(function() {
        var data = jQuery(this).val();
        if (data) {
            data = atob(data);
            data = jQuery.parseJSON(data);
            mappings.step2.hierarchy = data;
        }
    });
    /*****************************************************
     * STEP 3
     ****************************************************/
    /**
     * Toggle diagrams
     */
     jQuery(document).ready(function() {
        jQuery('.js-only-mobile-trigger').on('click',function(e){ if(document.body.clientWidth>768) e.preventDefault(); });
         jQuery('.js-filter-diagrams').on('change', 'input', function() {
            diagram_id = jQuery(this).val();
            jQuery('.js-diagram img,  .js-btn-expand').addClass('hide');
            jQuery('.js-parttype-header-diagram').remove();
            jQuery('.js-diagram img#' + diagram_id + ', .js-diagram .js-btn-expand-' + diagram_id).removeClass('hide');
         });
    });
      /**
     * Toggle attributes filters
     */
    jQuery('.js-step3').on('click', '.js-btn-see-more', function() {
        //jQuery('.js-step3').find('.js-toggle-attribute').fadeToggle();
        jQuery('.js-step3').find('.js-toggle-attribute').toggleClass('hide');
        jQuery('.js-step3').find('.js-label-see-more').toggleClass('hide');
    });
    /**
     * Binds the filters with the parts
     */
    jQuery('.js-step3').find('.js-mapping').each(function() {
        var data = jQuery(this).val();
        data = atob(data);
        data = jQuery.parseJSON(data);
        mappings.step3.filters.push(data);
    });
    /**
     * Binds the parts with the filters
     */
    jQuery('.js-step3').find('.js-part').each(function() {
        mappings.step3.parts[jQuery(this).attr('id')] = {
            mappings: jQuery(this).attr('data-ids'),
            visible: true,
            parttype: jQuery(this).attr('data-parttype')
        };
    });
    /**
     * Visual dropdowns of fits on
     */
    jQuery('.js-step3').on('click', '.js-fit-header', function() {
        var subfit = jQuery(this).closest('.js-fit').find('.js-sub-fit').first();
        var icon = jQuery(this).find('.js-icon-fit');
        if (subfit.hasClass('js-closed'))
        {
            subfit.slideDown('fast', function(){
                jQuery(this).removeClass('js-closed');
            });
            icon.removeClass('fa-angle-right');
            icon.addClass('fa-angle-down');
        } else {
            subfit.slideUp('fast', function(){
                jQuery(this).addClass('js-closed');
            });
            icon.removeClass('fa-angle-down');
            icon.addClass('fa-angle-right');
        }
    });
     /**
     * Toggle filter panel mobile
     */
    jQuery('.js-step3').on('click', '.js-btn-filter', function() {
        jQuery('.left-side').toggleClass("is-open");
    });
    /**
     * accordeon step3
    */
    jQuery(".js-parttype-header").on('click', function() {
        SortPartsBy.sort(this.parentElement);
       var fa = jQuery(this).find('.fa').toggleClass('fa-plus-circle fa-minus-circle');
       if(fa.hasClass('fa-plus-circle')){
        jQuery(this).siblings('.js-part').slideUp({start: function () {$(this).css({display: "flex"})}});
       }
       else{
        jQuery(this).siblings('.js-part').slideDown({start: function () {$(this).css({display: "flex"})}});
       }
       new Analytic.Event('search-engine','parttype-header',$(this).data('title'),!jQuery(this).find('.fa').hasClass('fa-plus-circle')?'open':'close')
    });
    jQuery('.js-btn-moreinfo').on('click', function(e) {
       e.preventDefault();
       var link = jQuery(this).data('link');
       if(link){
           if (e.ctrlKey){
               window.open(link,'_blank')
           } else {
               window.location.href = link;
           }
       }
    });
     jQuery('.js-btn-buyers-guide').on('click', function(e) {
       e.preventDefault();
       var link = jQuery(this).data('link');
       if (e.ctrlKey){
            window.open(link + '&anchor=buyers-guide','_blank')
       }else{
            window.location.href = link + '&anchor=buyers-guide';
       }
    });

    jQuery('.js-step3').on('click', '.js-show-alternate', function(){
            var idTrigered=$(this).attr('data-show-alternte-action');
            if($(this).hasClass('active')){
                var showText=$(this).attr('data-visible-message');
                $(this).removeClass('active');

                $('[data-alternate-container="'+idTrigered+'"]').addClass('display-none');
                $('[data-default-container="'+idTrigered+'"]').removeClass('display-none');
            }
            else{
                var showText=$(this).attr('data-hidden-message');
                $(this).addClass('active');

                $('[data-alternate-container="'+idTrigered+'"]').removeClass('display-none');
                $('[data-default-container="'+idTrigered+'"]').addClass('display-none');
            }

            $(this).html(showText);
        });


    /**
     * Add to cart action
     */
    jQuery('.js-step3').on('change','.js-part .js-txt-quantity',function(){
		var part = jQuery(this).closest('.js-part');
		part.find('.js-txt-quantity').val($(this).val());
    })
    
    jQuery('.js-step3').on('click', '.js-btn-addtocart', function(){
        var used_line_code;
        var url = document.getElementById('addtocart').value;
        var year = document.getElementById('year').value;
        var make = document.getElementById('make').value;
        var model = document.getElementById('model').value;
        var part = jQuery(this).closest('.js-part');
        var quantityControl = part.find('.js-txt-quantity');
        var manufacturer = part.data('manufacturer');
        var description = part.data('description');
        var partTypeName = part.data('parttypename');
        var productCode = part.data('partnumber');
        var lineCode = part.data('linecode');
        var buttonClicked = jQuery(this);
        var storeId = jQuery(this).data('store-id');
        var sid = jQuery('#sid').val();
        var unitPrice = jQuery(this).parent().parent().parent().parent().data('unitprice');
        var qty=buttonClicked.parent().find('qty-num').val();
        var action=[];
        var lineCodeSpecific = $(this).attr('data-line-code');
        if(lineCodeSpecific)
            used_line_code=lineCodeSpecific;
        else
            used_line_code=lineCode;




        var action={
            event:'addToCart',

            "productObj" : {
                year:year,
                make:make,
                model:model,
                part:part,
                quantityControl:quantityControl,
                manufacturerName:manufacturer,
                description:description,
                partTypeName:partTypeName,
                productCode:productCode,
                lineCode:used_line_code,
                buttonClicked:buttonClicked,
                storeId:storeId,
                sid:sid,
                qty:qty,
                unitPrice:unitPrice,
                ottoIdentifier : jQuery(this).data('ottoIdentifier'),
            }
        };

        //mesureAction(action);

        quantityControl.removeClass('error');
        if (quantityControl.val() == 0) {
            quantityControl.addClass('error');
            return;
        }
        var overlayMessage = part.find('.js-part-overlay-message');
        var overlaySuccess = overlayMessage.find('.js-success');
        var overlayError = overlayMessage.find('.js-error');
        if (!Tools.IsMobile()) {
            var overlay = part.find('.js-part-overlay');
            var overlayLoading = part.find('.js-part-overlay-loading');
            overlaySuccess.removeClass('hide');
            overlayError.addClass('hide');
            overlay.removeAttr('style');
            overlayLoading.removeAttr('style');
        } else {
            var overlay = jQuery('.js-loading-overlay');
            var overlayLoading = jQuery('.js-loading-overlay.loading-icon-overlay');
            overlay.removeClass('hide');
            overlayLoading.removeClass('hide');

        }
        new Analytic.Event('search-engine','btn-addtocart',productCode+"");
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                quantity : quantityControl.val()?quantityControl.val():1,
                productcode : productCode,
                linecode : used_line_code,
                year : year,
                make : make,
                model : model,
                manufacturer : manufacturer,
                description : description,
                parttype : partTypeName,
                storeid : storeId,
                sid : sid,
                unitprice : unitPrice
            },
            success: function(data){

                if (data.error) {
                    overlaySuccess.addClass('hide');
                    overlayError.removeClass('hide');
                } else if (data.count > 0) {
                    jQuery('.js-menu-cart-count').html('&nbsp;(' + data.count + ')');
                    jQuery('.js-breadcrumb-cart-text-single, .js-breadcrumb-cart-text-multiple').addClass('hide');
                    jQuery('.js-container-content-step-4').parent().removeClass('inactive');
                    jQuery('.js-getresults').removeClass('disabled');
                    if(data.count > 1) {
                        jQuery('.js-breadcrumb-cart-text-multiple').removeClass('hide');
                    } else {
                        jQuery('.js-breadcrumb-cart-text-single').removeClass('hide');
                    }
                    if(buttonClicked.hasClass('btn'))
                      buttonClicked.css('background-color' , '#313236');
                    else
                      buttonClicked.attr('style', 'color: #313236 !important');
                    buttonClicked.siblings('.item-added').removeClass('hide');

                    buttonClicked.find("[data-text-after-add]").text(buttonClicked.find("[data-text-after-add]").data('textAfterAdd'))
                }
                if (!Tools.IsMobile()) {
                    overlayLoading.css('display', 'none');
                    overlayMessage.removeAttr('style');
                } else {
                    overlay.addClass('hide');
                    overlayLoading.addClass('hide');
                }
                setTimeout(function(){
                    overlay.animate({
                        opacity:0
                    }, 300, function(){
                        setTimeout(function(){overlay.css('display', 'none');}, 250);
                    });
                    overlayMessage.animate({
                        opacity:0
                    }, 300, function(){
                        setTimeout(function(){overlayMessage.css('display', 'none');}, 250);
                    });
                },2000);
            }
        });
    });
    /**
     * step 3 sticky header
     */
    jQuery(window).bind('scroll', function() {
        jQuery('.js-parttype-header:visible').each(function() {
            var header = jQuery(this);
            toolbar_height = stickyThing.height();
            var position = header.offset().top - (jQuery(window).scrollTop() + toolbar_height);
            var sticky_header_title = header.attr('data-title');
            var header_width = jQuery('.right-side').width();
            if(jQuery(window).scrollTop() + toolbar_height > jQuery('.js-parttype-header:first-of-type:visible').offset().top && jQuery(window).scrollTop() < jQuery('footer').offset().top){
                jQuery(".js-parttype-sticky-header").fadeIn();
                if (position <= 0) {
                   jQuery('.js-parttype-sticky-header').text(sticky_header_title + ' '+header.find('span').text());
                   jQuery(".js-parttype-sticky-header").css('width', header_width);
                   jQuery(".js-parttype-sticky-header").css('top', toolbar_height);
                }
            } else {
                jQuery(".js-parttype-sticky-header").fadeOut();
            }
        });
    });
    /**
     * toogle anchor top button
     */
     jQuery(document).scroll(function(){
        //jQuery('.js-dropdown-manufacturer, .js-parttype-pop-header, .js-parttype-pop-footer, .js-mobile-overlay').fadeOut();
        if (jQuery(this).scrollTop() > 500) {
            jQuery('.js-scroll-to-top').fadeIn();
        } else {
            jQuery('.js-scroll-to-top').fadeOut();
        }
    });
     /**
     * Scroll to top
     */
    jQuery('.js-scroll-to-top').click(function(){
        jQuery('html, body').animate({scrollTop : 0}, 800);
        return false;
    });
    /**
     * Reset filters
     */
    jQuery('.js-step3').on('click', '.js-reset', function() {
        if (isFiltering) {
            return false;
        }
        isFiltering = true;
        jQuery('.js-step3 .js-categories .js-chk').prop('checked', false);
        FilterStoreDeliveryOption.reset().recalibrate();
        logicalFilter = [];
        applyStep3Filters();
        isFiltering = false;
        jQuery('html, body').animate({scrollTop : 0}, 800);
        collapseAll();
        jQuery('.js-reset').addClass('disabled');
    });

    jQuery('.js-step3').on('click', '.js-collapse', function() {
        collapseAll();
        //$(this).removeClass('js-collapse');
    });
    jQuery('.js-step3').on('click', '.js-expand', function() {
        expandAll();
        //$(this).removeClass('js-collapse');
    });
    /**
     * Filter checkboxes actions
     */
    jQuery('.js-step3').on('change', '.js-chk', function(){

        new Analytic.Event('search-engine','js-step3',$(this).val(),$(this).prop('checked')?'checked':'');
        if (isFiltering) {
            return false;
        }
        isFiltering = true;
        var category = jQuery(this).attr('data-category');
        var id = parseInt(jQuery(this).attr('id').replace('filter-', ''));
        if(category!=undefined){
            if (jQuery(this).prop('checked')) {
                jQuery('.js-reset').removeClass('disabled');
                var filter = {};
                strKeys = Object.keys(logicalFilter);
                intKeys = strKeys.map(Number);
                lastKey = (intKeys.length > 0 ? intKeys[intKeys.length - 1] : -1);
                if (lastKey >= 0 && logicalFilter[lastKey].category == category) {
                    filter = logicalFilter[lastKey];
                    filter.filters.push(id);
                    logicalFilter[lastKey] = filter;
                } else {
                    filter.category = category;
                    filter.filters = [id];
                    logicalFilter.push(filter);
                }
            } else {
                var toDelete = [];
                removeLogicalFilter(id)
                if(!logicalFilter.length) jQuery('.js-reset').addClass('disabled');
            }
        }
        var loadingIcon = jQuery(this).closest('.js-filter').find('.js-loading-icon');
        loadingIcon.removeClass('hide');
        setTimeout(function() {
            applyStep3Filters();
            loadingIcon.addClass('hide');
            isFiltering = false;
        }, 150);
    });
    jQuery('.js-step3 .js-filter').on('click', 'input[type=checkbox]', function(){
        if (( navigator.userAgent.match(/(iPod|iPhone|iPad)/) && (jQuery(this).closest('.js-filter').hasClass('disabled') ||
            isFiltering == false)) && (jQuery(this).closest('.js-filter').hasClass('disabled') || isFiltering )) {
            return false;
        }
    });
    jQuery('.js-step3 .js-filter').on('click', 'label', function(){
         if (( navigator.userAgent.match(/(iPod|iPhone|iPad)/) && (jQuery(this).closest('.js-filter').hasClass('disabled') ||
            isFiltering == false)) && (jQuery(this).closest('.js-filter').hasClass('disabled') || isFiltering )) {
            return false;
        }
    });
    applyStep3Filters();

    //jQuery('.js-loading-overlay').addClass('hide');
    /****************************************************/
    /**
    * sticky stuff
    */
    var  $el = jQuery('.js-fixed-element');

   if(jQuery(window).width() > 480){
        var $elPosY = $el.offset().top;
   }else{
        var $elPosY = $el.offset().top - 50;
   }

   jQuery(window).scroll(function(e){

       var isPositionFixed = ($el.css('position') == 'fixed');


         if (jQuery(this).scrollTop() > $elPosY && !isPositionFixed  && !Tools.IsMobile() && jQuery('.right-side').height() > (jQuery(window).height()) ){
           $el.addClass('fixed-element');
         } else if (jQuery(this).scrollTop() > $elPosY && !isPositionFixed  && !Tools.IsMobile() && jQuery('.js-cart.js-left-side-height').height() > 900) {
            $el.addClass('fixed-element');
         }
         if (jQuery(this).scrollTop() < $elPosY && isPositionFixed && !Tools.IsMobile()) {
           $el.removeClass('fixed-element');
         }
   });



});

var stickyThing = new function(){
    var my_objects = [];
    var _is = 'static';
    jQuery('document').ready(function() {
        $('.js-sticky-nav').each(function(){
            my_objects.push({
                element: this,
                placeholder: $('<i class="sticky-nav-placeholder"></i>'),
                height: $(this).outerHeight(),
                left: $(this).offset().left,
                top: $(this).offset().top,
                width: $(this).outerWidth(),
            })
        });
        jQuery( window ).resize(this.update.bind(this))
        this.sticky();
        setTimeout(this.update,1000); //make sure css fully rendered
    }.bind(this));
    
    function getSreenProperty(){
        return {
            height: window.innerHeight,
            left:   jQuery(window).scrollLeft(),
            top:    jQuery(window).scrollTop(), 
            width:  window.innerWidth
        };
    }
    this.is = function(){return _is;}
    this.fixed = function(){
        if(_is != 'fixed'){
            var s_pos = getSreenProperty();
            jQuery(window).off('scroll',this.scroll);
            var s_h = 0;
            for(k in my_objects){
                if( my_objects[k].element.dataset['minWidth'] == undefined || parseInt(my_objects[k].element.dataset['minWidth'])<s_pos.width){
                    jQuery(my_objects[k].element).addClass('sticky-nav').css({top: s_h+'px'}).after(my_objects[k].placeholder.height(my_objects[k].height));
                    s_h+=  my_objects[k].height;
                }
                else{
                    jQuery(my_objects[k].element).removeClass('sticky-nav').css({top:''});
                    my_objects[k].placeholder.remove();
                }
            }
            _is = 'fixed';
        }
    }
    this.sticky = function(){
        if(_is != 'sticky'){
            jQuery(window).scroll(this.scroll);
            jQuery(window).trigger('scroll');
            _is = 'sticky';
        }
    }
    this.static = function(){
        if(_is != 'static'){
            jQuery(window).off('scroll',this.scroll);
            for(k in my_objects){
                jQuery(my_objects[k].element).removeClass('sticky-nav').css({top:''});
                my_objects[k].placeholder.remove();
            }
            _is = 'static';
        }
    }
    this.height = function(){
        var s_h = 0;
        for(k in my_objects)
            if( jQuery(my_objects[k].element).hasClass('sticky-nav'))
                s_h+=  my_objects[k].height;
        return s_h;
    }
    this.update = function(){
        jQuery('.js-sticky-nav').removeClass('sticky-nav');
        jQuery('.sticky-nav-placeholder').remove();
        for(k in my_objects){
            my_objects[k].height= jQuery(my_objects[k].element).outerHeight();
            my_objects[k].left  = jQuery(my_objects[k].element).offset().left;
            my_objects[k].top   = jQuery(my_objects[k].element).offset().top;
            my_objects[k].width = jQuery(my_objects[k].element).outerWidth();
        }
        jQuery(window).trigger('scroll');
    };
    this.scroll = function(){
        var s_pos = getSreenProperty();
        var s_h = 0;
        for(k in my_objects){
            if( my_objects[k].top < s_pos.top+s_h && (my_objects[k].element.dataset['minWidth'] == undefined || parseInt(my_objects[k].element.dataset['minWidth'])<s_pos.width) ){
                jQuery(my_objects[k].element).addClass('sticky-nav').css({top: s_h+'px'}).after(my_objects[k].placeholder.height(my_objects[k].height));
                s_h+=  my_objects[k].height;
            }
            else{
                jQuery(my_objects[k].element).removeClass('sticky-nav').css({top:''});
                my_objects[k].placeholder.remove();
            }
        }
    };
};

/**
 * Updates the stat of all filters and their parent on step2
 */
function updateStep2FiltersState()
{
    var enableNextStep = false;
    var counts = {};
    for (var cid in mappings.step2.hierarchy){
        for (var scid in mappings.step2.hierarchy[cid]) {
            for (var ptid in mappings.step2.hierarchy[cid][scid]) {
                for (var m in mappings.step2.hierarchy[cid][scid][ptid]) {
                    var manufacturer = document.getElementById('manufacturer-' + cid + '-' + scid + '-' + ptid + '-' + mappings.step2.hierarchy[cid][scid][ptid][m]);
                    if (manufacturer) {
                        if (!counts[cid]) {
                            counts[cid] = {'checked' : 0, 'all' : 0};
                        }
                        if (!counts[cid + '-' + scid]) {
                            counts[cid + '-' + scid] = {'checked' : 0, 'all' : 0};
                        }
                        if (!counts[cid + '-' + scid + '-' + ptid]) {
                            counts[cid + '-' + scid + '-' + ptid] = {'checked' : 0, 'all' : 0};
                        }
                        var checked = (manufacturer.checked ? 1 : 0);
                        counts[cid].all++;
                        counts[cid].checked += checked;
                        counts[cid + '-' + scid].all++;
                        counts[cid + '-' + scid].checked += checked;
                        counts[cid + '-' + scid + '-' + ptid].all++;
                        counts[cid + '-' + scid + '-' + ptid].checked += checked;
                        if (checked != 0) {
                            enableNextStep = true;
                        }
                    }
                }
                var ptElement = document.getElementById('filter-pt-' + cid + '-' + scid + '-' + ptid);
                var ptSubmenuElement = document.getElementById('filter-pt-submenu-' + cid + '-' + scid + '-' + ptid);
                if (counts[cid + '-' + scid + '-' + ptid].checked > 0) {
                    ptSubmenuElement.classList.add('chk-selected');
                } else {
                    ptSubmenuElement.classList.remove('chk-selected');
                }
                ptElement.checked = counts[cid + '-' + scid + '-' + ptid].checked == counts[cid + '-' + scid + '-' + ptid].all;
                ptElement.indeterminate = counts[cid + '-' + scid + '-' + ptid].checked != counts[cid + '-' + scid + '-' + ptid].all && counts[cid + '-' + scid + '-' + ptid].checked > 0;
            }
            var scElement = document.getElementById('filter-sc-' + cid + '-' + scid);
            var scSubmenuElement = document.getElementById('filter-sc-submenu-' + cid + '-' + scid);
            if (counts[cid + '-' + scid].checked > 0) {
                scSubmenuElement.classList.add('chk-selected');
            } else {
                scSubmenuElement.classList.remove('chk-selected');
            }
            scElement.checked = counts[cid + '-' + scid].checked == counts[cid + '-' + scid].all;
            scElement.indeterminate = counts[cid + '-' + scid].checked != counts[cid + '-' + scid].all && counts[cid + '-' + scid].checked > 0;
        }
        var cSubmenuElement = document.getElementById('filter-c-submenu-' + cid);
        if (counts[cid].checked > 0) {
            cSubmenuElement.classList.add('chk-selected');
        } else {
            cSubmenuElement.classList.remove('chk-selected');
        }
    }

    if (enableNextStep) {
        jQuery('.js-step2 .js-reset, .js-step2-mobile .js-reset').removeClass('disabled');
        jQuery('.js-step2 .js-getresults, .js-step2-mobile .js-getresults').removeClass('disabled');
    } else {
        jQuery('.js-step2 .js-reset, .js-step2-mobile .js-reset').addClass('disabled');
        jQuery('.js-step2 .js-getresults, .js-step2-mobile .js-getresults').addClass('disabled');
    }
}

/**
 * Hides/shows results based on the filters chosen on step3
 * @returns {Boolean} If the filters were applied
 */
function applyStep3Filters()
{
    //PartType count

    var ptCount = [];
    for (var i in mappings.step3.parts)
    {

        var partType = mappings.step3.parts[i].parttype;
        if (!(partType in ptCount)) {
            ptCount[partType] = 0;
        }
        ptCount[partType]++;
    }



    //Cloning mappings
    var dataLeft = {};
    for(var i in mappings.step3.parts)
    {
        dataLeft[i] = mappings.step3.parts[i];

    }

    //Pre-filter NON-PIES
    for (var partId in dataLeft)
    {
        el = document.getElementById(partId);

        var region = false;
        if(
            !((!FilterStoreDeliveryOption.pikup() && !FilterStoreDeliveryOption.delivery()) ||
            (FilterStoreDeliveryOption.pikup() && el.querySelector('.js-picto-instore.active')!=null) ||
            (FilterStoreDeliveryOption.delivery() && el.querySelector('.js-picto-delovery.active')!=null))
        )
            region = true;

        if(
            region ||
            (FilterStoreDeliveryOption.availableOnly() && (el.dataset.unitprice=="0" || el.dataset.unitprice=="")) ||
            $('.js-diagram-check').is(':checked') && el.dataset.diagram != $('input[name=diagram]:checked').val()
        ) {
            delete dataLeft[partId];
        }
    }

    //Logically filtering data
    var strKeys = Object.keys(logicalFilter);
    var intKeys = strKeys.map(Number);
    var lastKey = (intKeys.length > 0 ? intKeys[intKeys.length - 1] : -1);
    var currentPtCount = [];

    if(!$('.js-diagram-check').is(':checked'))
    for(var i in logicalFilter)
    {
        var mappingIds = [];
        for (var j in logicalFilter[i].filters)
        {
            var filterId = logicalFilter[i].filters[j] - 1;
            for (var k in mappings.step3.filters[filterId].mappings)
            {
                mappingIds.push(mappings.step3.filters[filterId].mappings[k]);
            }
        }
        if (i == lastKey) {
            for (var j in mappings.step3.filters) {
                if (mappings.step3.filters[j].category == logicalFilter[lastKey].category) {
                    var filterId = parseInt(j) + 1;
                    if (!(filterId in currentPtCount)) {
                        currentPtCount[filterId] = [];
                    }
                    for (var k in dataLeft) {
                        for (var l in mappings.step3.filters[j].mappings) {
                            if (dataLeft[k].mappings.indexOf(mappings.step3.filters[j].mappings[l]) >= 0) {
                                if (!(k in currentPtCount[filterId])) {
                                    currentPtCount[filterId][k] = dataLeft[k];
                                }
                                break;
                            }
                        }
                    }
                }
            }
        }
        for (var j in dataLeft)
        {
            var isIn = false;
            for (var k in mappingIds)
            {
                if (dataLeft[j].mappings.indexOf(mappingIds[k]) >= 0) {
                    isIn = true;
                    break;
                }
            }
            if (!isIn) {
                delete dataLeft[j];
            }
        }
    }

    //Display data
    for (var partId in mappings.step3.parts)
    {
        if (partId in dataLeft) {
            document.getElementById(partId).classList.remove("hide");
            if (document.getElementById(partId).classList.contains('option-hide')) 
                ptCount[mappings.step3.parts[partId].parttype]--;
        } else {
            document.getElementById(partId).classList.add("hide");
            ptCount[mappings.step3.parts[partId].parttype]--;
        }
    }
    for (var parttype in ptCount)
    {
        if (ptCount[parttype] <= 0) {
            document.getElementById(parttype).classList.add("hide");
        } else {
            document.getElementById(parttype).classList.remove("hide");
        }
        document.getElementById('headercount-' + parttype).innerHTML = '(' + ptCount[parttype] + ')';
    }

    //Filter checkboxes filtering
    var filterCount = {};


    //console.log(currentPtCount);
    //console.log(dataLeft);
    //return;

    for(var i in mappings.step3.filters)
    {
        var filterId = parseInt(i) + 1;
        filterCount[filterId] = [];
        for (var j in mappings.step3.filters[i].mappings)
        {
            var mapping = mappings.step3.filters[i].mappings[j];

            for(var k in dataLeft)
            {

                if (dataLeft[k].mappings.indexOf(mapping) >= 0 && filterCount[filterId].indexOf(k) < 0) {
                    filterCount[filterId].push(k);
                    if(i == 67) console.log('HERE');
                }
            }

        }

        if (filterCount[filterId].length > 0 || (lastKey != -1 && logicalFilter[lastKey] !=undefined && mappings.step3.filters[i].category == logicalFilter[lastKey].category)) {
            document.getElementById('filter-container-' + filterId).classList.remove('disabled');
        } else {
            document.getElementById('filter-container-' + filterId).classList.add('disabled');
        }
        var count = filterCount[filterId].length;
        if(i == 67) console.log(count);

        if (filterId in currentPtCount) {
            count = Object.keys(currentPtCount[filterId]).length;
            if(i == 67) console.log(count);
        }
        document.getElementById('filter-count-' + filterId).innerHTML = '(' + count + ')';
        var _chekbox =  document.getElementById('filter-' + filterId);
        _chekbox.disabled = !count;
        _chekbox.parentNode.parentNode.style.display = !count?'none':'';
        _chekbox.checked = count?_chekbox.checked:false;
        if(!_chekbox.checked) removeLogicalFilter(filterId)
        var group = $(_chekbox.parentNode.parentNode).closest('li.js-category');
        group.css('display',(group.find('input[type=checkbox]').length <= group.find('input[type=checkbox]:disabled').length)?'none':'')
    }

    FilterStoreDeliveryOption.recalibrate()
}

function union_arrays (x, y) {
    var obj = {};
    for (var i = x.length-1; i >= 0; -- i)
       obj[x[i]] = x[i];
    for (var i = y.length-1; i >= 0; -- i)
       obj[y[i]] = y[i];
    var res = []
    for (var k in obj) {
      if (obj.hasOwnProperty(k))  // <-- optional
        res.push(obj[k]);
    }
    return res;
}

function expandAll(){
    jQuery('.js-part').removeAttr('style');
    jQuery('.js-part [data-drop-img]').each(function(){
        var elemProduct=$(this);
        if(!elemProduct.find('img').length)
        elemProduct.html("<img src='"+ $(this).attr('data-drop-img')+"'>");
    })
    jQuery('.js-parttype-header').each(function(){
        $(this).find('.fa').removeClass('fa-plus-circle').addClass('fa-minus-circle');
        SortPartsBy.sort(this.parentElement);
    });
    jQuery('.js-step3 .js-expand').addClass('js-collapse').removeClass('js-expand');
    
}

function collapseAll() {
    jQuery('.js-part').slideUp();
    jQuery('.js-parttype-header').find('.fa-minus-circle').removeClass('fa-minus-circle').addClass('fa-plus-circle');
    jQuery('.js-step3 .js-collapse').removeClass('js-collapse').addClass('js-expand');
    hasExpanded = false;
}

function mapPartData() {
    jQuery('.js-step2').find('.js-namemap').each(function(){
        var data = jQuery(this).val();
        data = atob(data);
        data = jQuery.parseJSON(data);

        mappings.step2.ids = data;
    });
}


function submitToStep3(checks){

    if (checks.length > 0)
    {
        jQuery('.js-loading-overlay').css({'opacity': '1', 'background-color' : 'white'});
        jQuery('.js-loading-overlay').removeClass('hide');
        var form = jQuery('<form action="" method="POST"></form>');
        var data = [];
        checks.each(function(){
            var selection = {};
            selection.Manufacturer = jQuery(this).val();
            selection.PartTerminology = jQuery(this).closest('.js-parttype').find('.js-submenu').find('.js-chk').val();
            selection.SubCategory = jQuery(this).closest('.js-subcategory').find('.js-submenu').find('.js-chk').val();
            selection.Category = jQuery(this).closest('.js-category').data('id');
            selection.isNA = jQuery(this).data('isna') == '1';

            mapPartData();

            for(var name in mappings.step2.ids)
            {

                if (mappings.step2.ids[name] == selection.PartTerminology) {
                    selection.PartTerminology = name.substr(3);
                } else if (mappings.step2.ids[name] == selection.SubCategory) {
                    selection.SubCategory = name.substr(3);
                } else if (mappings.step2.ids[name] == selection.Category) {
                    selection.Category = name.substr(3);
                }
            }

            data.push(selection);
        });

        form.append(jQuery('<input />', {
            type : 'hidden',
            name : 'selection',
            value : JSON.stringify(data)
        }));
        form.append(jQuery('<input />', {
            type : 'hidden',
            name : 'token',
            value : jQuery('.js-step2 #token').val()
        }));
        jQuery('body').append(form);
        form.submit();
    }
}