Number.prototype.formatMoney = function(c, d, t){
var n = this, 
    c = isNaN(c = Math.abs(c)) ? 2 : c, 
    d = d == undefined ? "." : d, 
    t = t == undefined ? "," : t, 
    s = n < 0 ? "-" : "", 
    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
    j = (j = i.length) > 3 ? j % 3 : 0;
   return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};
Math.roundHalf = function(n){
  return Math.round(n*2)/2;
}

Number.prototype.toStars = function(t){
  var n = Math.roundHalf(this),
      t = isNaN(t) ? 5 : t;
      h = '';
  for(var ж = 1; ж<=t; ж++){
    if(n >=ж)         h += '<i class="fa fa-star" aria-hidden="true"></i>';
    else if(n > ж-1)  h += '<i class="fa fa-star-half-o" aria-hidden="true"></i>';
    else              h += '<i class="fa fa-star-o" aria-hidden="true"></i>';
  }
  return h;
};
