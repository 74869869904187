jQuery("document").ready(function () {
  jQuery(".js-brand-parttype-header").on("click", function () {
    jQuery(this)
      .siblings(".js-brand-subcats")
      .find(".js-brand-subcat-header")
      .slideToggle();
    jQuery(this).find(".fa").toggleClass("fa-plus-circle fa-minus-circle");
    jQuery(this)
      .siblings(".js-brand-subcats")
      .find(".js-row-brand-parttype")
      .css("display", "none");
    jQuery(this)
      .siblings(".js-brand-subcats")
      .find(".js-brand-subcat-header i")
      .removeClass("fa-minus-circle")
      .addClass("fa-plus-circle");
  });
  jQuery(".js-brand-subcat-header").on("click", function () {
    jQuery(this).siblings(".js-row-brand-parttype").fadeToggle();
    jQuery(this).find(".fa").toggleClass("fa-plus-circle fa-minus-circle");
  });

  jQuery(".js-faq-question").on("click", function () {
    jQuery(this).next(".js-faq-answer").slideToggle();
    jQuery(this).find(".fa").toggleClass("fa-plus-circle fa-minus-circle");
  });
});
