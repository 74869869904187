$(function () {
  $(".connections-container").on("click", ".edit-connection-btn", editConnectionClickedHanlder);
  $(".connections-container").on("click", ".delete-connection-btn", deletePriceListByNameClickedHandler);
  $(".add-connection-btn").on("click", addConnectionClickedHanlder);
});

//HANDLERS
function addConnectionClickedHanlder() {
  showConnectionLabelPopup(
    __("price-list.add-connection-label-modal.msg"),
    createConnection
  );
}
function editConnectionClickedHanlder() {
  editingConnection = $(this).parent().parent();
  showConnectionLabelPopup(
    __("price-list.edit-connection-label-modal.msg") + " " + wrapStringInBoldTab(getConnectionLabelFromRow(editingConnection)),
    editConnection
  );
}
function deletePriceListByNameClickedHandler() {
  deletingConnection = $(this).parent().parent();
  new popupConfirmation(
    __("price-list.delete-confirm.msg") + " " + wrapStringInBoldTab(getConnectionLabelFromRow(deletingConnection)),
    deleteConnection,
    __("Delete"),
    __("Cancel")
  )
}

//State
var editingConnection;
var deletingConnection;

function getConnections() {
  return getConnectionTableRows().map(function () {
    return {
      connectionLabel: getConnectionLabelFromRow($(this))
    }
  }).toArray();
}
//DAL
function createConnectionAJAX(connectionLabel) {
  $.post("/ajax/createConnectionIfNotExists", {
    connectionLabel: connectionLabel,
  });
}
function editConnectionAJAX(connectionLabel) {
  $.post("/ajax/renameConnection", {
    oldName: getConnectionLabelFromRow(editingConnection),
    newName: connectionLabel,
  });
}
function deletePriceListByNameAJAX(priceListName) {
  $.post("/ajax/deletePriceListByName", {
    priceListName: priceListName,
  })
}
//DOM GET
function getConnectionLabelFromRow(row) {
  return getConnectionLabelContainerFromRow(row).html().trim();
}
function getConnectionLabelContainerFromRow(row) {
  return row.find(".connection-label-container");
}
function getNewImportBtnFromRow(row) {
  return row.find(".new-importation-btn");
}
function getConnectionTableRows() {
  return $(".connections-container .connection-table-row")
}
//DOM SET
function showConnectionLabelPopup(msg, callBack) {
  var form = getConnectionLabelPopupForm(msg);
  var popup = new popupForm(form[0], "Ok", {
    success: function () {
      var connectionLabel = form.find("#connectionLabel-input").val().trim();

      if (
        connectionLabelIsValid(connectionLabel) &&
        !connectionLabelAlreadyExists(connectionLabel)
      ) {
        callBack(connectionLabel);
        popup.close().die();
      }
    },
  });
}
function showConnectionLabelInvalidModal() {
  showAlertPopup(
    __(
      "price-list-import.line-code-association.connection-label-invalid-modal.text"
    )
  );
}
function createConnectionDOM(connectionLabel) {
  var newRow = $(".template-container").find(".connection-table-row").clone();
  setConnectionLabelForRow(newRow, connectionLabel);
  setDateCreatedForRow(newRow, getCurrentDateYMDFormatted())
  $(".connections-container").append(newRow);
}
function editConnectionDOM(connectionLabel) {
  setConnectionLabelForRow(editingConnection, connectionLabel);
}
function deletePriceListByNameDOM() {
  deletingConnection.remove()
}

function setConnectionLabelForRow(row, connectionLabel) {
  getConnectionLabelContainerFromRow(row).html(connectionLabel);

  var newImportBtn = getNewImportBtnFromRow(row);
  newImportBtn.attr(
    "href",
    getUrlWithChangedConnectionLabel(newImportBtn.attr("href"), connectionLabel)
  );
}
function setDateCreatedForRow(row, dateCreated) {
  row.find(".date-created-container").html(dateCreated);
}
function showConnectionAlreadyExistsPopup(connectionLabel) {
  showAlertPopup(
    __("price-list.connection-already-exists.msg") +
      " " +
      wrapStringInBoldTab(connectionLabel)
  );
}
//LOGIC
function getUrlWithChangedConnectionLabel(url, connectionLabel) {
  var splittedUrl = url.split("/");
  splittedUrl[splittedUrl.length - getMinusIndexForUrl(url)] = connectionLabel;
  return splittedUrl.join("/").replace(" ", "+");
}

function getMinusIndexForUrl(url) {
  if (urlEndsWithASlash(url)) return 2;
  else return 1;
}
function urlEndsWithASlash(url) {
  return url[url.length - 1] == "/";
}
function connectionLabelIsValid(connectionLabel) {
  var valid =
    !isNullOrWhiteSpace(connectionLabel) && connectionLabel.length <= 55;
  if (!valid) showConnectionLabelInvalidModal();
  return valid;
}
function connectionLabelAlreadyExists(connectionLabel) {
  var found = getConnections().some(function (conn) {
    return conn.connectionLabel.toLowerCase() == connectionLabel.toLowerCase();
  });

  if (found) showConnectionAlreadyExistsPopup(connectionLabel);
  return found;
}
function getConnectionLabelPopupForm(msg) {
  return $(
    "<form class='container-form'>" +
      msg +
      "<input class='full' type='text' id='connectionLabel-input' placeholder='Ex: " +
      "Ex: Local" +
      "' />" +
      "</form>"
  );
}
function createConnection(connectionLabel) {
  createConnectionAJAX(connectionLabel);
  createConnectionDOM(connectionLabel);
}
function editConnection(connectionLabel) {
  editConnectionAJAX(connectionLabel);
  editConnectionDOM(connectionLabel);
}
function deleteConnection() {
  var connlabel = getConnectionLabelFromRow(deletingConnection);
  deletePriceListByNameDOM();
  deletePriceListByNameAJAX(connlabel);
}