function getSelectedFromDropdown(dropdown) {
  return dropdown.find(":selected");
}
function changeDropdownSelection(dropdown, newSelection) {
  dropdown.val(newSelection).trigger("change");
}

function clearDropdownSelection(dropdown) {
  getNonNullOptionsForDropdown(dropdown).prop("selected", false);
  dropdown.trigger("change");
}

function multiselectSelectAllOption(multiSelect) {
  getNonNullOptionsForDropdown(multiSelect).prop("selected", "selected");

  multiSelect.trigger("change");
}

function getNonNullOptionsForDropdown(dropdown) {
  return dropdown.find("option").filter(function () {
    return !isNullOrWhiteSpace($(this).val());
  });
}
