jQuery('document').ready(function(){
    jQuery('.js-product-info-tab, .js-otto-tab').click(function(){
         jQuery('.js-container-content, .js-product-info-tab, .js-otto-tab').removeClass('active');
         tab_id = jQuery(this).attr('id');
          jQuery('#container-' + tab_id + ', #' + tab_id).addClass('active'); 
    });
    jQuery('.js-link-see-more').click(function(){
        jQuery('.js-more').fadeToggle();
        jQuery(this).find('.js-label-see-more').toggleClass('hide');
    });

   if (jQuery('input[name=anchor]').val() == 'buyers-guide') {
        jQuery('.js-product-info-tab#buyers-guide').addClass('active'); 
        jQuery('.js-container-content.container-buyers-guide').addClass('active');
          jQuery('html, body').animate({
            scrollTop: jQuery("a[name=buyers-guide]").offset().top
            }, 500); 
   } else {
        jQuery('.js-product-info-tab:first-of-type').addClass('active'); 
        jQuery('.js-container-content:first-of-type').addClass('active');
   }
   

     jQuery('.js-button-toggle').click(function(){
        jQuery('.js-applicative-info').slideToggle();
        jQuery(this).find('.fa').toggleClass('fa-plus-circle fa-minus-circle');
        new Analytic.Event('product-detail','button-toggle',$(this).text(),jQuery(this).find('.fa').hasClass('fa-minus-circle')?'open':'close')
    });    

      jQuery('.slickslide').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: false,
        slide: 'li',
        cssEase: 'linear',
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 4000,
        asNavFor: '.slider-nav',
        responsive: [{
            breakpoint: 800,
            settings: {
                centerMode: false,
                centerPadding: '40px',
                variableWidth: false,
                slidesToShow: 1,
                dots: true
            },
            breakpoint: 1200,
            settings: {
                
                centerMode: false,
                centerPadding: '40px',
                variableWidth: false,
                slidesToShow: 1,
                dots: true

            }
        }],
        customPaging: function (slider, i) {
            var html = '';
            if(jQuery('.slick-thumbs li:nth-child(' + (i + 1) + ')').length)
              html = jQuery('.slick-thumbs li:nth-child(' + (i + 1) + ')').html();
            else{
              html = $(slider.$slides[i]).find('img').clone().get(0).outerHTML;
            }

            return '<button class="tab">' + html + '</button>';
        }
    });



 jQuery('.container-video').slickLightbox(/*{ 

    closeOnBackdropClick : false,
    caption: 'caption',
    slick : function ($e) {

      $e.find('.slick-lightbox-slick-iframe').each(function () { 
         jQuery(this)
          .attr('data-src',  jQuery(this).attr('src'))
          .attr('src', '')
      })

      function clearIframe (slick, index) {
        var $iframe =  jQuery(slick.$slides.get(index)).find('.slick-lightbox-slick-iframe')
        if ($iframe.length) {
          setTimeout(function () {
            $iframe.attr('src', '')
          }, slick.options.speed)
        }
      }

      function loadIframe (slick, index) {

        var $iframe =  jQuery(slick.$slides.get(index)).find('.slick-lightbox-slick-iframe')
        if ($iframe.length) $iframe.attr('src', $iframe.attr('data-src')) 
      }

      /**
       * Return slick instance
       *//*
      return $e.find('.slick-lightbox-slick') 
        .on('init', function (event, slick) {
          loadIframe(slick, slick.currentSlide)
        })
        .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          clearIframe(slick, currentSlide)
          loadIframe(slick, nextSlide)
        })
        .slick()
    }
 }*/);

    /**
     * Add to cart action
     */
    jQuery('.js-product-detail').on('click', '.js-btn-addtocart', function(){
        var url = document.getElementById('addtocart').value;
        var year = document.getElementById('year').value;
        var make = document.getElementById('make').value;
        var model = document.getElementById('model').value;
        var part = jQuery(this).closest('.js-product-detail');
        var quantityControl = part.find('.js-txt-quantity');
        var manufacturer = part.data('manufacturer');
        var description = part.data('description');
        var parttypename = part.data('parttypename');
        var productCode = part.data('partnumber');
        var lineCode = part.data('linecode');
        var buttonClicked = jQuery(this);
        var storeId = jQuery(this).data('store-id');
        var sid = jQuery('#sid').val();
        var unitPrice = jQuery('#unitprice').val();;
        
        quantityControl.removeClass('error');

        if (quantityControl.val() == 0) {
            quantityControl.addClass('error');
            return;
        }
        
        var successMsg = part.find('.js-addtocard-msg.js-msg-success');
        var errorMsg = part.find('.js-addtocard-msg.js-msg-success');
        var loading = buttonClicked.find('.js-loading');
        var text = buttonClicked.find('.js-text');
        
        successMsg.css('display', 'none');
        errorMsg.css('display', 'none');
        loading.removeAttr('style');
        text.css('display', 'none');

        console.log(productCode);

        new Analytic.Event('product-detail','btn-addtocart',productCode+"");
                    
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                quantity : quantityControl.val()?quantityControl.val():1,
                productcode : productCode,
                linecode : lineCode,
                year : year,
                make : make,
                model : model,
                manufacturer : manufacturer,
                description : description,
                parttype : parttypename,
                storeid : storeId,
                sid : sid,
                unitprice : unitPrice,
                ottoIdentifier : jQuery(this).data('ottoIdentifier'),
            },
            success: function(data){
                if (data.error) {
                    successMsg.css('display', 'none');
                    errorMsg.removeAttr('style');
                    
                }  else if (data.count > 0) {
                    if(buttonClicked.hasClass('btn'))
                      buttonClicked.css('background-color', '#313236');
                    else
                      buttonClicked.attr('style', 'color: #313236 !important');
                    buttonClicked.find("[data-text-after-add]").text(buttonClicked.find("[data-text-after-add]").data('textAfterAdd'))
                    successMsg.removeAttr('style');
                    jQuery('.js-menu-cart-count').html('&nbsp;(' + data.count + ')');
                    jQuery('.js-container-content-step-4').parent().removeClass('inactive');
                    loading.css('display', 'none');
                    text.removeAttr('style');
                    
                }
            }
        });
    });
    

    $('.js-product-details').on('change' , '.filter-contents ul input' ,function(){
      //$('.slickslide.slick-initialized').slick('slickUnfilter');
      var idcontainer = new Array();
      var subapp = new Object();
      $('.filter-contents ul').removeClass('selected');
      if( !$(this).parents('ul').hasClass('selected') )
        $(this).parents('ul').addClass('selected');
      if( $('.filter-contents ul input:checked').length == 0 )
        $(this).parents('ul').removeClass('selected');
      $('.filter-contents ul input:checked').each(function(){
      var idies = $(this).attr('dat-sub-id');
      var targetItem = $(this).parents('ul').attr('data-js-target');
      idcontainer.push(idies.split(','));
      if( typeof subapp[targetItem] !== 'undefined' )
          subapp[targetItem] = union_arrays( subapp[targetItem], idies.split(',') );
      else
          subapp[targetItem] = idies.split(',');
      });
      var search=idcontainer.shift();
      for(var i = search.length-1; i>=0; i--){
          var found = false;
          Loop1:
          for(var k1 in idcontainer){
              for(var k2 in idcontainer[k1]){
                  if(idcontainer[k1][k2] == search[i]){
                      found = true;
                      break Loop1;
                  }
              }
          }
          if(!found){
              search.splice(i, 1);
          }
          else{
              console.log(search[i]);
              $('.slickslide.slick-initialized').slick('slickFilter','[data-json-part="'+search[i]+'"]');
          }
      }
      if($('.slickslide.slick-initialized').find('[data-json-part]').length<2){
          $('.slickslide.slick-initialized .slick-track').css('transform','');
      }
      else{
          $('.slickslide.slick-initialized').slick('slickGoTo', 0);
      }
      $('[data-js-item="part"]').each(function(){
      var show = false ;
      var jsonPart = $(this).attr('data-json-part').split(',');
      jsonPart.forEach(function(el){
          if(search.indexOf(el)!==-1){
              show = true
          }
      });
      if( show )
          $(this).show();
      else
          $(this).hide();
      });
  });
  $('.js-product-details .filter-contents ul input').first().trigger('change');
});