$(function () {
  //listeners
  $(".line-code-association-row .brand-dropdown").change(
    brandDropdownChangedHandler
  );

  $(".line-code-association-row").on(
    "click",
    ".select-all-part-types-btn",
    selectAllPartTypesBtnClickedHandler
  );
  $(".line-code-association-row").on(
    "click",
    ".deselect-all-part-types-btn",
    deselectAllPartTypesClickedHandler
  );
  $(".price-list-import-line-code-association-container").on(
    "click",
    ".save-btn",
    saveButtonClickedHandler
  );

  //setup
  setupPartTypeDropdown();
});
//setup fonctions
function setupPartTypeDropdown() {
  $(".line-code-association-row .part-type-dropdown").select2({
    placeholder: __(
      "price-list-import.line-code-association.part-type-dropdown.placeholder"
    ),
    closeOnSelect: false,
  });
}
function applyDefaultLineCodeMatches() {
  var matches = getDefaultLineCodeMatches();
  getLineCodeAssociationRows().each(function () {
    var currLineCode = getLineCodeFromRow($(this));

    var foundMatch = matches.find(function (match) {
      return match.lineCode == currLineCode;
    });

    if (foundMatch) {
      changeDropdownSelection(
        getBrandDropdownFromRow($(this)),
        foundMatch.manufacturerPartTypes.manufacturerName
      );
      changeDropdownSelection(
        getPartTypeDropdownFromRow($(this)),
        foundMatch.manufacturerPartTypes.partTypeNames
      );
    }
  });
}
//Handlers
function brandDropdownChangedHandler() {
  var manufacturer = getSelectedFromDropdown($(this)).val();
  var partTypeDropdown = getPartTypeDropdownFromRowComponent($(this));
  var selectAllBtn = getSelectAllBtnFromRowComponent($(this));
  var unselectAllBtn = getUnselectAllBtnFromRowComponent($(this));
  var partTypes = getPartTypesForManufacturer(manufacturer);
  initializePartTypeDropdown(partTypes, partTypeDropdown,selectAllBtn,unselectAllBtn);
}
function selectAllPartTypesBtnClickedHandler() {
  var dropdown = getPartTypeDropdownFromRowComponent($(this));
  multiselectSelectAllOption(dropdown);
}
function deselectAllPartTypesClickedHandler() {
  var dropdown = getPartTypeDropdownFromRowComponent($(this));
  clearDropdownSelection(dropdown);
}
function saveButtonClickedHandler() {
  var missingMatches = getMissingLineCodeMatches();
  if (missingMatches.length) {
    showMissingLineCodeMatchesPopup(missingMatches);
  } else {
    launchImportation();
  }
}
//STATE
var _fileMappings;
function setFileMappings(mappedFile) {
  _fileMappings = mappedFile;
}
function getFileMappings() {
  return _fileMappings;
}

var _fileID;
function setFileID(fileID) {
  _fileID = fileID;
}
function getFileID() {
  return _fileID
}

var _lineCodeMatches;
function getDefaultLineCodeMatches() {
  return _lineCodeMatches;
}
function setDefaultLineCodeMatches(matches) {
  _lineCodeMatches = matches;
  applyDefaultLineCodeMatches();
}

var _manufacturerPartTypes;
function getManufacturerPartTypes() {
  return _manufacturerPartTypes;
}
function setManufacturerPartTypes(mpts) {
  _manufacturerPartTypes = mpts;
}

var _redirectURL;
function getRedirectURL() {
  return _redirectURL;
}
function setRedirectURL(url) {
  _redirectURL = url;
}
//DAL
function getMappings() {
  return getItemFromSession("mappings");
}

function getCurrConnectionLabel() {
  return getItemFromSession("connectionLabel");
}

function getPartTypesForManufacturer(manufacturerName) {
  return getManufacturerPartTypes().find(function (x) {
    return x.manufacturerName == manufacturerName;
  }).partTypeNames;
}
function launchImportation() {
  turnOnSpinner(getSaveBtn());
  resetImportStatusState();
  insertPriceList();
  insertManufacturerPartTypeLineCode();
  insertPriceListMappingsAJAX();
}

function insertPriceList() {
  $.ajax({
    url: "/ajax/insertPriceList",
    type: "POST",
    data: {
      fileMappings: getFileMappings(),
      connectionLabel: getCurrConnectionLabel(),
      fileID: getFileID()
    },
  }).done(function () {
    priceListImportDone = true;
    importDoneAction();
  });
}

function insertManufacturerPartTypeLineCode() {
  $.post(
    "/ajax/insertManufacturerPartTypeLineCode",
    {
      connectionLabel: getCurrConnectionLabel(),
      matches: getLineCodeMatches(),
    },
    function (resp) {
      lineCodeMatchesImportDone = true;
      importDoneAction();
    }
  );
}

function insertPriceListMappingsAJAX() {
  $.post(
    "/ajax/insertPriceListMappings",
    {
      connectionLabel: getCurrConnectionLabel(),
      mappings: getFormattedMappingsForAJAXInsert(),
    },
    function (resp) {
      mappingsImportDone = true;
      importDoneAction();
    }
  );
}

//DOM SET
function initializePartTypeDropdown(partTypes, dropdown,selectAllBtn,unselectAllBtn) {
  var options = "<option></option>" + getOptionsForPartTypes(partTypes);
  dropdown.prop("disabled",false);
  selectAllBtn.prop("disabled",false);
  selectAllBtn.removeClass("disabled");
  unselectAllBtn.prop("disabled",false);
  unselectAllBtn.removeClass("disabled");
  dropdown.html(options);
}
function showMissingLineCodeMatchesPopup(missingMatches) {
  new popupConfirmation(
    getMissingLineCodeMatchesModalMsg(missingMatches),
    function () {
      launchImportation();
    },
    __("price-list-import.line-code-mathces-missing-modal.yes-btn"),
    __("price-list-import.line-code-mathces-missing-modal.no-btn")
  );
}

//DOM GET
function getPartTypeDropdownFromRowComponent(component) {
  return component.parent().parent().find(".part-type-dropdown");
}
function getSaveBtn() {
  return $(".price-list-import-line-code-association-container .save-btn");
}
function getLineCodeAssociationRows() {
  return $(".line-code-association-row");
}
function getSelectAllBtnFromRowComponent(component) {
  return component.parent().parent().find(".select-all-part-types-btn");
}
function getUnselectAllBtnFromRowComponent(component) {
  return component.parent().parent().find(".deselect-all-part-types-btn");
}

//Logic
function getFormattedMappingsForAJAXInsert() {
  return getMappings().map(function (x) {
    return {
      TabName: x.tab,
      FieldTypeName: x.name,
      MoreInfoValue: x.moreInfo,
    };
  });
}
function getMissingLineCodeMatchesModalMsg(missingMatches) {
  return (
    __("price-list-import.line-code-mathces-missing-modal.msg.part1") +
    "</br>" +
    wrapStringInBoldTab(missingMatches.join(", ")) +
    "</br>" +
    __("price-list-import.line-code-mathces-missing-modal.msg.part2")
  );
}
function getOptionsForPartTypes(partTypes) {
  return partTypes
    .map(function (x) {
      return "<option value='" + x + "'>" + x + "</option>";
    })
    .join();
}
function getLineCodeMatches() {
  return getLineCodeAssociationRows()
    .map(function () {
      return {
        lineCode: getLineCodeFromRow($(this)),
        manufacturer: getBrandDropdownFromRow($(this)).val(),
        partTypes: getPartTypeDropdownFromRow($(this)).val(),
      };
    })
    .toArray();
}
function importDoneAction() {
  if (importIsDone()) {
    new popupConfirmation(__("price-list-import.line-code-association.save-successful-alert"), getRedirectURL(), "OK", false)
    turnOffSpinner(getSaveBtn());
  }
}

function getLineCodeFromRow(row) {
  return row.data("line-code");
}
function getBrandDropdownFromRow(row) {
  return row.find(".brand-dropdown");
}
function getPartTypeDropdownFromRow(row) {
  return row.find(".part-type-dropdown");
}

function getMissingLineCodeMatches() {
  var matches = getLineCodeMatches();

  return matches
    .filter(function (x) {
      return !x.partTypes.length;
    })
    .map(function (x) {
      return x.lineCode;
    });
}
//State
var priceListImportDone = false;
var lineCodeMatchesImportDone = false;
var mappingsImportDone = false;

function resetImportStatusState() {
  priceListImportDone = false;
  lineCodeMatchesImportDone = false;
  mappingsImportDone = false;
}
function importIsDone() {
  return priceListImportDone && lineCodeMatchesImportDone && mappingsImportDone;
}
