$(".matrix-modal").ready(function () {
  /*event listeners*/
  $(".matrix-modal").on(
    "click",
    ".matrix-modal-cancel-btn",
    matrixModalCancelHandler
  );

  attachMatrixesEventListeners($(".discount-matrix-modal-row-container"));
});
/*Setup*/
function attachMatrixesEventListeners(matrixesContainer) {
  matrixesContainer.on(
    "keypress",
    ".matrix-rate-input",
    matrixRateKeyPressHandler
  );
  matrixesContainer.on(
    "click",
    ".matrix-blocker",
    matrixBlockerClickEventHandler
  );
  /*make matrixes sortable*/
  matrixesContainer.sortable({
    items: ".matrix-container:not(.matrix-disabled)",
    cancel: ":input,button,select,.matrix-rate-input",
    helper: "clone",
  });
}
/*state*/
var MatrixesPathState = {};

/*event handlers*/
function showDiscountMatrixModal(
  title,
  matrixes,
  connectionsAvailable,
  matrixesPath,
  isEditing
) {
  var matrixesContainer = $(".discount-matrix-modal-row-container");
  matrixesContainer.html("");
  $(".matrix-modal-title").html(title);
  MatrixesPathState = matrixesPath;

  createAndAppendMatrixesToDOM(matrixes, connectionsAvailable, matrixesContainer);
  $(".matrix-modal").show();
  if (isEditing) enableMatrixEditing(matrixesContainer);
  else disableMatrixEditing();

  makeItemsSameWidth($(".matrix-price-type-selector"));
}

function matrixBlockerClickEventHandler() {
  $(this).parent().removeClass("matrix-disabled");
  $(this).hide();
  sortMatrixesContainer($(this).closest(".discount-matrix-modal-row-container"));
}

function matrixRateKeyPressHandler(e) {
  var x = event.charCode || event.keyCode;
  if (
    (isNaN(String.fromCharCode(e.which)) && x != 46) ||
    x === 32 ||
    x === 13 ||
    (x === 46 && $(this).html().includes("."))
  )
    e.preventDefault();
}

function matrixModalCancelHandler() {
  $(".matrix-modal").hide();
}

function matrixModalOkHandler() {
  if (!ratesAreValid($(".discount-matrix-modal-row-container"))) {
    showInvalidRateModal();
  } else {
    updateMatrixes(getMatrixesFromMatrixesContainer($(".discount-matrix-modal-row-container")));
    $(".matrix-modal").hide();
  }
}

/*logic*/
function ratesAreValid(matrixesContainer) {
  var ratesAreValid = true;
  matrixesContainer.find(".matrix-container")
    .not(".matrix-disabled")
    .find(".matrix-rate-input")
    .each(function () {
      ratesAreValid = ratesAreValid && !isNullOrWhiteSpace($(this).html());
    });
  return ratesAreValid;
}
function getMatrixesFromMatrixesContainer(matrixesContainer) {
  var matrixes = [];
  matrixesContainer
    .children()
    .toArray()
    .forEach(function (x, i) {
      if (!$(x).hasClass("matrix-disabled")) {
        matrixes.push({
          manufacturer: MatrixesPathState.Manufacturer,
          partType: "",
          priceType: $(x).find(".matrix-price-type-selector").val(),
          operatorSymbol: $(x).find(".matrix-operator-selector").val(),
          rateTypeSymbol: $(x).find(".matrix-rate-type-selector").val(),
          rate: $(x).find(".matrix-rate-input").html(),
          connectionLabel: $(x).find(".matrix-provider-name-container").html(),
          order: i + 1,
        });
      }
    });
  return matrixes;
}
function updateMatrixes(matrixesModified) {
  var editingMatrixes = getItemFromSession("editing-matrixes");

  var category = editingMatrixes.find(function (cat) {
    return cat.categoryName === MatrixesPathState.Category;
  });
  if (category) {
    var subCategory = category.matrixesBySubCategory.find(function (subcat) {
      return subcat.subCategoryName === MatrixesPathState.Subcategory;
    });
    if (subCategory) {
      var partType = subCategory.matrixesByPartType.find(function (partType) {
        return partType.partTypeName === MatrixesPathState.PartType;
      });
      if (partType) {
        assignMatrixesToPartType(matrixesModified, partType);
      } else {
        assignMatrixesToSubCategory(matrixesModified, subCategory);
      }
    } else {
      assignMatrixesToCategory(matrixesModified, category);
    }
  } else {
    assignMatrixesToManufacturer(matrixesModified, editingMatrixes);
  }
  storeItemInSession("editing-matrixes", editingMatrixes);
  refreshMatrixesThumbnail(editingMatrixes);
}
function assignMatrixesToPartType(matrixes, partType) {
  partType.orderedMatrixes = matrixes;
}

function assignMatrixesToSubCategory(matrixes, subCategory) {
  subCategory.matrixesByPartType.forEach(function (x) {
    assignMatrixesToPartType(matrixes, x);
  });
}

function assignMatrixesToCategory(matrixes, category) {
  category.matrixesBySubCategory.forEach(function (x) {
    assignMatrixesToSubCategory(matrixes, x);
  });
}

function assignMatrixesToManufacturer(matrixes, manufacturer) {
  manufacturer.forEach(function (x) {
    assignMatrixesToCategory(matrixes, x);
  });
}

function sortMatrixesContainer(matrixesContainer) {
  var sorted = matrixesContainer.children().sort(function (a, b) {
    if ($(a).hasClass("matrix-disabled")) return 1;
    if ($(b).hasClass("matrix-disabled")) return -1;
    return $(a).data("order") - $(b).data("order");
  });
  matrixesContainer.html(sorted);
}

function getMatrixForConnection(matrixes, connection) {
  return matrixes.find(function (matrix) {
    return matrix.connectionLabel == connection.connectionLabel;
  });
}
//DOM SET
function showInvalidRateModal() {
  new popupConfirmation(
    __("matrix-modal.rate-input.empty-error-msg"),
    false,
    "Ok",
    false
  );
}
function disableMatrixEditing() {
  console.log("disabling");
  $(".matrixes-non-edit-blocker").show();
  $(".matrix-modal-cancel-btn").hide();
  $(".matrix-modal-description").hide();
  $(".matrix-blocker-msg").empty();
  setOkButtonEventHandler(matrixModalCancelHandler);
}
function enableMatrixEditing(matrixesContainer) {
  console.log("enabling");
  $(".matrixes-non-edit-blocker").hide();
  $(".matrix-modal-cancel-btn").show();
  $(".matrix-modal-description").show();
  matrixesContainer.find(".matrix-blocker-msg").html(__("matrix-modal.blocker.edit.msg"));
  setOkButtonEventHandler(matrixModalOkHandler);
}
function setOkButtonEventHandler(handler) {
  $(".matrix-modal-ok-button").off("click").click(handler);
}
/*Matrix populator*/
function makeItemsSameWidth(items) {
  var widths = items
    .map(function () {
      return $(this).outerWidth();
    })
    .get();
  var maxWidth = Math.max.apply(Math, widths);
  items.css({
    "min-width": maxWidth,
    width: maxWidth,
  });
}

function createAndAppendMatrixesToDOM(matrixes, connectionsAvailable, matrixesContainer) {
  connectionsAvailable.forEach(function (connection) {
    var newRow = $("#matrix-base-row-container").children().clone();

    populatePriceSelector(newRow, connection);
    populateConnectionlabel(newRow, connection);

    var matrix = getMatrixForConnection(matrixes, connection);
    if (matrix) {
      populateMatrixInfos(newRow, matrix);
    } else {
      disableMatrix(newRow);
    }
    matrixesContainer.append(newRow);
  });
  sortMatrixesContainer(matrixesContainer);
}

function populatePriceSelector(newRow, connection) {
  connection.priceTypes.forEach(function (priceType) {
    newRow
      .find(".matrix-price-type-selector")
      .append(
        '<option value="' +
          priceType.priceTypeName +
          '">' +
          priceType.priceTypeName +
          "</option>"
      );
  });
}

function populateConnectionlabel(newRow, connection) {
  newRow
    .find(".matrix-provider-name-container")
    .html(connection.connectionLabel);
}

function populateMatrixInfos(newRow, matrix) {
  newRow.find(".matrix-operator-selector").val(matrix.operatorSymbol);
  newRow.find(".matrix-rate-type-selector").val(matrix.rateTypeSymbol);
  newRow.find(".matrix-rate-input").html(matrix.rate);
  newRow.find(".matrix-price-type-selector").val(matrix.priceType);
  newRow.data("order", matrix.order);

  newRow.find(".matrix-blocker").hide();
}

function disableMatrix(row) {
  row.addClass("matrix-disabled");
  row.find(".matrix-blocker").show();
}
