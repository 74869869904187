jQuery(document).ready(function(){
    jQuery('.js-customer-type').click(function() {
        toggleEnterpriseFields();
    });

    function toggleEnterpriseFields() {
        if (jQuery('.js-radio-enterprise').is(':checked')) { 
            jQuery('.js-fields-enterprise').fadeIn();
         } else {
            jQuery('.js-fields-enterprise').fadeOut();
         }
    }

    toggleEnterpriseFields();



    jQuery('#profile_address_country').change(function() {
        toggleStateList();
    });

    function toggleStateList() {
        jQuery('.js-state').addClass('hide');
        jQuery('.js-province').addClass('hide');
        if (jQuery('#profile_address_country').val() == 'CA') {
            jQuery('.js-province').removeClass('hide');
        } else if (jQuery('#profile_address_country').val() == 'US') {
             jQuery('.js-state').removeClass('hide'); 
        }
    }

    toggleStateList();


    jQuery('.js--multi-address').each(function(){
        var country = $(this).find("select[name*='country']");
        var province = $(this).find("select[name*='province']").closest(".form-group");
        var state = $(this).find("select[name*='state']").closest(".form-group");

        function toggleStateList(){
            province.addClass('hide');
            state.addClass('hide');
            if(country.val() == 'CA')
                province.removeClass('hide');
            else if(country.val() == 'US')
                state.removeClass('hide');
        }
        country.change(toggleStateList);
        toggleStateList();
    })

});