var validationTimeout;
var goButton;
var partTextfield;



        // jQuery('.js-search-part-number').each(function(){
        //     if(jQuery(this).val().length > 2) {
        //         goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
        //         goButton.removeAttr('disabled');
        //         goButton.addClass('valid');
        //     }
        // });


jQuery('document').ready(function(){
    jQuery('.js-search-part-number').on('keyup', '.js-search-part-number-text', function(){
        if(jQuery(this).val().length > 2) {
            goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        }
    }).on('change', '.js-search-part-number-text', function(){
        if(jQuery(this).val().length > 2) {
            goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        }
    }).on('click', '.js-search-part-number-text', function(){
        if(jQuery(this).val().length > 2) {
            goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        }
    }).on('mouseup', '.js-search-part-number-text', function(){
        if(jQuery(this).val().length > 2) {
            goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        }
    }).on('blur', '.js-search-part-number-text', function(){
        if(jQuery(this).val().length > 2) {
            goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        }
    });
    jQuery('.js-search-part-number').on('click', '.js-go-button', function(e) {
        e.preventDefault();
        var button = $(this);
        var form =  button.closest('form')
        var input = form.find('.js-search-part-number-text')
        partTextfield = input.val();
        url = input.data('url');
        var loader = button.find('.js-loader').removeClass('hide');
        var text = button.find('.js-text').addClass('hide');
        
        
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                partNumber : partTextfield
            },
            success: function(data){
                if(data.valid == 1) {
                    form.submit();
                } else {
                    input.val('');
                    input.blur();
                    loader.addClass('hide');
                    text.removeClass('hide');
                    button.removeClass('valid');
                    
                    if(lang == 'en') {
                        input.attr("placeholder", "No result found.");
                    } else {
                        input.attr("placeholder", "Aucun résultat trouvé.");
                    }
                }
                
            },
            error: function(){
       
                jQuery('.js-search-part-number-text').val('');
                jQuery('.js-search-part-number-text').blur();
                loader.addClass('hide');
                text.removeClass('hide');
                button.removeClass('valid');
                if(lang == 'en') {
                    jQuery('.js-search-part-number-text').attr("placeholder", "No result found.");
                } else {
                    jQuery('.js-search-part-number-text').attr("placeholder", "Aucun résultat trouvé.");
                }

            }
        });
    });
});
