$(function () {
  //setup
  disabledNextBtn();
  disableUploadBtn();
  //event listeners
  $("#file-upload-form #file-input").change(fileInputChangedHandler);
  $(".upload-file-btn").on("click", uploadFile);
  $(".file-content-table-container").on(
    "change",
    ".field-type-selector",
    fieldTypeDropdownChangedHandler
  );
  $(".price-list-import-container .next-btn").on(
    "click",
    nextBtnClickedHandler
  );
});

//Handlers
function fileInputChangedHandler() {
  console.log($(this).val());
  if (!isNullOrWhiteSpace($(this).val())) enableUploadBtn();
  else disableUploadBtn();
}
function fieldTypeDropdownChangedHandler() {
  if (isNullOrWhiteSpace($(this).val())) return;
  var fieldInfo = getFieldInfoObjectFromDropdown($(this));
  clearMoreInfoContainerByTabName(fieldInfo.tab);

  if (!fieldInfo.needsMoreInfo)
    deselectOtherFieldDropdownsWithSameValue(fieldInfo);
  if (fieldInfo.needsMoreInfo) {
    showFieldMoreInfoModal(fieldInfo);
  }
}
function nextBtnClickedHandler() {
  setFieldInfoObjectsState();
  var missingRequiredFields = getMissingRequiredFields();

  if (missingRequiredFields.length)
    showMissingRequiredFieldsModal(missingRequiredFields);
  else redirectToStep2();
}

//redirect
function redirectToStep2() {
  var mappings = getFieldInfoObjectState();
  storeItemInSession("mappings", mappings);

  //redirect to step2 with post parameters
  var form = $("#step2-redirect-form");
  form.find("input[name='file-mappings']").val(JSON.stringify(mappings));
  form.find("input[name='file-id']").val(getItemFromSession("fileID"));
  form.submit().remove();
}

//DAL
function uploadFile() {
  var btn = $(this);
  turnOnSpinner(btn);
  $.ajax({
    url: "/dashboard/price-list-import/file-content-table",
    type: "POST",
    data: new FormData($("#file-upload-form")[0]),
    cache: false,
    contentType: false,
    processData: false,
  }).done(function (response) {
    populateFileContentTableContainer(response);
    assignDefaultMappings();
    initializeSelect2ForFieldTypeDropdown();
    turnOffSpinner(btn);
    enableNextBtn();
  });
}
function getRequiredFields() {
  return getItemFromSession("fields").filter(function (x) {
    return x.required;
  });
}
function getDefaultMappings() {
  return getItemFromSession("default-mappings");
}
//DOM Set
function disabledNextBtn() {
  var btn = getNextBtn();
  btn.addClass("disabled");
  btn.prop("disabled", true);
}
function enableNextBtn() {
  enableBtn(getNextBtn());
}

function disableUploadBtn() {
  var btn = $(".upload-file-btn");
  btn.addClass("disabled");
  btn.prop("disabled", true);
}
function enableUploadBtn() {
  enableBtn($(".upload-file-btn"));
}

function enableBtn(btn) {
  btn.removeClass("disabled");
  btn.prop("disabled", false);
}

function assignDefaultMappings() {
  getDefaultMappings().forEach(function (x) {
    getFieldTypeDropdownByTabName(x.tabName).val(x.fieldTypeName);
    if (!isNullOrWhiteSpace(x.moreInfoValue))
      getFieldTypeMoreInfoContainerByTabName(x.tabName).html(x.moreInfoValue);
  });
}
function populateFileContentTableContainer(table) {
  $(".file-content-table-container").html(table);
}
function initializeSelect2ForFieldTypeDropdown() {
  getAllFieldTypeDropdowns().select2({
    placeholder: __(
      "price-list-import.file-content-table.field-type-selector.placeholder"
    ),
    dropdownAutoWidth: true
  });
}
function clearMoreInfoContainerByTabName(tabName) {
  getFieldTypeMoreInfoContainerByTabName(tabName).empty();
}
function showMissingRequiredFieldsModal(missingFields) {
  showAlertPopup(
    __(
      "price-list-import.line-code-association.missing-require-field-modal.text"
    ) +
      " " +
      wrapStringInBoldTab(missingFields.join(", "))
  );
}

//DOM Get
function getNextBtn() {
  return $(".price-list-import-container .next-btn");
}
function getFieldTypeDropdownByTabName(tabName) {
  return getAllFieldTypeDropdowns().filter(getDataTabSelector(tabName));
}
function getFieldTypeMoreInfoContainerByTabName(tabName) {
  return getAllFieldTypeMoreInfoContainer().filter(getDataTabSelector(tabName));
}
function getAllFieldTypeDropdowns() {
  return $(".file-content-table-container .field-type-selector");
}
function getAllFieldTypeMoreInfoContainer() {
  return $(".field-type-more-info-container .field-type-more-info-item");
}

//Logic
function getDataTabSelector(tabName) {
  return "[data-tab='" + tabName + "']";
}
function getAllFieldInfoObjects() {
  return getAllFieldTypeDropdowns()
    .map(function () {
      return getFieldInfoObjectFromDropdown($(this));
    })
    .toArray();
}
function getAllMoreInfoObjects() {
  return getAllFieldTypeMoreInfoContainer()
    .map(function () {
      return getMoreInfoObjectFromContainer($(this));
    })
    .toArray();
}
function getTabNameFromComponent(component) {
  return component.data("tab");
}
function getNeedsMoreInfoFromComponent(component) {
  return component.data("needs-more-info");
}
function deselectOtherFieldDropdownsWithSameValue(selectedFieldInfo) {
  getAllFieldTypeDropdowns().each(function () {
    var currFieldInfo = getFieldInfoObjectFromDropdown($(this));
    if (
      currFieldInfo.name == selectedFieldInfo.name &&
      currFieldInfo.tab != selectedFieldInfo.tab
    ) {
      clearDropdownSelection($(this));
    }
  });
}
function getAllMergedFieldInfoAndMoreInfoObjects() {
  return innerJoinArrays(
    getAllFieldInfoObjects(),
    getAllMoreInfoObjects(),
    function (info, moreInfo) {
      return info.tab == moreInfo.tab;
    }
  );
}
function getAssignedFieldInfoAndMoreInfoObjects() {
  return getAllMergedFieldInfoAndMoreInfoObjects().filter(function (fieldInfo) {
    return fieldInfo.name != "";
  });
}
function getMissingRequiredFields() {
  return getRequiredFields()
    .filter(function (field) {
      return getFieldInfoByFieldName(field.fieldName) == null;
    })
    .map(function (field) {
      return field.fieldName;
    });
}

//State
var _fieldInfoObjectsState;
function setFieldInfoObjectsState() {
  _fieldInfoObjectsState = getAssignedFieldInfoAndMoreInfoObjects();
}
function getFieldInfoObjectState() {
  return _fieldInfoObjectsState;
}

var _fileContent;
function setFileContent(fileContent) {
  _fileContent = fileContent;
}
function getFileContent() {
  return _fileContent;
}

//Converter
function getMoreInfoObjectFromContainer(container) {
  return {
    tab: getTabNameFromComponent(container),
    moreInfo: container.html().trim(),
  };
}
function getFieldInfoObjectFromDropdown(fieldDropdown) {
  var selected = getSelectedFromDropdown(fieldDropdown);
  return {
    name: selected.val(),
    tab: getTabNameFromComponent(fieldDropdown),
    needsMoreInfo: getNeedsMoreInfoFromComponent(selected),
    moreInfoKeyName: selected.data("more-info-key-name"),
    moreInfoValueName: selected.data("more-info-value-name"),
  };
}

function getFieldInfoByFieldName(fieldName) {
  return getFieldInfoObjectState().find(function (fieldInfo) {
    return fieldInfo.name == fieldName;
  });
}
