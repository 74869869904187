/**
 * HOW TO USE
 *
 * ("text to translate")
 * ("text to translate","lg")
 * ("text to translate",["repalce %s"])
 * ("text to translate",["repalce %s"],'lg')
 *
 * $("div")._e("text to translate");
 * document.getElementById("#to_trans")._e("text to translate");
 * __("text to translate");
 * Translator.trans("text to translate");
 * Translator.getLang();
 * Translator.setLang("lg");
 * Translator.add("lg","text to translate","Translator Responce");
 * Translator.add("lg","text to translate",{
 *  0: "0 as param[0]"
 *  1: "1 as param[0]"
 *  '': "everyting else as param[0]"
 * });
 *
 */

function TranslatorJS() {
  var lang = "en";
  if (document.querySelector("html").lang != undefined)
    lang = document.querySelector("html").lang;
  var trans = {
    en: {
      "popup.reset_directory.text":
        "You're about to delete your entire Product Directory and Linecodes. This action cannot be undone.<br /><br />Are you sure you want continue?",
      "searchbar.placeholder":
        "Search by year, make, model, product, part type or brand.",
      Go: "Go",
      "All Categories": "All Categories",
      Year: "Year",
      Make: "Make",
      Model: "Model",
      "All Brands": "All Brands",
      Brands: "Brands",
      "Part types": "Part types",
      Parts: "Parts",
      "Add to cart": "Add to cart",
      "View cart": "View cart",
      "Advanced search": "Advanced search",

      //customer-dashboard
      "pricing-structure-dropdown.place-holder": "Select a pricing structure",

      //brand-dropdown
      "brand-dropdown.place-holder": "Select a brand",

      //pricing-management
      "pricing-management.rename-structure-modal.title":
        "Please enter new information for the pricing structure",
      "pricing-management.add-structure-modal-placeholder": "Counter",
      "pricing-management.pricing-structure-add-prompt-msg":
        "Please enter a name for your new pricing structure",
      "pricing-management.structure-name-placeholder":
        "Structure's name",
      "pricing-management.pricing-structure-add-already-exists-error-msg":
        "A structure with the name specified already exists",
      "pricing-management.pricing-structure-edition.name-empty-error-msg":
        "Please enter a name for your pricing structure",
      "pricing-management.pricing-structure-delete-confirm-msg":
        "Please confirm that you want to delete <b>permanently</b> the pricing structure",
      "pricing-management.manufacturer-dropdown-change-no-structure-error-msg":
        "Please select a pricing structure first",
      "pricing-management.default-matrixes-title":
        "Choose a default price structure for all brands",

      "matrix-modal.rate-input.empty-error-msg":
        "Please enter a rate for every matrixes",
      "matrix-modal.blocker.edit.msg": "Click to enable",

      //price-list
      "price-list.add-connection-label-modal.msg":
        "Please enter a name for your new price list",
      "price-list.edit-connection-label-modal.msg":
        "Please enter a new name for the price list",
      "price-list.connection-already-exists.msg":
        "A list with this name already exists:",
      "price-list.delete-confirm.msg":
        "Please confirm that you want to delete <b>permanently</b> the price list",

      //price-list-import
      "price-list-import.file-content-table.field-type-selector.placeholder":
        "Field type",

      "price-list-import.more-info-modal.label.ProductPricing":
        "Enter the price type corresponding to the column",
      "price-list-import.more-info-modal.label.ProductInventory":
        "Enter the inventory location corresponding to the column",

      "price-list-import.more-info-modal.input-placeholder.ProductPricing":
        "EX: Jobber, Cost, Core, Promo, ...",
      "price-list-import.more-info-modal.input-placeholder.ProductInventory":
        "EX: Warehouse, Ottawa, Montreal, ...",

      "price-list-import.more-info-modal.error.input-empty":
        "Please enter a value",
      "price-list-import.more-info-modal.error.input-already-exists":
        "A field with this type already exists",

      "price-list-import.line-code-mathces-missing-modal.msg.part1":
        "WARNING! The following line codes are not mapped:",
      "price-list-import.line-code-mathces-missing-modal.msg.part2":
        "Do you wish to continue?",
      "price-list-import.line-code-mathces-missing-modal.yes-btn": "Yes",
      "price-list-import.line-code-mathces-missing-modal.no-btn": "No",

      //price-list-import.line-code-association
      "price-list-import.line-code-association.part-type-dropdown.placeholder":
        "Select partTypes",
      "price-list-import.line-code-association.save-successful-alert":
        "Save successfull",
      "price-list-import.line-code-association.missing-require-field-modal.text":
        "Please specify the following fields:",
      "price-list-import.line-code-association.connection-label-invalid-modal.text":
        "Connection name invalid, must be between 1 and 55 cahracters",
    },
    fr: {
      Cancel: "Annuler",
      Delete: "Supprimer",
      "Select a customer to impersonate":
        "Sélectionnez un client à impersonifier",
      Search: "Rechercher",
      Back: "Retour",
      "Whoops!": "Oups!",
      "Try changing location or expanding your shopping area.":
        "Essayez de changer votre localisation ou d'élargir votre zone de magasinage.",
      "popup.reset_directory.text":
        "Vous êtes sur le point de supprimer votre catalogue et vos code de ligne en entier. Cette action est irréversible.<br /><br />Souhaitez-vous vraiment continuer ?",
      Open: "Ouvert",
      Closed: "Fermé",
      Keywords: "Mots clés",
      Insert: "Insérer",
      "searchbar.placeholder":
        "Chercher par année, marque, modèle, produit, type de pièce ou manufacturier",

      Go: "Go",
      "All Categories": "Toute les catégories",
      Year: "Année",
      Make: "Marque",
      Model: "Modèle",
      "All Brands": "Toute les marques",
      Brands: "Marques",
      "Part types": "Types de pièces",
      Parts: "Pièces",
      "Add to cart": "Ajouter au panier",
      "View cart": "Voir le panier",
      "Advanced search": "Recheche avancée",
      'No result found for "%s"': 'Aucun résultat trouvé pour "%s"',

      //customer-dashboard
      "pricing-structure-dropdown.place-holder":
        "Sélectionnez une structure de prix",

      //brand-dropdown
      "brand-dropdown.place-holder": "Sélectionnez une marque",

      //pricing-management
      "pricing-management.rename-structure-modal.title":
        "Veuillez saisir un nouveau nom pour la structure de prix",
      "pricing-management.add-structure-modal-placeholder": "Comptoir",
      "pricing-management.pricing-structure-add-prompt-msg":
        "Veuillez entré un nom pour votre nouvelle structure de prix",
      "pricing-management.pricing-structure-edition.name-empty-error-msg":
        "Veuillez saisir un nom pour votre structure de prix",
      "pricing-management.structure-name-placeholder":
        "Nom de la structure",
      "pricing-management.pricing-structure-add-already-exists-error-msg":
        "Une structure avec le nom spécifié existe déjà",
      "pricing-management.pricing-structure-delete-confirm-msg":
        "Veuillez confirmer que vous souhaitez supprimer <b>définitivement</b> la structure de prix",
      "pricing-management.manufacturer-dropdown-change-no-structure-error-msg":
        "Veuillez d'abord sélectionner une structure de prix",
      "pricing-management.default-matrixes-title":
        "Choisissez une structure de prix par défaut pour toutes les marques",

      "matrix-modal.rate-input.empty-error-msg":
        "Veuillez saisir un taux pour chaque matrice",
      "matrix-modal.blocker.edit.msg": "Cliquez pour activer",

      //price-list
      "price-list.add-connection-label-modal.msg":
        "Veuillez saisir un nom pour votre nouvelle liste de prix",
      "price-list.edit-connection-label-modal.msg":
        "Veuillez saisir un nouveau nom pour la liste de prix",
      "price-list.connection-already-exists.msg":
        "Une liste de prix avec ce nom existe déjà:",
      "price-list.delete-confirm.msg":
        "Veuillez confirmer que vous souhaitez supprimer <b>définitivement</b> la liste de prix",

      //price-list-import
      "price-list-import.file-content-table.field-type-selector.placeholder":
        "Type de champ",

      "price-list-import.more-info-modal.label.ProductPricing":
        "Saisissez le type de prix correspondant à la colonne",
      "price-list-import.more-info-modal.label.ProductInventory":
        "Saisissez l'emplacement de l'inventaire correspondant à la colonne",

      "price-list-import.more-info-modal.input-placeholder.ProductPricing":
        "EX: Jobber, Cost, Core, Promo, ...",
      "price-list-import.more-info-modal.input-placeholder.ProductInventory":
        "EX: Entrepôt, Ottawa, Montréal, ...",

      "price-list-import.more-info-modal.error.input-empty":
        "Veuillez entrer une valeur",
      "price-list-import.more-info-modal.error.input-already-exists":
        "Un champ de ce type existe déjà",

      "price-list-import.line-code-mathces-missing-modal.msg.part1":
        "ATTENTION! Les codes de ligne suivants ne sont pas mappés:",
      "price-list-import.line-code-mathces-missing-modal.msg.part2":
        "Souhaitez-vous continuer?",
      "price-list-import.line-code-mathces-missing-modal.yes-btn": "Oui",
      "price-list-import.line-code-mathces-missing-modal.no-btn": "Non",

      //price-list-import.line-code-association
      "price-list-import.line-code-association.part-type-dropdown.placeholder":
        "Sélectionnez les types de pièces",
      "price-list-import.line-code-association.save-successful-alert":
        "Sauvegarde réussie",
      "price-list-import.line-code-association.missing-require-field-modal.text":
        "Veuillez spécifier les champs suivants:",
      "price-list-import.line-code-association.connection-label-invalid-modal.text":
        "Nom de connexion non valide, doit comprendre entre 1 et 55 caractères",
    },
  };

  function replaceAll($string, $array) {
    count = ($string.match(/%s/g) || []).length;
    if (count && count != $array.length)
      throw new Error(
        "The number of parameters does not match the number of variables."
      );
    for (var i = 0; i < count; i++) {
      $string = $string.replace("%s", $array[i]);
    }
    return $string;
  }

  this.set = function ($lang) {
    if (typeof $lang != "string")
      throw new TypeError("First parameter need to be a string");
    return (lang = $lang);
  };
  this.getLang = function () {
    return lang;
  };
  this.add = function ($lang, $key, $text) {
    if (typeof $lang != "string" || typeof $key != "string")
      throw new TypeError("All parameter need to be a string");
    if (trans[lang] == undefined) trans[$lang] = {};
    trans[$lang][$key] = $text;
    return this;
  };
  this.trans = function ($text, $param, $lang) {
    if (typeof $text != "string")
      throw new TypeError("First parameter need to be a string");
    if (typeof $lang != "string") $lang = $param;
    if (typeof $lang != "string") $lang = lang;
    if (!Array.isArray($param)) $param = [];
    if (trans[$lang] != undefined && trans[$lang][$text] != undefined) {
      if (typeof trans[$lang][$text] == "string")
        return replaceAll(trans[$lang][$text], $param);
      else if ($param.length && !isNaN($param[0])) {
        if (trans[$lang][$text][$param[0]] != undefined) {
          return replaceAll(trans[$lang][$text][$param[0]], $param);
        } else {
          return replaceAll(trans[$lang][$text][""], $param);
        }
      }
    }
    return replaceAll($text, $param);
  };
}
// PHP like
window.Translator = new TranslatorJS();
// Wordpress like
window.__ = window.Translator.trans;
if (window.jQuery)
  jQuery.fn._e = function ($t, $p, $l) {
    this.html(window.__($t, $p, $l));
  };
Element.prototype._e = function ($t, $p, $l) {
  this.innerHTML = window.__($t, $p, $l);
};
// Twig like
window.trans = window.Translator.trans;
String.prototype.trans = function ($p, $l) {
  return window.__(this.toString(), $p, $l);
};
