jQuery(document).ready(function(){
    jQuery('.js-btn-export').on('click', function(){
        if (!jQuery(this).hasClass('disabled')) {
            jQuery('.js-container-partial--export, .js-loading-overlay').removeClass('hide');
        }
    });
    jQuery('.js-close-export').on('click', function(){
        jQuery('.js-container-partial--export, .js-loading-overlay').addClass('hide');
    });
    jQuery('.js-select-export').on('change', function(){
        $('[data-select-import]').hide();
        $('[data-select-import*='+$(this).val()+']').show();
    }).trigger('change')
    jQuery('.js-btn-export-link').closest('form').on('submit', function(event){
        event.preventDefault();
        var form = $(this);
        var remember = jQuery('#remember_choices').is(':checked')?'1':null;

        var selectVal = jQuery('.js-select-export').val()


        if ( selectVal == 'email') {
            $.ajax({
                url:form.attr('action'),
                type: 'POST',
                data: form.serialize(),
                success: function(data){
                    jQuery('.js-message-success').removeClass('hide'); 
                },
                error: function(jqXHR) {
                    console.log(jqXHR.status);
                }
            });
        }

        else if(selectVal == 'abmagic' || selectVal == 'clipboard'){
            $.ajax({
                url:'/ajax/exportcart',
                type: 'POST',
                data: {
                    'format' : selectVal,
                    'remember': remember
                },
                dataType: 'text',
                success: function(data){
                    copyToClipboard(data);
                    $('.js-close-export').trigger('click');
                },
                error: function(jqXHR) {
                    console.log(jqXHR.status);
                }
            })
        }
    });

    
});