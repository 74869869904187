jQuery(document).ready(function(){
    $('.js-suspend-subscription').on('click',clickSuspend)

    function clickSuspend(){
        var form = getForm();
        SetSubscription(form, this.dataset['id'])
        form = new popupForm(form, this.innerHTML, {
            success: formSuccess
        });
    }

    function getForm(){
        return document.getElementById('suspend_subscription').cloneNode(true);
    }

    function SetSubscription(form, id){
        $(form).find('[name="suspend_subscription[subscription]"]').val(id).change();
    }

    function formSuccess(event,form){
        form.submit();
        this.close();
    }
})