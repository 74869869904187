jQuery(document).ready(function() {
    jQuery('.js-step3').on('change', '.js-diagram-check', function() {
        $(this).closest('.js-toggle-diagram').toggleClass('on')
        jQuery('.js-container-filter-diagrams, .js-container-categories, .js-container-sort, .js-container-attributes').toggleClass('hide');
        if (!jQuery('.js-container-filter-diagrams').hasClass('hide')) {
            jQuery('.js-filter-diagrams').find('input:first').attr('checked', 'checked'); 
            diagram_id = jQuery('.js-filter-diagrams').find('input:checked').val();
            jQuery('input#diagram-' + diagram_id).trigger('change');
        } else {
            jQuery('.js-parttype-header-diagram').remove();
            jQuery('.js-parttype-header').removeClass('hide'); 
            jQuery('.js-diagram img, .js-diagram .js-btn-expand').addClass('hide');
            jQuery('.js-part .part-left, .js-part .part-middle, .js-part .part-right').stop().css('display','').css('opacity','')
            jQuery('.js-part').each(function() {
                part_parttypename = jQuery(this).attr('data-parttypename');
                jQuery(this).removeClass('hide');
                jQuery('.js-parttype-header[data-title="' + part_parttypename + '"]').after(jQuery(this));
                //jQuery('.js-parttype-header[data-title="' + part_parttypename + '"]').find('.fa').removeClass('fa-plus-circle').addClass('fa-minus-circle');
            });
            applyStep3Filters();
            SortPartsBy.sort();
        }
    });
});