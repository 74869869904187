// function getItemFromSession(key) {
//   return JSON.parse(sessionStorage.getItem(key));
// }
// function storeItemInSession(key, item) {
//   sessionStorage.setItem(key, JSON.stringify(item));
// }


var globalStorage = {}

// function getItemFromSession(key) {
//   if (globalStorage[key])
//     return globalStorage[key]
//   else
//     return JSON.parse(sessionStorage.getItem(key));
// }
function getItemFromSession(key) {
  var session = JSON.parse(sessionStorage.getItem(key));
  if (session)
    return session
  else
    return globalStorage[key]
}
function storeItemInSession(key, item) {
  globalStorage[key] = item;
  sessionStorage.setItem(key, JSON.stringify(item));
}
