//! sample code to use this collapser:
/// <div class="collapser"><i class='fa fa-angle-right'></i>(clickable, add header msg here)</div>
/// <div class="collapser-content"></div>

$(function () {
    $("body").on(
        "click",
        ".collapser",
        collapserClickedHandler
    );
    $(".collapser").on(
        "load",
        collapserLoadedHandler
    );
})

function collapserClickedHandler() {
    var content = $(this).next(".collapser-content");
    if (content.is(":visible")) {
        content.hide();
        changeAngle($(this), 'right');
    } else {
        content.show();
        changeAngle($(this), 'down');
    }
}
function collapserLoadedHandler() {
    console.log("loaded");
}

function changeAngle(collapser, angle) {
    var icon = collapser.find('i')
    icon.removeClass('fa-angle-right fa-angle-down')
    icon.addClass('fa-angle-' + angle);
}
