jQuery('document').ready(function() {
    $('input[name="cart_checkout[profile][create_account]"]').on('change',function(){
        if($(this).is(':checked')){
            $('[data-container=change_password]').show();
        }
        else{
            $('[data-container=change_password]').hide();
        }
    });

    $('form.js-lock-form-on-submit').submit(function(e){
        $(this).find('button[type=submit]').attr('disabled',true)
    });

     jQuery('.js-cart').on('change', '.js-product-quantity', function() { 
        var total = 0;
        var lang = jQuery('html').attr('lang');
        var qty_update = jQuery(this).val();
        var unitprice = jQuery(this).parent().parent().data('unitprice');
        var subtotal = (unitprice * qty_update).toFixed(2);
        var $this = jQuery(this);
        $this.parent().parent().data('quantity', qty_update);
        $this.parent().siblings('.js-item-total').data('subtotal', subtotal);

        var invoice = JSON.parse($("[data-invoice]").text());
        for(var i=0; i<invoice.referencesOrder.length; i++){
            if(invoice.referencesOrder[i].orderedItem.orderedItem.productID == $this.parent().parent().data('partnumber') &&
                invoice.referencesOrder[i].orderedItem.orderedItem.brand.identifier == $this.parent().parent().data('lineCode')
            ){
                invoice.referencesOrder[i].orderedItem.orderQuantity = qty_update
            }
        }
        invoice.totalPaymentDue.price = subtotal;
        $("[data-invoice]").text(JSON.stringify(invoice));

        if(lang == 'fr'){
            $this.parent().siblings('.js-item-total').html(subtotal + '&nbsp;$');
        } else {
            $this.parent().siblings('.js-item-total').html('$&nbsp;' + subtotal);
        }
        jQuery('.js-item').each(function(){
            sub = jQuery(this).find('.js-item-total').data('subtotal');
            total = +total + +sub;
        });
        if(lang == 'fr'){
            jQuery('.js-amount').html(total.toFixed(2) + '&nbsp;$');
        } else {
            jQuery('.js-amount').html('$&nbsp;' + total.toFixed(2)); 
        }
        var productcode = $this.parent().parent().data('partnumber');
        var linecode = $this.parent().parent().attr('data-line-code');
        var year = $this.parent().parent().data('year');
        var make = $this.parent().parent().data('make');
        var model = $this.parent().parent().data('model');
        var id = $this.parent().parent().data('id');
        var manufacturer = $this.parent().parent().find('.js-item-manufacturer').html();
        var description = $this.parent().parent().find('.js-item-description').html();
        var storeid = $this.data('storeid');
        var sid = $this.data('sid');
        var unitPrice = jQuery(this).data('unitprice');
        var tax = jQuery(this).data('tax');
        var deposit = jQuery(this).data('deposit');
        $this.addClass('hide');
        $this.siblings(".fa").removeClass('hide');
        $.ajax({
            url: '/ajax/deleteitem/',
            dataType: 'JSON',
            type: 'POST',
            data: {
                id : id
            },
            success: function() {
                $.ajax({
                    url: '/ajax/addtocart/',
                    dataType: 'JSON',
                    type: 'POST',
                    data: {
                        quantity : qty_update,
                        productcode : productcode,
                        linecode : linecode,
                        year : year,
                        make : make,
                        model : model,
                        manufacturer : manufacturer,
                        description : description,
                        storeid : storeid,
                        unitprice : unitPrice,
                        tax : tax,
                        deposit : deposit,
                        sid : sid

                    },
                    success: function() {
                        $this.removeClass('hide');
                        $this.siblings(".fa").addClass('hide');
                    }
                });
            }
        });
     });

    jQuery('.js-cart').on('click', '.js-delete-item', function(){
        var url = jQuery('.js-cart .js-delete-item-url').val();
        var item = jQuery(this).closest('.js-item');
        var category = item.closest('.js-item-category');
        var total = jQuery('.js-cart').find('.js-grand-total');
        var buttons = jQuery('.js-cart').find('.js-action-buttons');
        var no_item = jQuery('.js-no-items');
        var loadingIcon = item.find('.js-loading-icon');
        var id = item.attr('data-id');

        
        loadingIcon.removeClass('hide'); 
        jQuery(this).addClass('hide');
        new Analytic.Event('cart','delete-item',item.attr('data-partnumber'),id);
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                id : id
            },
            success: function(data){
                if (data.error) {
                    loadingIcon.addClass('hide');
                } else {
                    $.when(item.remove()).then(updateCartNumbers());
                    
                    if (category.find('.js-item').length == 0) {
                        category.remove();
                    }
                    if (jQuery('.js-cart').find('.js-item').length == 0) {
                        buttons.remove();
                        jQuery('.js-container-partial--step-breadcrumb .js-menu-cart-count').empty(); 
                        var toolBarCount = jQuery('.js-cart .js-menu-cart-count');
                         toolBarCount.html(0);
                        total.remove();
                    }
                     if (jQuery('.cart-listing').find('.js-item').length == 0) {
                        no_item.removeClass('hide');
                     }
                    
                    
                }
                   
            }
        });
    });

     jQuery('.js-cart').on('click', '.js-btn-empty-cart', function(){
        if (!jQuery('.js-btn-empty-cart').hasClass('disabled') ) {
            var category = jQuery('.js-item-category');
            var total = jQuery('.js-cart').find('.js-grand-total');
            var no_item = jQuery('.js-no-items');
            new Analytic.Event('cart','btn-empty-cart');
            $.ajax({
                url:'/ajax/cartPurge',
                success: function(data){
                   category.find('.js-item').remove();
                   category.remove();
                   total.remove();
                   no_item.removeClass('hide');
                    //jQuery('.js-menu-cart-count, .js-breadcrumb-cart-count').html('');
                  jQuery('.js-btn-empty-cart, .js-btn-export').addClass('disabled'); 
                  jQuery('.cart-listing .js-action-buttons').addClass('hide'); 
                  jQuery('.js-menu-cart-count').empty(); 
                  var toolBarCount = jQuery('.js-cart .js-menu-cart-count');
                  toolBarCount.html(0);
                  footerPosition();    
                }
            });
        }
     });


     jQuery('.js-cart').on('change', '.js-na-check', function(){
        if (jQuery(this).prop('checked') && !jQuery(this).siblings().hasClass('disabled')) {
            jQuery('.js-price-mask').show();
            jQuery('.js-price-digit').hide();

        } else {
            jQuery('.js-price-mask').hide();
            jQuery('.js-price-digit').show();
        }
    });

    jQuery('.js-cart-checkout').on('change', '.js-na-check', function(){
        if (jQuery(this).prop('checked') && !jQuery(this).siblings().hasClass('disabled')) {
            jQuery('.js-price-mask').show();
            jQuery('.js-price-digit').hide();
        } else {
            jQuery('.js-price-mask').hide();
            jQuery('.js-price-digit').show();          
        }
    });

     jQuery('.js-cart-confirmation').on('change', '.js-na-check', function(){
        if (jQuery(this).prop('checked') && !jQuery(this).siblings().hasClass('disabled')) {
            jQuery('.js-price-mask').show();
            jQuery('.js-price-digit').hide();
        } else {
            jQuery('.js-price-mask').hide();
            jQuery('.js-price-digit').show();    
        }
    });
    
      jQuery('.js-delivery-method input').on('change', function() {
        if (jQuery('.js-delivery-method input:checked').val() == 'ship') {
            jQuery('.js-shipping-address').removeClass('hide');
            jQuery('.js-store-pick-up').fadeOut(); 
            jQuery('#cart_checkout_shipping_address,#cart_checkout_account_shipping_address').val('');
            jQuery('#cart_checkout_shipping_address2,#cart_checkout_account_shipping_address2').val('');
            jQuery('#cart_checkout_shipping_city,#cart_checkout_account_shipping_city').val('');
            jQuery('#cart_checkout_shipping_state,#cart_checkout_account_shipping_state').val('');
            jQuery('#cart_checkout_shipping_country,#cart_checkout_account_shipping_country').val('');
            jQuery('#cart_checkout_shipping_postal_code,#cart_checkout_account_shipping_postal_code').val('');
            jQuery('.js-pickup_option').addClass('hide');
            $('.js-fieldset-card').removeClass('hide');
       } else if (jQuery('.js-delivery-method input:checked').val() == 'ship_billing'){
            jQuery('.js-shipping-address').addClass('hide');
            jQuery('.js-store-pick-up').fadeOut(); 
            jQuery('#cart_checkout_shipping_address,#cart_checkout_account_shipping_address').val(jQuery('.js-shipping-address-default').val());
            jQuery('#cart_checkout_shipping_address2,#cart_checkout_account_shipping_address2').val(jQuery('.js-shipping-address2-default').val());
            jQuery('#cart_checkout_shipping_city,#cart_checkout_account_shipping_city').val(jQuery('.js-shipping-city-default').val());
            jQuery('#cart_checkout_shipping_state,#cart_checkout_account_shipping_state').val(jQuery('.js-shipping-state-default').val());
            jQuery('#cart_checkout_shipping_country,#cart_checkout_account_shipping_country').val(jQuery('.js-shipping-country-default').val());
            jQuery('#cart_checkout_shipping_postal_code,#cart_checkout_account_shipping_postal_code').val(jQuery('.js-shipping-postal-code-default').val());
            jQuery('.js-pickup_option').addClass('hide');
            $('.js-fieldset-card').removeClass('hide');  
        } else if (jQuery('.js-delivery-method input:checked').val() == 'pickup'){
            jQuery('.js-shipping-address').addClass('hide');
            jQuery('.js-store-pick-up').fadeIn();
            jQuery('#cart_checkout_shipping_address,#cart_checkout_account_shipping_address').val(jQuery('.js-pickup-address').val());
            jQuery('#cart_checkout_shipping_address2,#cart_checkout_account_shipping_address2').val(jQuery('.js-pickup-address2').val());
            jQuery('#cart_checkout_shipping_city,#cart_checkout_account_shipping_city').val(jQuery('.js-pickup-city').val());
            jQuery('#cart_checkout_shipping_state,#cart_checkout_account_shipping_state').val(jQuery('.js-pickup-state').val());
            jQuery('#cart_checkout_shipping_country,#cart_checkout_account_shipping_country').val(jQuery('.js-pickup-country').val());
            jQuery('#cart_checkout_shipping_postal_code,#cart_checkout_account_shipping_postal_code').val(jQuery('.js-pickup-postal-code').val());
            jQuery('.js-pickup_option').removeClass('hide');
            if (jQuery('.js-pickup_option input:checked').val() == 'pay_now')
                $('.js-fieldset-card').removeClass('hide');
            else
                $('.js-fieldset-card').addClass('hide');
        }
        updateCartShippingFee();
    });

    jQuery('.js-pickup_option input').on('change', function() {
        if($(this).val() == 'pay_now'){
            $('.js-fieldset-card').removeClass('hide');
        }
        else{
            $('.js-fieldset-card').addClass('hide');
        }
        updateCartShippingFee();
    });

    jQuery('input[class*=js-cart-checkout-shipping-],select[class*=js-cart-checkout-shipping-]').on('change', function(){
        updateCartShippingFee();
    });
    
    jQuery('input[class*=js-shipping-],select[class*=js-shipping-]').on('change',function(){
        if (jQuery('.js-delivery-method input:checked').val() == 'ship_billing'){
            jQuery('#cart_checkout_shipping_address,#cart_checkout_account_shipping_address').val(jQuery('.js-shipping-address-default').val());
            jQuery('#cart_checkout_shipping_address2,#cart_checkout_account_shipping_address2').val(jQuery('.js-shipping-address2-default').val());
            jQuery('#cart_checkout_shipping_city,#cart_checkout_account_shipping_city').val(jQuery('.js-shipping-city-default').val());
            jQuery('#cart_checkout_shipping_state,#cart_checkout_account_shipping_state').val(jQuery('.js-shipping-state-default').val());
            jQuery('#cart_checkout_shipping_country,#cart_checkout_account_shipping_country').val(jQuery('.js-shipping-country-default').val());
            jQuery('#cart_checkout_shipping_postal_code,#cart_checkout_account_shipping_postal_code').val(jQuery('.js-shipping-postal-code-default').val());
            
        }
        updateCartShippingFee();
    })

    if (jQuery('.js-billing-address').length){
        updateCartTotals();
        jQuery('.js-billing-address').on('change', function(){
            updateCartTotals();
        });
    }
});

/**
 * Updates the numbers in the page related to the cart items
 */
function updateCartNumbers()
{
    var count = 0;
     var lang = jQuery('html').attr('lang');
    //Grand total calculation
    var total = 0;
    jQuery('.js-item').each(function(){
        total += parseFloat(jQuery(this).attr('data-unitprice')) * parseInt(jQuery(this).attr('data-quantity'))
        count++;
    });
     if (lang == 'fr') {
        jQuery('.js-grand-total .js-amount').html('$&nbsp;' + total.formatMoney(2, '.', ' '));
    }else{
         jQuery('.js-grand-total .js-amount').html( total.formatMoney(2, '.', ' ') + '&nbsp;$');
    }
    
    //Total count in the menu
    var menuCount = jQuery('.js-container-partial--step-breadcrumb .js-menu-cart-count');
    var toolBarCount = jQuery('.js-cart .js-menu-cart-count');
    toolBarCount.html(count);
    
    if (count > 0){
        menuCount.html('(' + count + ')');

    } else {
        menuCount.html('');
    }
}

var updateCartShippingFeeRquest = null;
function updateCartShippingFee(){
  
    if (jQuery('.js-delivery-method input:checked').val() == 'ship') {
        var shipping_address = [
            jQuery('.js-cart-checkout-shipping-address').val(),
            jQuery('.js-cart-checkout-shipping-address2').val(),
            jQuery('.js-cart-checkout-shipping-city').val(),
            jQuery('.js-cart-checkout-shipping-state').val(),
            'CA',
            jQuery('.js-cart-checkout-shipping-postal_code').val(),
        ]
    } else {
        shipping_address = [
            $('.js-shipping-address-default').val(),
            $('.js-shipping-address2-default').val(),
            $('.js-shipping-city-default').val(),
            $('.js-shipping-state-default').val(),
            $('.js-shipping-country-default').val(),
            $('.js-shipping-postal-code-default').val(),
        ];
        //var shipping_address = jQuery('.js-delivery-method input:checked').val();
    }
   
    var shipping_price;
    var lang = jQuery('html').attr('lang');
    jQuery('.js-loader').removeClass('hide');
    if (jQuery('.js-delivery-method input:checked').val() != 'pickup') {
        if(window.ajaxGetShipping) window.ajaxGetShipping.abort();
        updateCartShippingFeeRquest = $.ajax({
        url:'/ajax/getShipping',
        dataType: 'JSON',
        type: 'POST',
        beforeSend : function()    {           
            if(updateCartShippingFeeRquest != null) {
                updateCartShippingFeeRquest.abort();
            }
        },
        data: $('form[name="cart_checkout"]').serialize(),
        success: function(data){

                if (data == null) {
                    shipping_price = '---';
                    if (lang == 'fr') {
                        invalid_address = 'Non déterminé'; 
                    } else {
                        invalid_address = 'Non specified';
                    }
                    jQuery('.js-shipping-transporter').html(invalid_address);
                    jQuery('.js-shipping-delay, .js-discount').addClass('hide');
                    
                } else {
                    shipping_price = parseFloat(data.total_shipping_prices).toFixed(2);

                    // OTTO optionnal field display
                    if(data.includeAllItems === true)
                        $('.js-mixed-delivery').hide().find('input[type=checkbox]').prop("checked", false);
                    else
                        $('.js-mixed-delivery').show();

                    jQuery('.js-shipping-transporter').html(data.text);
                    jQuery('.js-shipping-delay, .js-discount').removeClass('hide');
                    jQuery('.js-btn-checkout').prop('disabled', false);

                }
                updateCartSubTotal(shipping_price);
                jQuery('.js-pick-up').addClass('hide');
            }
        });
    } else {
        shipping_price = 0;
        jQuery('.js-shipping-transporter').html('');
        jQuery('.js-shipping-delay, .js-discount').addClass('hide');
        updateCartSubTotal(shipping_price);
        jQuery('.js-btn-checkout').prop('disabled', false);  
        jQuery('.js-pick-up').removeClass('hide');
    }
   
}

function updateCartSubTotal(shipping_price)
{
    if(isNaN(shipping_price)) shipping_price = 0;
    var items_subtotal = 0;
    var shipping_flat_fee = 0;
    var lang = jQuery('html').attr('lang');
    var discount = jQuery('.js-input-shipping-rebate').val();
    var shipping_final_price = parseFloat(shipping_price);//parseFloat(+shipping_price - +discount).toFixed(2);
    /*if (jQuery('.js-shipping-flat-fee').length) {
        shipping_flat_fee = jQuery('.js-delivery-method input:checked').val()!='pickup'?jQuery('.js-shipping-flat-fee').val():0;
        shipping_final_price = parseFloat(+shipping_price + +shipping_flat_fee - +discount).toFixed(2);
    }*/
        

     if (lang == 'fr') {
            var shipping_free = 'Kobrex offre la livraison gratuite.';
            var rebate = 'Rabais';
            discount_dollar_sign =  discount + ' $';
            shipping_price_dollar_sign = parseFloat(+shipping_price + +shipping_flat_fee).toFixed(2) + ' $'; 
        } else {
            var shipping_free = 'Kobrex ships this order free of charge.';
            var rebate = 'Discount';
            discount_dollar_sign = '$ ' + discount;
            shipping_price_dollar_sign = '$ ' + parseFloat(+shipping_price + +shipping_flat_fee).toFixed(2); 
        }
    
    if (shipping_final_price > 0) {
        jQuery('.js-input-shipping-price').val(shipping_final_price);
        jQuery('.js-shipping-final-price').html(shipping_final_price.toFixed(2));
        jQuery('.js-discount').html('<span class="js-shipping-price"></span> - ( <span class="js-shipping-rebate">' + rebate + ' ' + discount_dollar_sign +'</span> )');
    } else {
        jQuery('.js-input-shipping-price').val(0);
        jQuery('.js-shipping-final-price').html((0).toFixed(2));
        jQuery('.js-discount').html(shipping_free);
    }
    jQuery('.js-shipping-price').html(shipping_price_dollar_sign);
    
    jQuery('.js-item-subtotal').each(function() {
        items_subtotal += parseFloat(jQuery(this).text());
    });

     jQuery('.js-item-deposit').each(function() {
        items_subtotal += parseFloat(jQuery(this).text()); 
    });

       jQuery('.js-item-tax').each(function() {
        items_subtotal += parseFloat(jQuery(this).text()); 
    });

    var subtotal =  isNaN(shipping_price) ? parseFloat(items_subtotal).toFixed(2) :  parseFloat(+shipping_final_price + +items_subtotal).toFixed(2);
    jQuery('.js-subtotal').attr('data-js-subtotal', subtotal);  
    jQuery('.js-subtotal').data('js-subtotal', subtotal);
    subtotal = subtotal;
    jQuery('.js-subtotal-amount').html(subtotal); 
    updateCartTotals();
}

function updateCartTotals()
{
    /*var billing_address = jQuery('.js-billing-address-hidden').val();
    var province = billing_address.split('||')[3];*/
    var subtotal = jQuery('[data-js-subtotal]').data('js-subtotal');
    province = jQuery('.js-shipping-state-default').val()
    
    $.ajax({
        url:'/ajax/getTaxeByProvince',
        dataType: 'JSON',
        type: 'POST',
        data: $('form[name="cart_checkout"]').serialize(),
        success: function(data){
            
            var tps = subtotal * data.tps;
            var tvp = (parseFloat(subtotal) ) * parseFloat(data.tvp);
            var total = parseFloat(subtotal) + parseFloat(tps) + parseFloat(tvp);
            var lang = jQuery('html').attr('lang');
            tps =  tps.toFixed(2);
            tvp =  tvp.toFixed(2);
            total = total.toFixed(2);
            jQuery('.js-input-tvp').val(tvp);
            jQuery('.js-input-tps').val(tps);
           
            jQuery('.js-input-subtotal-amount').val(subtotal);
           if(lang == 'fr'){
                jQuery('.js-tps').html(tps+'&nbsp;$');
                jQuery('.js-tvp').html(tvp+'&nbsp;$');
                jQuery('.js-total').html(total+'&nbsp;$');
           }else{
                jQuery('.js-tps').html('$&nbsp;' + tps);
                jQuery('.js-tvp').html('$&nbsp;' + tvp);
                jQuery('.js-total').html('$&nbsp;' + total);
           }
            jQuery('.js-loader').addClass('hide');
        }
    });
}