function isNullOrWhiteSpace(string) {
  return string == null || string.trim() == "";
}
function wrapStringInBoldTab(string) {
  return "<b>" + string + "</b>";
}
function makeStringNullIfWhiteSpace(string) {
  if (isNullOrWhiteSpace(string)) return null;
  else return string;
}
