
function repeaterCollectionType(element, option){
    var button = $(element).find('[data-action="add"]');
    var container = $(element).find('[data-repeater]');
    var prototype = $(element).find('[data-prototype]');

    button.on('click',add_new);
    $(element).on('click','[data-action="remove"]', remove)

    $regexs = {};
    var p = $(prototype.val());
    p.find('[name]').each(function(){
        $regexs[$(this).attr('name')] = new RegExp($(this).attr('name').replace(/\[/g,'\\[').replace(/\]/g,'\\]').replace(/__name__/g,'(.*?)'));
    });

    function add_new(e){
        e.preventDefault();
        var counter = container.children().length;
        var html_prototype = $(prototype.val().replace(/__name__/g, counter));
        container.append(html_prototype);
        if(option != undefined && option.add != undefined)
            option.add.bind(html_prototype)();
    }

    function remove(e){
        e.preventDefault();
        $(this).closest('[data-container]').remove();
        recalibrate();
    }

    function recalibrate(){
        container.children().each(function(index){
            $(this).find('[name]').each(function(){
                var input = $(this);
                for (const key in $regexs) {
                    if ($regexs[key].test(input.attr('name'))) 
                        input.attr('name',key.replace(/__name__/g, index));
                }
            })
        });
    }
}

jQuery("document").ready(function () {

    $('.js-repeater-type').each(function(){
        new repeaterCollectionType(this,{
            add: function(){
                var country = $(this).find("select[name*='country']");
                var province = $(this).find("select[name*='province']").closest(".form-group");
                var state = $(this).find("select[name*='state']").closest(".form-group");
        
                function toggleStateList(){
                    province.addClass('hide');
                    state.addClass('hide');
                    if(country.val() == 'CA')
                        province.removeClass('hide');
                    else if(country.val() == 'US')
                        state.removeClass('hide');
                }
                country.change(toggleStateList);
                toggleStateList();
            }
        });
    })
})