jQuery("document").ready(function () {
  //setup
  hideButtons();
  disableCategoriesLoading();

  /*event listeners */
  $("#add-pricing-structure-btn").click(addPricingStructureHandler);
  $(".pricing-structures-list").on(
    "click",
    ".delete-pricing-structure-btn",
    deletePricingStructureHandler
  );
  $(".pricing-structures-list").on(
    "click",
    ".edit-pricing-structure-btn",
    editPricingStructureHandler
  );
  $(".pricing-structures-list").on(
    "click",
    ".structure-association-btn",
    pricingStructureAssociationBtnClickedHandler
  );
  $(".pricing-structures-list").on(
    "click",
    ".pricing-structure-name-container",
    pricingStructureSelectionChangedHandler
  );
  $(".pricing-management-body-container .brand-dropdown").change(
    brandDropdownSelectionChangedHandler
  );
  $(".global-pricing-container").on(
    "click",
    ".btn-show-edit-matrixes-modal, .btn-show-non-edit-matrixes-modal",
    showDiscountMatrixModalForManufacturerHandler
  );
  $("#pricing-management-categories-container").on(
    "click",
    ".part-type .btn-show-edit-matrixes-modal, .part-type .btn-show-non-edit-matrixes-modal",
    showDiscountMatrixModalForPartTypeHandler
  );
  $("#pricing-management-categories-container").on(
    "click",
    ".subcategory .btn-show-edit-matrixes-modal, .subcategory .btn-show-non-edit-matrixes-modal",
    showDiscountMatrixModalForSubCategoryHandler
  );
  $("#pricing-management-categories-container").on(
    "click",
    ".category .btn-show-edit-matrixes-modal, .category .btn-show-non-edit-matrixes-modal",
    showDiscountMatrixModalForCategoryHandler
  );
  $(".pricing-management-edit-btn").on("click", enableEditing);
  $(".pricing-management-cancel-btn").on("click", disableEditing);
  $(".pricing-management-clear-btn").on("click", clearbtnClickedHandler);
  $(".pricing-management-save-btn").on("click", saveClickedHandler);
});
//STATE
var editing = false;
var connectionsWithPriceTypes;

function setConnectionsWithPriceTypes(value) {
  connectionsWithPriceTypes = value;
}
function getConnectionsWithPriceTypes() {
  return connectionsWithPriceTypes;
}

/*event Handlers*/
function pricingStructureAssociationBtnClickedHandler() {
  showPricingStructureAssociationModal($(this).data("pricing-structure-name"));
}
function clearbtnClickedHandler() {
  var matrixes = getMatrixes();

  matrixes.forEach(function (cat) {
    cat.matrixesBySubCategory.forEach(function (subcat) {
      subcat.matrixesByPartType.forEach(function (partType) {
        partType.orderedMatrixes = [];
      });
    });
  });
  refreshMatrixesThumbnail(matrixes);
  storeItemInSession("editing-matrixes", matrixes);
}
function disableEditing() {
  editing = false;
  hideButtons();
  $(".global-pricing-container .non-edit-btn").show();
  $(".global-pricing-container .matrix-thumbnail-container").show();
  refreshMatrixesThumbnail(getMatrixesFromSession());
  $(".pricing-management-edit-btn").show();
  $(".pricing-management-export-btn").show();
  $(".btn-show-non-edit-matrixes-modal").show();
}
function hideButtons() {
  $(".global-pricing-container .non-edit-btn").hide();
  $(".global-pricing-container .matrix-thumbnail-container").hide();

  $(".global-pricing-container .edit-btn").hide();
  $(".pricing-management-buttons-container button").hide();
  $(".btn-show-edit-matrixes-modal").hide();
  $(".btn-show-non-edit-matrixes-modal").hide();
}
function enableEditing() {
  editing = true;
  $(".global-pricing-container .non-edit-btn").hide();
  $(".global-pricing-container .matrix-thumbnail-container").show();

  $(".global-pricing-container .edit-btn").show();
  $(".pricing-management-buttons-container button").show();
  $(".pricing-management-edit-btn").hide();
  $(".pricing-management-export-btn").hide();
  $(".btn-show-edit-matrixes-modal").show();
  $(".btn-show-non-edit-matrixes-modal").hide();
  storeEditingMatrixesInSession();
}

function saveClickedHandler() {
  var editedMatrixes = getMatrixes();
  storeItemInSession("matrixes", editedMatrixes);

  var matrixesParam = getFormattedMatrixesForInsert(editedMatrixes);
  insertDiscountMatrixesAjaxCall(matrixesParam);
  disableEditing();
}

function deletePricingStructureHandler() {
  var btn = $(this);
  var structureName = btn.data("pricing-structure-name");

  new popupConfirmation(
    __("pricing-management.pricing-structure-delete-confirm-msg") +
      " <b>" +
      structureName +
      "</b>",
    function (e) {
      var item = getPricingStructureFromBtn(btn);
      if (item.hasClass("pricing-structures-list-item-selected")) {
        $("#pricing-management-categories-container").html("");
        hideButtons();
        clearMatrixesThumbnails();
        resetBrandDropDown();
      }
      item.remove();
      $.post("/ajax/deletePricingStructureByName", {
        pricingStructureName: structureName,
      });
    },
    "Delete"
  );
}
function editPricingStructureHandler() {
  var structure = getPricingStructureFromBtn($(this));
  var oldName = structure.data("pricing-structure-name");
  var defaultmatrixes = structure.data("default-matrixes");
  var form = getPricingStructureEditionForm(
    __("pricing-management.rename-structure-modal.title") + " " + wrapStringInBoldTab(oldName),
    structure.data("pricing-structure-isdefault"),
    defaultmatrixes == null ? [] : defaultmatrixes,
    oldName
  );
  showPricingStructureEditionModal(form, oldName, function (oldName, name, isDefault, defaultMatrixes) {
    structure.data("default-matrixes", defaultMatrixes);
    renamePricingStructureDOM(structure, name, isDefault);
    updatePricingStructureAJAX(oldName, formatPricingStructureInfosForAJAX(name, isDefault, defaultMatrixes));
  })
}

function addPricingStructureHandler() {
  var form = getPricingStructureEditionForm(
    __("pricing-management.pricing-structure-add-prompt-msg"),
    false,
    [],
    ""
  );
  showPricingStructureEditionModal(form, '', function (oldName, name, isDefault, defaultMatrixes) {
    createPricingStructureAJAX(formatPricingStructureInfosForAJAX(name, isDefault, defaultMatrixes));
  })
}

function pricingStructureSelectionChangedHandler() {
  $(".pricing-structures-list-item-selected").removeClass(
    "pricing-structures-list-item-selected"
  );
  $(this).closest(".pricing-structures-list-item").addClass("pricing-structures-list-item-selected");
  var manufacturerName = getSelectedManufacturerName();
  if (manufacturerName) {
    loadCategories(getSelectedPricingStructureName(), manufacturerName);
  }
}

function brandDropdownSelectionChangedHandler() {
  if (isNullOrWhiteSpace($(this).val())) return;
  var curr = $(this).find(":selected");
  var selectedPricingStructure = getSelectedPricingStructureName();

  if (selectedPricingStructure == undefined) {
    resetBrandDropDown();

    new popupConfirmation(
      __(
        "pricing-management.manufacturer-dropdown-change-no-structure-error-msg"
      ),
      false,
      "Ok",
      false
    );
  } else {
    setBrandLogo(curr.data("logo"));
    $(".global-pricing-manufacturer-name").html(curr.val());
    loadCategories(selectedPricingStructure, curr.val());
  }
}
function getMatrixesPath(btn) {
  return {
    PartType: btn.data("part-type"),
    Subcategory: btn.data("subcategory"),
    Category: btn.data("category"),
    Manufacturer: getSelectedManufacturerName(),
  };
}
function showDiscountMatrixModalForPartTypeHandler() {
  var path = getMatrixesPath($(this));
  var data = getMatrixByPartType(path);
  showDiscountMatrixModal(
    path.PartType,
    data.orderedMatrixes,
    data.connectionsAvailable,
    path,
    editing
  );
}

function showDiscountMatrixModalForSubCategoryHandler() {
  var path = getMatrixesPath($(this));
  var matrixesByPartType = getMatrixesByPartTypeFromCatSubcat(path);
  var data = getSubcategoryMatrixesAndConnections(matrixesByPartType);

  showDiscountMatrixModal(
    path.Subcategory,
    data.Matrixes,
    data.Connections,
    path,
    editing
  );
}

function showDiscountMatrixModalForCategoryHandler() {
  var path = getMatrixesPath($(this));
  var matrixesBysubcat = getMatrixesBySubcatFromCat(path);
  var data = getCategoryMatrixesAndConnections(matrixesBysubcat);

  showDiscountMatrixModal(
    path.Category,
    data.Matrixes,
    data.Connections,
    path,
    editing
  );
}
function showDiscountMatrixModalForManufacturerHandler() {
  var path = getMatrixesPath($(this));
  var data = getGlobalMatrixesAndConnections(getMatrixes());
  showDiscountMatrixModal(
    path.Manufacturer,
    data.Matrixes,
    data.Connections,
    path,
    editing
  );
}

/*DOM SET*/
function showPricingStructureEditionModal(form, oldName, successCallback) {
  var matrixesContainer = form.find('.discount-matrix-modal-row-container');
  var popup = new popupForm(form[0], "Save", {
    success: function (e) {
      var name = form.find("input").val();
      if (validatePricingStructureInfos(oldName, name, matrixesContainer)) {
        var isDefault = form.find("#default-cb").is(":checked") ? 1 : 0;
        var defaultMatrixes = getMatrixesFromMatrixesContainer(matrixesContainer)
        successCallback(oldName, name, isDefault, defaultMatrixes)
        popup.close();
        popup.die();
      }
    },
  });
  //make price type selectors same width
  makeItemsSameWidth(matrixesContainer.find(".matrix-price-type-selector"));
}
function showPricingStructureAlreadyExistsMSG() {
  showAlertPopup(
    __("pricing-management.pricing-structure-add-already-exists-error-msg")
  );
}
function showPricingStructureNameEmptyMSG() {
  showAlertPopup(
    __("pricing-management.pricing-structure-edition.name-empty-error-msg")
  );
}
function renamePricingStructureDOM(JQStructure, newName,isDefault) {
  $('.pricing-structures-list-item').data("pricing-structure-isdefault",0);
  JQStructure.data("pricing-structure-name", newName);
  JQStructure.data("pricing-structure-isdefault", isDefault);
  JQStructure.find(".pricing-structures-list-item-name").html(newName);
  JQStructure.find("[data-pricing-structure-name]").data(
    "pricing-structure-name",
    newName
  );
  $(".default-structure").removeClass("default-structure");
  if(isDefault == 1)
  {
    JQStructure.find("label").parent().addClass("default-structure");
    setDefaultPricingDom(JQStructure);
  }
}
function refreshMatrixesThumbnail(matrixes) {
  clearMatrixesThumbnails();
  var globalMatrixes = getGlobalMatrixesAndConnections(matrixes).Matrixes;

  if (globalMatrixes.length) {
    setMatrixesThumbnailForContainer(
      getGlobalMatrixThumbnailContainer(),
      globalMatrixes
    );
  } else refreshCategoriesMatrixesThumbnail(matrixes);
}
function refreshCategoriesMatrixesThumbnail(matrixes) {
  matrixes.forEach(function (category) {
    var path = { category: category.categoryName };
    var categoryMatrixes = getCategoryMatrixesAndConnections(
      category.matrixesBySubCategory
    ).Matrixes;
    if (categoryMatrixes.length) {
      setMatrixesThumbnailForContainer(
        getCategoryMatrixThumbnailContainer(path),
        categoryMatrixes
      );
    } else refreshSubcategoryMatrixesThumbnail(category.matrixesBySubCategory, path);
  });
}
function refreshSubcategoryMatrixesThumbnail(matrixes, path) {
  matrixes.forEach(function (subcat) {
    path.subcategory = subcat.subCategoryName;
    var subcategoryMatrixes = getSubcategoryMatrixesAndConnections(
      subcat.matrixesByPartType
    ).Matrixes;

    if (subcategoryMatrixes.length) {
      setMatrixesThumbnailForContainer(
        getSubcatMatrixThumbnailContainer(path),
        subcategoryMatrixes
      );
    } else refreshPartTypeMatrixesThumbnail(subcat.matrixesByPartType, path);
  });
}
function refreshPartTypeMatrixesThumbnail(matrixes, path) {
  matrixes.forEach(function (partType) {
    path.partType = partType.partTypeName;
    setMatrixesThumbnailForContainer(
      getPartTypeMatrixThumbnailContainer(path),
      partType.orderedMatrixes
    );
  });
}
function setMatrixesThumbnailForContainer(container, matrixes) {
  container.html(getNewPopulatedMatrixesThumbnail(matrixes));
}
function clearMatrixesThumbnails() {
  $(".matrix-thumbnail-container").empty();
}
/*DOM GET*/
function getMatrixThumbnailTemplate() {
  return $(".template-container .matrix-thumbnail");
}
function getPartTypeMatrixThumbnailContainer(path) {
  return $(".part" + getPartTypeFilter(path) + " .matrix-thumbnail-container");
}
function getSubcatMatrixThumbnailContainer(path) {
  return $(
    ".subcategory" + getSubcategoryFilter(path) + " .matrix-thumbnail-container"
  );
}
function getCategoryMatrixThumbnailContainer(path) {
  return $(
    ".category" + getCategoryFilter(path) + " .matrix-thumbnail-container"
  );
}
function getGlobalMatrixThumbnailContainer() {
  return $(".global-pricing-container .matrix-thumbnail-container");
}
//FilterBuilder
function getCategoryFilter(path) {
  return "[data-category='" + path.category + "']";
}
function getSubcategoryFilter(path) {
  return (
    getCategoryFilter(path) + "[data-subcategory='" + path.subcategory + "']"
  );
}
function getPartTypeFilter(path) {
  return (
    getSubcategoryFilter(path) + "[data-part-type='" + path.partType + "']"
  );
}
/*DAL*/
function updatePricingStructureAJAX(oldPricingStructureName, newPricingStructureInfos) {
  $.post(
    "/ajax/updatePricingStructure",
    {
      OldPricingStructureName: oldPricingStructureName,
      NewPricingStructureInfos: newPricingStructureInfos
    },
    function (result) {
      console.log('result :>> ', result);
    }
  );
}

function createPricingStructureAJAX(pricingStructureInfos) {
  $.post(
    "/ajax/createPricingStructure",
    {
      PricingStructureInfos: pricingStructureInfos
    },
    function (result) {
      var node = $(result);
      $(".pricing-structures-list").append(node);
      console.log('node :>> ', node);
      console.log('pricingStructureInfos.isDefaultStructureForMembership :>> ', pricingStructureInfos.isDefaultStructureForMembership);
      if (pricingStructureInfos.isDefaultStructureForMembership) {
        setDefaultPricingDom(node);
      }
    }
  );
}
function setDefaultPricingDom(JQStructure) {
    $('.pricing-structures-list-item').data("pricing-structure-isdefault",0);
    JQStructure.data("pricing-structure-isdefault", 1);
  
    $(".default-structure").removeClass("default-structure");
    
    JQStructure.find("label").parent().addClass("default-structure");
  
    $('.js-default').addClass('hide');
  
    $(".default-structure > .js-default").removeClass("hide");
}

function loadCategories(structureName, manufacturerName) {
  if (
    !isNullOrWhiteSpace(structureName) &&
    !isNullOrWhiteSpace(manufacturerName)
  ) {
    hideButtons();
    clearMatrixesThumbnails();
    $("#pricing-management-categories-container").html("");
    enableCategoriesLoading();
    refreshExportLinks(manufacturerName);
    $.post(
      "/ajax/getCategoriesSubCategoriesPartType",
      {
        manufacturer: manufacturerName,
        pricingStructure: structureName,
      },
      function (result) {
        $("#pricing-management-categories-container").html(result);
        disableCategoriesLoading();
        refreshMatrixesThumbnail(getMatrixesFromSession());
        disableEditing();
      }
    );
  }
}
function insertDiscountMatrixesAjaxCall(matrixes) {
  $.post("/ajax/insertDiscountMatrixes", {
    pricingStructureName: getSelectedPricingStructureName(),
    manufacturerName: getSelectedManufacturerName(),
    discountMatrixs: matrixes,
  });
}

function getMatrixesFromSession() {
  return getItemFromSession("matrixes");
}
function storeEditingMatrixesInSession() {
  storeItemInSession("editing-matrixes", getMatrixesFromSession());
}
function getMatrixes() {
  if (editing) return getEditingMatrixes();
  else return getMatrixesFromSession();
}
function getEditingMatrixes() {
  return getItemFromSession("editing-matrixes");
}

/*logic*/
function formatPricingStructureInfosForAJAX(name, isDefault, defaultMatrixes) {
  return {
    pricingStructureName: name,
    isDefaultStructureForMembership: isDefault,
    defaultMatrixes: defaultMatrixes
  }
}
function validatePricingStructureInfos(oldName, newName, defaultMatrixesContainer) {
  if (isNullOrWhiteSpace(newName)) {
    showPricingStructureNameEmptyMSG()
    return false;
  }
  if (!ratesAreValid(defaultMatrixesContainer)) {
    showInvalidRateModal();
    return false;
  }
  if (oldName !== newName && pricingStructureAlreadyExists(newName)) {
    showPricingStructureAlreadyExistsMSG();
    return false;
  }
  return true;
}

function getNewPopulatedMatrixesThumbnail(matrixes) {
  return matrixes
    .map(function (matrix) {
      var tpl = getMatrixThumbnailTemplate().clone();
      tpl.find(".connection-label").html(matrix.connectionLabel);
      tpl.find(".calculation").html(getMatrixCalcultionAsText(matrix));
      return tpl;
    })
    .filter(function (x, i) {
      return i < 4;
    });
}
function getMatrixCalcultionAsText(matrix) {
  return [
    matrix.priceType,
    matrix.operatorSymbol,
    matrix.rate,
    matrix.rateTypeSymbol,
  ].join(" ");
}
function getGlobalMatrixesAndConnections(matrixes) {
  var manufacturerConnections = [];
  var manufacturermatrixes = [];
  var categoriesInfos = [];
  matrixes.forEach(function (x) {
    var categoryData = getCategoryMatrixesAndConnections(
      x.matrixesBySubCategory
    );

    manufacturerConnections = concatArraysWithNoDuplicate(
      manufacturerConnections,
      categoryData.Connections,
      connectionsAreEqual
    );
    manufacturermatrixes = concatArraysWithNoDuplicate(
      manufacturermatrixes,
      categoryData.Matrixes,
      matrixesAreEqual
    );

    categoriesInfos.push({
      matrixes: categoryData.Matrixes,
      connectionsAvailable: categoryData.Connections,
    });
  });
  var canPassMatrixes = true;
  categoriesInfos.forEach(function (x) {
    canPassMatrixes =
      canPassMatrixes &&
      levelMatrixesAreValid(
        x.matrixes,
        x.connectionsAvailable,
        manufacturermatrixes
      );
  });
  if (!canPassMatrixes) {
    manufacturermatrixes = [];
  }
  return {
    Matrixes: manufacturermatrixes,
    Connections: manufacturerConnections,
  };
}
function getCategoryMatrixesAndConnections(matrixesBysubcat) {
  var categoryConnections = [];
  var categoryMatrixes = [];
  var canPassMatrixes = true;
  var subCategoyInfos = [];
  matrixesBysubcat.forEach(function (x) {
    var currSubcatConnections = mergeConnectionsForSubcategory(
      x.matrixesByPartType
    );

    categoryConnections = concatArraysWithNoDuplicate(
      categoryConnections,
      currSubcatConnections,
      connectionsAreEqual
    );

    var currSubcatMatrixes = mergeMatrixesForSubcategory(x.matrixesByPartType);

    categoryMatrixes = concatArraysWithNoDuplicate(
      categoryMatrixes,
      currSubcatMatrixes,
      matrixesAreEqual
    );
    subCategoyInfos.push({
      matrixes: currSubcatMatrixes,
      connectionsAvailable: currSubcatConnections,
    });
  });

  subCategoyInfos.forEach(function (x) {
    canPassMatrixes =
      canPassMatrixes &&
      levelMatrixesAreValid(
        x.matrixes,
        x.connectionsAvailable,
        categoryMatrixes
      );
  });
  if (!canPassMatrixes) {
    categoryMatrixes = [];
  }
  return {
    Connections: categoryConnections,
    Matrixes: categoryMatrixes,
  };
}
function getSubcategoryMatrixesAndConnections(matrixesByPartType) {
  var mergedConnections = mergeConnectionsForSubcategory(matrixesByPartType);
  var mergedMatrixes = mergeMatrixesForSubcategory(
    matrixesByPartType,
    mergedConnections
  );
  return {
    Matrixes: mergedMatrixes,
    Connections: mergedConnections,
  };
}

function getPricingStructureEditionForm(title, isDefault, defaultMatrixes, pricingStructureName) {
  var checked = "";
  if(isDefault){
    checked = "checked disabled";
  }
  var form = $(
    "<form class='container-form'>" +
      title +
    "<input class='full' type='text' value='" + pricingStructureName + "' placeholder=\"" + __('pricing-management.structure-name-placeholder') + "\" />" +
      "<div class='flex flex-center'>" +
    "<input class='input-hidden mr2 mt1' type='checkbox' name='default' id='default-cb' value='1' " + checked + ">" +
    "<label class='input-checkbox--fake' id='default-label' for='default-cb'></label>" +
    "<label>Make this my default structure</label>" +
      "</div>" +
    "<div class='collapser mt1' style='display:flex; align-self:start;'><i class='fa fa-angle-down mr2'></i>" + __('pricing-management.default-matrixes-title') + "</div>" +
    "<div class='discount-matrix-modal-row-container collapser-content'></div>" +
    "</form>"
  );
  //setup default matrixes
  var availableConnections = getConnectionsWithPriceTypes();
  var matrixesContainer = form.find('.discount-matrix-modal-row-container');
  createAndAppendMatrixesToDOM(defaultMatrixes, availableConnections, matrixesContainer);
  enableMatrixEditing(matrixesContainer);
  attachMatrixesEventListeners(matrixesContainer);
  return form;
}

function getPricingStructureFromBtn(btn) {
  return btn.closest('.pricing-structures-list-item')
}
function resetBrandDropDown() {
  clearDropdownSelection(
    $(".pricing-management-body-container .brand-dropdown")
  );
  setBrandLogo("");
}
function disableCategoriesLoading() {
  $(".pricing-management-categoris-loading").hide();
}
function enableCategoriesLoading() {
  $(".pricing-management-categoris-loading").show();
}

function connectionsAreEqual(item1, item2) {
  return item1.connectionLabel === item2.connectionLabel;
}
function getFormattedMatrixesForInsert(matrixes) {
  var matrixesParam = [];
  matrixes.forEach(function (cat) {
    cat.matrixesBySubCategory.forEach(function (subcat) {
      subcat.matrixesByPartType.forEach(function (partType) {
        var toConcat = partType.orderedMatrixes.map(function (x) {
          x.partType = partType.partTypeName;
          return x;
        });
        matrixesParam = matrixesParam.concat(toConcat);
      });
    });
  });
  return matrixesParam;
}

function mergeMatrixesForSubcategory(matrixesByPartType) {
  var mergedMatrixes = [];
  matrixesByPartType.forEach(function (x) {
    mergedMatrixes = concatArraysWithNoDuplicate(
      mergedMatrixes,
      x.orderedMatrixes,
      matrixesAreEqual
    );
  });

  var canPassFilteredMatrix = true;
  matrixesByPartType.forEach(function (x) {
    canPassFilteredMatrix =
      canPassFilteredMatrix &&
      levelMatrixesAreValid(
        x.orderedMatrixes,
        x.connectionsAvailable,
        mergedMatrixes
      );
  });
  if (!canPassFilteredMatrix) {
    mergedMatrixes = [];
  }
  return mergedMatrixes;
}

function levelMatrixesAreValid(
  matrixesToCheck,
  connectionsAvailable,
  mergedMatrixes
) {
  var canPassFilteredMatrix = true;
  mergedMatrixes.forEach(function (matrix) {
    canPassFilteredMatrix =
      canPassFilteredMatrix &&
      !matrixesNotContainsMatrixAndHasConnection(
        matrixesToCheck,
        connectionsAvailable,
        matrix
      );
  });

  return (
    canPassFilteredMatrix &&
    !mergedMatrixesContainsDuplicate(mergedMatrixes, connectionsAvailable)
  );
}

function mergeConnectionsForSubcategory(matrixesByPartType) {
  var filteredConnections = [];
  matrixesByPartType.forEach(function (x) {
    filteredConnections = concatArraysWithNoDuplicate(
      filteredConnections,
      x.connectionsAvailable,
      connectionsAreEqual
    );
  });
  return filteredConnections;
}
function matrixesNotContainsMatrixAndHasConnection(
  matrixes,
  connectionsAvailable,
  matrix
) {
  return (
    !matrixes.some(function (x) {
      return matrixesAreEqual(x, matrix);
    }) &&
    connectionsAvailable.some(function (x) {
      return x.connectionLabel === matrix.connectionLabel;
    })
  );
}
function matrixesAreEqual(matrix1, matrix2) {
  matrix1.partType = "";
  matrix2.partType = "";
  return JSON.stringify(matrix1) === JSON.stringify(matrix2);
}
function getMatrixesBySubcatFromCat(path) {
  return getMatrixes().find(function (x) {
    return x.categoryName === path.Category;
  }).matrixesBySubCategory;
}
function getMatrixesByPartTypeFromCatSubcat(path) {
  return getMatrixesBySubcatFromCat(path).find(function (x) {
    return x.subCategoryName === path.Subcategory;
  }).matrixesByPartType;
}
function getMatrixByPartType(path) {
  return getMatrixesByPartTypeFromCatSubcat(path).find(function (x) {
    return x.partTypeName == path.PartType;
  });
}

function mergedMatrixesContainsDuplicate(mergedMatrixes, filteredConnections) {
  return filteredConnections.some(function (x) {
    return (
      mergedMatrixes.filter(function (matrix) {
        return matrix.connectionLabel == x.connectionLabel;
      }).length > 1
    );
  });
}

function getSelectedPricingStructureName() {
  return $(".pricing-structures-list-item-selected")
    .data('pricing-structure-name');
}

function getSelectedManufacturerName() {
  return $(".pricing-management-body-container .brand-dropdown")
    .find(":selected")
    .val();
}

function setBrandLogo(logoUrl) {
  $("#pricing-management-brand-logo").attr("src", logoUrl);
}

function pricingStructureAlreadyExists(structureName) {
  return $(".pricing-structures-list")
    .children()
    .toArray()
    .some(function (x) {
      return (
        $(x).data("pricing-structure-name").toString().toLowerCase() ==
        structureName.toLowerCase()
      );
    });
}

function refreshExportLinks(manufacturerName){
  $('.pricing-management-export-btn+.btn-dropdown-list').empty();

  jQuery.ajax({
    url: '/ajax/getExportLinks',
    data: {
      manufacturerName : manufacturerName
    },
    dataType: 'JSON',
    type: 'POST',
    success: function(data){
      for(k in data){
        $('.pricing-management-export-btn+.btn-dropdown-list').append('<a href="'+data[k].href+'" title="'+data[k].title+'">'+data[k].title+'</a>')
      }
    },
  });
}
