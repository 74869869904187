jQuery( document ).ready(function() {
    const histy = [];
    $('.js-linecode-editor').on('change', '.js-linecode-brand',function(){
        const value = this.value;
        $('.js-linecode-category').val(value).trigger('change');
    })

    $('.js-linecode-editor').on('change', '.js-linecode-category',function(){
        const value = this.value;

        $(this).closest('.js-category').find('.js-linecode-subcategory').each(function(){
            $(this).val(value).trigger('change');
        })
    });

    $('.js-linecode-editor').on('change', '.js-linecode-subcategory',function(){
        const value = this.value;
        jQuery(this).closest('.js-subcategory').find('input.js-linecode-part').each(function(){(ChangeInputValue.bind(this))(value) });
    });

    $('.js-linecode-editor').on('change', '.js-prefix-brand',function(){
        const value = this.value;
        $('.js-prefix-part').each(function(){(ChangeInputValue.bind(this))(value) });
    });

    $('.js-linecode-editor').on('change', '.js-suffix-brand',function(){
        const value = this.value;
        $('.js-suffix-part').each(function(){(ChangeInputValue.bind(this))(value) });
    });

    $('.js-linecode-editor').on('change', '.js-enable-brand',function(){
        const value = $(this).is(':checked');
        $('.js-enable-part').each(function(){(ChangeEnableStatus.bind(this))(value) });
    })

    $('.js-linecode-editor').on('change', '.js-enable-category',function(){
        const value = $(this).is(':checked');
        jQuery(this).closest('.js-category').find('.js-enable-part').each(function(){(ChangeEnableStatus.bind(this))(value) });
    });

    $('.js-linecode-editor').on('change', '.js-enable-subcategory',function(){
        const value = $(this).is(':checked');
        jQuery(this).closest('.js-subcategory').find('.js-enable-part').each(function(){(ChangeEnableStatus.bind(this))(value) });
    });

    $('.js-linecode-editor').on('change', '.js-enable-part',function(){
        (ChangeBrandStatus.bind($('.js-enable-brand').get(0)))($('.js-enable-part'));
        (ChangeBrandStatus.bind($(this).closest('.js-category').find('.js-enable-category').get(0)))($(this).closest('.js-category').find('.js-enable-part'));
        (ChangeBrandStatus.bind($(this).closest('.js-subcategory').find('.js-enable-subcategory').get(0)))($(this).closest('.js-subcategory').find('.js-enable-part'));
    });

    $('.js-linecode-editor').on('change', '.js-enable-part,.js-prefix-part,.js-suffix-part,.js-linecode-part',function(){
        addChangeElementToHistory($(this).closest('.js-part').get(0))
    });

    $('.js-linecode-editor').on('input', '.js-linecode-category',function(){
        $('.js-linecode-brand').val('');
    });

    $('.js-linecode-editor').on('input', '.js-linecode-subcategory',function(){
        $('.js-linecode-brand').val('');
        $(this).closest('.js-category').find('.js-linecode-category').val('');
    });

    $('.js-linecode-editor').on('input', '.js-linecode-part',function(){
        $('.js-linecode-brand').val('');
        $(this).closest('.js-category').find('.js-linecode-category').val('');
        $(this).closest('.js-subcategory').find('.js-linecode-subcategory').val('')
    });

    function ChangeInputValue(value){
        const current = $(this).val();
        if(current != value)
            $(this).val(value).trigger('change');
    }

    function ChangeEnableStatus(value){
        const current = $(this).is(':checked');
        if(current != value)
            $(this).prop('checked',value).trigger('change');
    }
    
    function ChangeBrandStatus(partCheckboxes){
        const total = partCheckboxes.length;
        const checked = partCheckboxes.filter(':checked').length;
        if (checked === total) {
            $(this).prop({
              checked: true,
              indeterminate: false
            });
          } else if (checked === 0) {
            $(this).prop({
              checked: false,
              indeterminate: false
            });
          } else {
            $(this).prop({
              checked: false,
              indeterminate: true
            });
        }
    }

    if($('.js-linecode-editor').length){
        (ChangeBrandStatus.bind($('.js-enable-brand').get(0)))($('.js-enable-part'));
        $('.js-category').each(function(){
            (ChangeBrandStatus.bind($(this).find('.js-enable-category').get(0)))($(this).find('.js-enable-part'));
        });
        $('.js-subcategory').each(function(){
            (ChangeBrandStatus.bind($(this).find('.js-enable-subcategory').get(0)))($(this).find('.js-enable-part'));
        });
    }

    jQuery('.js-settings--linecode, .js-settings--jobs').on('click', '.js-toggle-parts-linecode', function() {
        subcat = jQuery(this).find('span').text();
        if( jQuery(this).find('.fa').hasClass('fa-plus-circle')) {
            jQuery(this).parent().parent().find('.fa-minus-circle').removeClass('fa-minus-circle').addClass('fa-plus-circle');
            jQuery(this).parent().parent().find('.js-part').addClass('hide');
            jQuery(this).find('.fa').toggleClass('fa-plus-circle fa-minus-circle');
            jQuery(this).parent().parent().find('.js-part[data-subcat="' + subcat + '"]').toggleClass('hide');
        } else {
            jQuery(this).find('.fa-minus-circle').removeClass('fa-minus-circle').addClass('fa-plus-circle');
            jQuery(this).parent().parent().find('.js-part[data-subcat="' + subcat + '"]').addClass('hide');
        }   
    });

    function addChangeElementToHistory(element){
        if(!histy.includes(element))
            histy.push(element);
    }

    function loadChangeHistory(count){
        return histy.splice(0, count);
    }


    function inputNameToObject(name, value){
        const keys = name.match(/([^[\]]+)/g); 
        return keys.reduceRight((value, key) => ({ [key]: value }), value);
    }

    function deepMerge(target, source) {
        for (const key in source) {
            if (source.hasOwnProperty(key)) {
                if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
                    target[key] = deepMerge(target[key] || {}, source[key]);
                } else {
                    target[key] = source[key];
                }
            }
        }
        return target;
    }

    function objectToStructuredObject(obj, keysMap = ["category", "subCategory", "parttype", "manufacturerName"], breadcrumb = [], result = {}) {
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'object' && value !== null) {
                objectToStructuredObject(value, keysMap, [...breadcrumb, key], result);
            } else {
                breadcrumb.forEach((crumb, index) => {
                    result[keysMap[index]] = crumb;
                });
                result[key] = value;
            }
        }
        return result;
    }

    function formatData(item){
        let mergedData = {};
        $(item).find('input[name], select[name], textarea[name]').each(function() {
            let data
            if(this.type == 'checkbox'){
                data = inputNameToObject(this.name, this.checked ? this.value : "");
            }
            else{
                data = inputNameToObject(this.name, this.value);
            }
            mergedData = deepMerge(mergedData, data);
        });
        return mergedData;
    }

    function processBatch(){
        let data = [];
        const toProcess = loadChangeHistory(50);
        for(var key in toProcess){
            data.push(objectToStructuredObject(formatData(toProcess[key]).userSettings));
        }
        processCatalogSettingsQuery(data).then(autosaveRunner);
    }

    function processCatalogSettingsQuery(settings){
        return new Promise(function(resolve) {
            var _resolve = function(){resolve();}
            jQuery.ajax({
                url: '/ajax/saveUserCatalogSettings',
                dataType: 'JSON',
                type: 'POST',
                data: {
                    userSettings:settings
                },
                success: _resolve,
                error: _resolve,
            });
        });
    }

    function beforeUnloadHandler(event) {
        event.preventDefault();
        event.returnValue = true;
    }

    function autosaveRunner(){
        window.removeEventListener("beforeunload", beforeUnloadHandler);
        if(histy.length){
            window.addEventListener("beforeunload", beforeUnloadHandler);
            processBatch();
        }
        else{
            setTimeout(autosaveRunner, 1000);
        }
    }
    autosaveRunner();
});