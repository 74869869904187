$(function () {
  //event listeners
  $(".field-type-more-info-modal .ok-btn").on("click", okClickedHandler);
  $(".field-type-more-info-modal .cancel-btn").on(
    "click",
    cancelClickedHandler
  );
});

//Handler
function cancelClickedHandler() {
  resetFieldTypeDropdown();
  hideFieldMoreInfoModal();
}
function okClickedHandler() {
  if (ValidateUserInput()) {
    setMoreInfoContainer();
    hideFieldMoreInfoModal();
  }
}

//State
var _fieldInfoState;
function setFieldInfo(fieldInfo) {
  _fieldInfoState = fieldInfo;
}
function getFieldInfo() {
  return _fieldInfoState;
}
function getBoldTabName() {
  return wrapStringInBoldTab(getFieldInfo().tab);
}

var _modal;
function setModal() {
  _modal = $(".field-type-more-info-modal");
  getModalInput().val(null);
}
function getModal() {
  return _modal;
}
function getModalInput() {
  return getModal().find(".info-input");
}
function getModalLabel() {
  return getModal().find("label");
}

//DOM SET
function showFieldMoreInfoModal(fieldInfo) {
  setModal();
  setFieldInfo(fieldInfo);
  populateModal();
  getModal().show();
}
function populateModal() {
  getModal().data("tab", getFieldInfo().tab);
  populateModalLabel();
  populateModalInputPlaceholder();
}
function populateModalLabel() {
  getModalLabel().html(
    __("price-list-import.more-info-modal.label." + getFieldInfo().name) +
      " " +
      getBoldTabName()
  );
}
function populateModalInputPlaceholder() {
  getModalInput().prop(
    "placeholder",
    __(
      "price-list-import.more-info-modal.input-placeholder." +
        getFieldInfo().name
    )
  );
}

function hideFieldMoreInfoModal() {
  $(".field-type-more-info-modal").hide();
}
function resetFieldTypeDropdown() {
  clearDropdownSelection(getFieldTypeDropdownByTabName(getFieldInfo().tab));
}
function setMoreInfoContainer() {
  getFieldTypeMoreInfoContainerByTabName(getFieldInfo().tab).html(
    getModalInput().val()
  );
}

//Logic
function ValidateUserInput() {
  return userInputNotNull() && userInputNotAlreadyExists();
}
function userInputNotNull() {
  if (isNullOrWhiteSpace(getModalInput().val())) {
    showAlertPopup(__("price-list-import.more-info-modal.error.input-empty"));
    return false;
  }
  return true;
}
function userInputNotAlreadyExists() {
  if (fieldTypeIsAlreadySelected()) {
    showAlertPopup(
      __("price-list-import.more-info-modal.error.input-already-exists")
    );
    return false;
  }
  return true;
}

function fieldTypeIsAlreadySelected() {
  var fieldTypeName = getFieldInfo().name;
  var moreInfo = getModalInput().val();

  return getAllMergedFieldInfoAndMoreInfoObjects().some(function (fieldInfo) {
    return fieldInfo.name == fieldTypeName && fieldInfo.moreInfo == moreInfo;
  });
}
