function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return "";
}
function setCookie(segment, persona, cookie){
     if (cookie && segment != '') {
        var personaName = persona + '|' + segment;
        Cookies.set('netcom-persona', personaName, { expires: 365 });
    }
}
var cookie = getCookie("netcom-persona");
   
if (cookie  == 'null|tools-and-accessories') {
    setCookie('light-vehicles', null, true);
    Cookies.set('_gali', light-vehicles, { expires: 365 });
}