var startY, distY, direction;


function detectTouchStart(e)  
{
 
    var touchobj = e.changedTouches[0];
        startY = touchobj.pageY;
}


function detectTouchEnd(e)  
{
  
    var touchobj = e.changedTouches[0];
    var distY = touchobj.pageY - startY;
    direction = (distY < 0)? 'down' : 'up';
    return direction;

}

function detectMouseWheelDirection( e )  
{
    var delta = null,
        direction = false
    ;
    if ( !e ) { // if the event is not provided, we get it from the window object
        e = window.event;
    }
    if ( e.wheelDelta ) { // will work in most cases
        delta = e.wheelDelta / 60;
    } else if ( e.detail ) { // fallback for Firefox
        delta = -e.detail / 2;
    }
    if ( delta !== null ) {
        direction = delta > 0 ? 'up' : 'down';
    }

    return direction;

}


function handleMouseWheelDirection( direction )
{
  

   current = jQuery('.fixed-slide.show').data('slide');
  
     if ( direction == 'down' ) {
       if (current < jQuery('.fixed-slide').length) {
          next = current + 1;
          navigateSlide('#anchor-' + next)
       }

    } else if ( direction == 'up' ) {
      if (current == 1) {
          previous = current;
       } else {
          previous = current - 1;
       }
       navigateSlide('#anchor-' + previous)
    }   
}


function higlightAnchor(href) {
    var anchor = href;
    jQuery('.js-container__anchors a').find('.circle').removeClass('active');
    jQuery('.js-container__anchors a[href="' + anchor + '"]').find('.circle').addClass('active'); 
}


function navigateSlide(href) {
    slide = href.replace('#anchor-','');
    jQuery('.fixed-slide, .swipe-slide').removeClass('show');
    swipe_content_offset = jQuery(".swipe-slide").height();
    jQuery(".fixed-slide:nth-of-type(" + slide + ")").addClass('show');
    jQuery(".swipe-slide:nth-of-type(" + slide + ")").addClass('show');
    //jQuery(".bar:nth-of-type(" + slide + ")").addClass('show');
    jQuery(".container--scroll-content").css('transform', 'translateY(-' + swipe_content_offset * (slide - 1) + 'px)');
    higlightAnchor(href); 
    if (!Tools.IsMobile())
      toggleCheck();
    
}

function toggleCheck() {
 if (jQuery('.swipe-slide:first-of-type').hasClass('show')) {
    jQuery('.js-check').fadeOut();
  }  else {
      jQuery('.js-check').fadeIn(); 
  } 
}

function scrollToAnchor(selector) {
  document.querySelector(selector).scrollIntoView({block   :"start", behavior: 'smooth' });
}

document.onmousewheel = function( e ) {
  if (timeout == 0 && jQuery('.js-form').css('display') == 'none') {
    setTimer();
    handleMouseWheelDirection( detectMouseWheelDirection( e ) );
  }
};

if ( window.addEventListener ) {
  if (timeout == 0 && jQuery('.js-form').css('display') == 'none') {
    document.addEventListener( 'DOMMouseScroll', function( e ) {
        handleMouseWheelDirection( detectMouseWheelDirection( e ) );
    });
  }
}

document.addEventListener('touchstart', function(e) {
  
  if (timeout == 0 && jQuery('.js-form').css('display') == 'none') {
      detectTouchStart(e);
  }
}); 

document.addEventListener('touchend', function(e) {
  
   if (timeout == 0 && jQuery('.js-form').css('display') == 'none') {
    setTimer();
    handleMouseWheelDirection( detectTouchEnd( e ) );
  } 
});

jQuery(document).ready(function(){

jQuery(document).click('.js--scroll',function(e){
  var href = jQuery(e.target).closest('.js--scroll').attr('href');
  if(href!=undefined && href.indexOf('#') !== -1){
    href = href.split('#');
    if(href[0]!="" && document.location.pathname != href[0]){
      return;
    }
    e.preventDefault();
    scrollToAnchor('#'+href[href.length-1]);
  }
});

$('.js-on-viewport').each(function(){
  var el = $(this);
  function  testView() {
    var imgY = el.offset().top;
    var winY = $(this).scrollTop();
    var winH = $(this).height();
    var parentH = el.innerHeight();


    // The next pixel to show on screen      
    var winBottom = winY + winH;

    // If block is shown on screen
    if (winBottom > imgY /*&& winY < imgY + parentH*/) el.addClass('on-viewport');
    else el.removeClass('on-viewport');
    
  }
  $(document).on({
    scroll: function () {
      testView();
    }, ready: function () {
      testView();
    }
  });
});



$('.js-parallax .background').each(function(){
  var img = $(this);
  var imgParent = $(this).parent();
  function parallaxImg () {
    var speed = img.data('speed');
    var imgY = imgParent.offset().top;
    var winY = $(this).scrollTop();
    var winH = $(this).height();
    var parentH = imgParent.innerHeight();

    // The next pixel to show on screen      
    var winBottom = winY + winH;

    // If block is shown on screen
    if (winBottom > imgY && winY < imgY + parentH) {
      // Number of pixels shown after block appear
      var imgBottom = ((winBottom - imgY) * speed);
      // Max number of pixels until block disappear
      var imgTop = winH + parentH;
      // Porcentage between start showing until disappearing
      var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
    }
    img.css({
      top: imgPercent + '%',
      transform: 'translate(-50%, -' + imgPercent + '%)'
    });
  }
  $(document).on({
    scroll: function () {
      parallaxImg();
    }, ready: function () {
      parallaxImg();
    }
  });
});

});


var timeout = 0;

function setTimer() {
  timeout = 1;
  setTimeout( function() {
    timeout = 0;
  }, 700);
}
