function TableEditor(table){
    if(!(this instanceof TableEditor))
        throw new Error("Uncaught TypeError: TableEditor is a construtor.");
    var origin = $(table).find("tr.clone").remove();
    origin.removeClass("clone");
    var container = $(table).find("tbody")

    this.add = function(data){
        if(data instanceof Array ){
            data.forEach(this.add);
        }
        else{
            var clone = origin.clone().appendTo(container);
            for(key in data){
                clone.find("[data-tab="+key+"]").html(data[key]);
            }
            return clone;
        }
    }

    this.clear = function(){
        container.html("");
    }
    return this;
}