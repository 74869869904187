$(function () {
  //setup
  setupPricingStructureDropdown();
});
//setup functions
function setupPricingStructureDropdown() {
  var dropdown = $("select[name='pricing-structure']");
  dropdown.select2({
    placeholder: __("pricing-structure-dropdown.place-holder"),
  });
  changeDropdownSelection(dropdown, getItemFromSession("selected-structure"));
}
//hanlders
function submitCustomerClicked() {
  var matches = [
    getPricingStructureClientMatchObject(
      $("[name='profile_address[business_customer_id]']").val(),
      $("[name='profile_address[price_structure]']").val()
    ),
  ];
  UpdateStructureForClientsAJAX(matches);
}

//LOGIC
function getPricingStructureClientMatchObject(clientID, pricingStructureName) {
  return {
    clientID: clientID,
    pricingStructure: {
      pricingStructureName: pricingStructureName,
    },
  };
}
//DAL
function UpdateStructureForClientsAJAX(matches) {
  $.post(
    "/ajax/updatePricingStructureForClients",
    {
      matches: matches,
    },
    function (resp) {
      console.log(resp);
    }
  );
}
