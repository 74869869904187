function popupConfirmation(text, href, action, cancel) {
  var callback = null;
  if (!(this instanceof popupConfirmation))
    throw new Error("Uncaught TypeError: popupConfirmation is a construtor");
  if (action === undefined || action === null || action === "")
    //exclude false
    action = __("Delete");
  if (cancel === undefined || cancel === null || cancel === "")
    //exclude false
    cancel = __("Cancel");
  if (typeof href != "string") {
    callback = href;
    href = "";
  }
  var element = document.createElement("div");
  element.className = "c--popup c--popup-choices js-popup";
  var content =
    '<div class="c--popup----bg"></div>' +
    '<div class="c--popup-box c--popup-error">' +
    '<div class="c--popup-content">' +
    text +
    "</div>" +
    '<div class="c--popup-footer">' +
    (cancel != false
      ? '<button class="js--close btn btn--inverse decline">' +
        cancel +
        "</button>"
      : "") +
    (action != false
      ? '<a class="btn confirm" href="' + href + '" >' + action + "</a>"
      : "") +
    "</div>" +
    "</div>";
  element.innerHTML = content;

  this.toggle = function () {
    if (element.classList.contains("js--is-open")) return this.close();
    else return this.open();
  };
  this.open = function () {
    element.classList.add("js--is-open");
    return this;
  };
  this.close = function () {
    element.classList.remove("js--is-open");
    return this;
  };
  this.die = function () {
    element.parentElement.removeChild(element);
  };

  document.querySelector("body").appendChild(element);
  $(element).on(
    "click",
    ".js--close",
    function (e) {
      e.preventDefault();
      this.close();
      this.die();
    }.bind(this)
  );

  if (callback != null) {
    $(element).on(
      "click",
      ".btn.confirm",
      function (e) {
        e.preventDefault();
        this.close();
        this.die();
        callback();
      }.bind(this)
    );
  }

  this.toggle();
  return this;
}

function popupForm(form, action, parameters) {
  if (!(this instanceof popupForm))
    throw new Error("Uncaught TypeError: popupForm is a construtor");
  if (action == undefined || action == null || action == "") action = "Delete";
  var element = document.createElement("div");
  element.className = "c--popup c--popup-choices js-popup";
  var content =
    '<div class="c--popup----bg"></div>' +
    '<div class="c--popup-box c--popup-error" style="overflow-y:auto">' +
    '<div class="c--popup-content">' +
    "</div>" +
    '<div class="c--popup-footer">' +
    '<a class="js--close btn btn--inverse decline" href="#" >Cancel</a>' +
    '<a class="js--confirm btn confirm">' +
    action +
    "</a>" +
    "</div>" +
    "</div>";
  element.innerHTML = content;

  this.toggle = function () {
    if (element.classList.contains("js--is-open")) return this.close();
    else return this.open();
  };
  this.open = function () {
    element.classList.add("js--is-open");
    return this;
  };
  this.close = function () {
    element.classList.remove("js--is-open");
    return this;
  };
  this.die = function () {
    element.parentElement.removeChild(element);
    return this;
  };

  this.updateForm = function(form){
    element.querySelector(".c--popup-content").textContent =""
    element.querySelector(".c--popup-content").appendChild(form);

    $(form).on(
      "submit",
      function (e) {
        if (parameters.success != undefined) parameters.success.bind(form)(e);
        if (e.defaultPrevented == undefined) {
          e.preventDefault();
          this.close();
          this.die();
        }
      }.bind(this)
    );
  }

  this.setAction = function(action, onSuccess){
    element.querySelector(".c--popup-footer .confirm").textContent = action;
    if(onSuccess != undefined){
      parameters.success = onSuccess;
    }
    }

  document.querySelector("body").appendChild(element);
  $(element).on(
    "click",
    ".js--close",
    function (e) {
      e.preventDefault();
      this.close();
      this.die();
    }.bind(this)
  );

  $(element).on(
    "click",
    ".js--confirm",
    function (e) {
      if (parameters.success != undefined)
        parameters.success.bind(this)(e, form);
      /*if(e.defaultPrevented == undefined){
            e.preventDefault();
            this.close();
            this.die();
        }*/
    }.bind(this)
  );

  $(form).find(".js-popup-select2").select2()

  this.updateForm(form);

  if (parameters != undefined && parameters.beforeOpen != undefined)
    parameters.beforeOpen.bind(form)(element);

  this.toggle();
  return this;
}
function showAlertPopup(msg) {
  new popupConfirmation(msg, false, "Ok", false);
}

jQuery(document).ready(function () {
  $(document).on("click", "a[data-confirm]", function (e) {
    e.preventDefault();
    new popupConfirmation(this.dataset.confirm, this.href, this.dataset.action);
  });
});
