/*     ___                __      __  _     
 *    /   |  ____  ____ _/ /_  __/ /_(_)____
 *   / /| | / __ \/ __ `/ / / / / __/ / ___/
 *  / ___ |/ / / / /_/ / / /_/ / /_/ / /__  
 * /_/  |_/_/ /_/\__,_/_/\__, /\__/_/\___/.js
 *                      /____/
 * 
 * How to use?
 * 
 * Analytic()
 * Use analytic in stand-alone is prohibited. Please, build subClass to use it correctly. 
 * 
 * Public Usage
 * function(){
 *      Analytic.call(this);
 *      this.data;
 *      this.submit();     
 * }
 * 
 * OR
 * 
 * Protected / Private Usage
 * function(){
 *      var super = {};
 *      Analytic.call(super);
 *      this.getData = function(){
 *          super.data;
 *      }
 *      function submit(){
 *          super.submit();
 *      }
 * }
 * 
 * For fast usage,  use Analytic.Event(categoty, action, label, value).
 * The only limitation is event.type = event 
 * 
 * Analytic.Event(Category:string, Action:string, Label:string = undefined, Value:mixed = undefined)
 *  - Category = script.js file name or specific function name;
 *  - Action = event type : 'change' OR 'click' OR 'redirect' ...
 *  - Label = text OR label OR attribute name of element we interacted
 *  - Value = url OR input.value 
 * 
 * Analytic.Input(element:DomElement, label:string)
 *  - Action is change
 *  - Label is based on html content or on label Argument by this priority order
 *    1. label argument
 *    2. data-analytic-label
 *    3. placeholder attribute
 *    4. label[for=element.id].innerText
 *    5. name attribute
 *  - Value is determined by input value.
 *    1. [type=password],[data-secure] = '********'  (  ¬ _ ¬)=ᗜ <::::]==¤(ò _ ó  )
 *    2. element.val
 * 
 * Analytic.link(element:DomElement, href:string, name:string)
 * - Action is click or context
 * - Label is based on html content or on label Argument by this priority order
 *    1. label argument
 *    2. data-analytic-label
 *    3. element.innerText
 *  - Value is determined by element.href or href parameter.
 * 
 * Analytic.form(element:DomElement, href:string, name:string)
 *  - Action is submit
 *  - Label is based on html content or on label Argument by this priority order
 *    1. label argument
 *    2. data-analytic-label
 *    3. name attribute
 *  - Value is determined by form.serialize.
 *    1. [type=password],[data-secure] = '********' ᗜ(0 _ 0)ᗜ
 *    2. element.val
 */

Analytic = function(){ // USE ANALYTIC IN STAND-ALONE IS PROHIBITED
    if(this instanceof Analytic)
        throw new Error("Uncaught TypeError: Analytic is not a construtor.");
    var url =  window.location.protocol+'//'+window.location.host+window.location.pathname;
    this.data = {
        //url : window.location.protocol+'//'+window.location.host+window.location.pathname, NO LONGER SUPPORT -> PHP server_referrer
        type: 'event',// REQUIRED
        category : '',// REQUIRED
        action : '',  // REQUIRED
        label : '',
        value : '',
    }
    this.submit = function(){
        var date = new Date();
        this.data.url = url;
        this.data.date =  date.getFullYear()+'-'+(date.getMonth()+1<10?'0'+(date.getMonth()+1):date.getMonth()+1)+'-'+(date.getDate()<10?'0'+(date.getDate()):date.getDate())+' '+(date.getHours()<10?'0'+(date.getHours()):date.getHours())+':'+(date.getMinutes()<10?'0'+(date.getMinutes()):date.getMinutes())+':'+(date.getSeconds()<10?'0'+(date.getSeconds()):date.getSeconds())+':'+(date.getMilliseconds()<10?'00'+(date.getMilliseconds()):(date.getMilliseconds()<100?'0'+(date.getMilliseconds()):date.getMilliseconds()));
        for(key in  this.data){
            this.data[key] = beautify(this.data[key]);
        }
        // if(devtool_detector()) // Don't submit to bds if window in debug mode.
        //     log(this.data);
        // else{
        //     local(this.data);
        //     google(this.data);
        // }
        Analytic.prototype.date = date;
    }

    function devtool_detector(){
        var devtools = /./;
        devtools.opened = false;
        devtools.toString = function() {
        this.opened = true;
        }
        console.log('%c', devtools);
        return devtools.opened;
    }

    function beautify(val){ // REMOVE ALL RETURNS, TABS, DOUBLE SPACES AND SPACES AROND
        if(typeof val == "string"){
            val = val.replace(/[\n\r\t]/g, ' ').replace(/ +(?= )/g,'').trim();
        }
        return val;
    }
    function log(data){/* ONLY FOR DEBUG */
        console.log(data);
    }
    // function local(data){/* SEND DATA TO NETCOM.PARTS */
    //     $.ajax({
    //         url:'/ajax/tracker',
    //         type: 'POST',
    //         data: data,
    //     });
    // }
    function google(data){/* SEND DATA TO GOOGLE ANALYTICS */
        ga('send', 'event', data.category +' ─ '+data.action, data.label, data.value, 0);
    }
    return this;
}
Analytic.prototype.date = new Date();

Analytic.Link =function(element,href,name){
    if(!(this instanceof Analytic.Link))
        throw new Error("Uncaught TypeError: Analytic.Link is a construtor.");
    Analytic.call(this);
    this.data.category = 'Analytic.Link';
    this.data.label  = name!=undefined?name://If Manualy set
                        ($(element).attr('data-analytic-label')!=undefined?$(element).attr('data-analytic-label')://If data-analytic-label is set
                        ($(element).attr('title')!=undefined?$(element).attr('title')://If attr title is set
                        $(element).text()));// Use this.innerText
    this.data.value = href!=undefined?href:$(element).attr('href');
    $(element).on('click',function(){
        this.data.action = "click";
        this.submit();
    }.bind(this));

    element.addEventListener('contextmenu', function() {
        this.data.action = "contextmenu";
        this.submit();
    }.bind(this), false);
}

Analytic.Input = function(element,label){
    if(!(this instanceof Analytic.Input))
        throw new Error("Uncaught TypeError: Analytic.Input is a construtor.");
    Analytic.call(this);
    this.data.category ='Analytic.Input';
    this.data.label = label!=undefined?label: //If we set label
                        ($(element).attr('data-analytic-label')!=undefined?$(element).attr('data-analytic-label'): // Use Data Analytic tag
                        ($(element).attr('placeholder') != undefined?$(element).attr('placeholder'): //Use placeholder tag
                        ($(element).attr('id') && $('label[for="'+$(element).attr('id')+'"]') ? $('label[for="'+$(element).attr('id')+'"]').text() : // Use <lablel for=this.id />.innerText
                        $(element).attr('name')))); //Use name tag
    
    $(element).on('change',function(){
        this.data.action = 'change';
        this.data.value  = ($(element).attr('type') != 'password' && $(element).data('secure')==undefined ? $(element).val():'********'); //We feel bad if we have a database breach. ᗜಠ o ಠ)¤=[:::::>
        this.submit();
    }.bind(this));
}

Analytic.Form = function(element, label){
    if(!(this instanceof Analytic.Form))
        throw new Error("Uncaught TypeError: Analytic.Form is a construtor.");
    Analytic.call(this);
    this.data.category ='Analytic.Form';
    this.data.label = label!=undefined?label: //If we set label
                        ($(element).attr('data-analytic-label')!=undefined?$(element).attr('data-analytic-label'): // Use Data Analytic tag
                        $(element).attr('name')); //Use name tag
    $(element).on('submit',function(){
        this.data.action = 'submit';
        this.data.value  = $(element).serialize();
        $(element).find('input[type="password"],[data-secure]').each(function(index, input){
            this.data.value = this.data.value.replace($(input).serialize(),'crypt=********'); //We feel bad if we have a database breach. (╯òДó）╯︵ ┻━┻
        }.bind(this))
        this.submit();
    }.bind(this));
}

// Constructor Method
Analytic.Event = function(category,action,label,value){
    if(!(this instanceof Analytic.Event))
        throw new Error("Uncaught TypeError: Analytic.Event is a construtor.");    
    Analytic.call(this);
    
    if(typeof category == "string" && category!="") this.data.category = category;
    else if(category== undefined) throw new Error("Uncaught TypeError:Argument 1 of Analytic.Event is required");
    else throw new Error("Uncaught TypeError:Argument 1 of Analytic.Event must be a string");
    
    if(typeof action == "string") this.data.action = action;
    else if(action== undefined) throw new Error("Uncaught TypeError:Argument 2 of Analytic.Event is required");
    else throw new Error("Uncaught TypeError:Argument 2 of Analytic.Event must be a string");
    
    if(typeof label == "string") this.data.label = label;
    else if(label!= undefined) throw new Error("Uncaught TypeError:Argument 3 of Analytic.Event must be a string");
    
    if(typeof value == "string" || typeof value == "number") this.data.value = value;
    else if(value!= undefined) throw new Error("Uncaught TypeError:Argument 4 of Analytic.Event must be a string");
    this.submit();
}

// Not Constructor Method
Analytic.event = function(category,action,label,value){
    if(this instanceof Analytic.Event)
        throw new Error("Uncaught TypeError: Analytic.event is not a construtor.");    
    Analytic.call(this);
    
    if(typeof category == "string" && category!="") this.data.category = category;
    else if(category== undefined) throw new Error("Uncaught TypeError:Argument 1 of Analytic.Event is required");
    else throw new Error("Uncaught TypeError:Argument 1 of Analytic.Event must be a string");
    
    if(typeof action == "string") this.data.action = action;
    else if(action== undefined) throw new Error("Uncaught TypeError:Argument 2 of Analytic.Event is required");
    else throw new Error("Uncaught TypeError:Argument 2 of Analytic.Event must be a string");
    
    if(typeof label == "string") this.data.label = label;
    else if(label!= undefined) throw new Error("Uncaught TypeError:Argument 3 of Analytic.Event must be a string");
    
    if(typeof value == "string" || typeof value == "number") this.data.value = value;
    else if(value!= undefined) throw new Error("Uncaught TypeError:Argument 4 of Analytic.Event must be a string");
    this.submit();
}

jQuery('document').ready(function(){
    /* GENERAL ACTION (∩>.<)⊃━☆ﾟ.*/
    $('a').each(function(){new Analytic.Link(this);});
    $('[onclick*="location.href"]').each(function(){
        var url = $(this).attr('onclick').replace('location.href','').replace('window.','')
        url = url.slice(1, url.length-1);
        new Analytic.Link(this,url);
    });
    $('[data-link]').each(function(){
        var url = $(this).data('link');
        new Analytic.Link(this,url);
    });
    $('input[type="text"], input[type="number"], input[type="password"], input[type="email"], input[type="tel"], textarea').each(function(){
        new Analytic.Input(this);
    });
    $('form').each(function(){
        new Analytic.Form(this);
    });

    /* UNIQUE CASE HERE */
    $('#contact_form_subject').each(function(){
        new Analytic.Input(this)
    });
    $('[onclick*="step4Link"]').each(function(){
        var url = $(this).attr('onclick').replace('step4Link','')
        url = url.slice(2, url.length-2);
        new Analytic.Link(this,url);
    });
});
