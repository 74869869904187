/*const reader = new FileReader();
const fileInput = document.getElementById("file");
const img = document.getElementById("img");
reader.onload = e => {
  img.src = e.target.result;
}
fileInput.addEventListener('change', e => {
  const f = e.target.files[0];
  reader.readAsDataURL(f);
})*/


(function ( $ ) {
    $.fn.imgUploader = function() {
        this.each(function(){
          var missingImage = "https://netcomstorage.com/Image/no-image.webp";
          var reader = new FileReader();
          var src=missingImage
          if($(this).attr('value')) src = $(this).attr('value');
          var image = $('<img src="'+src+'" width="50px" height="50px" style="object-fit: cover;" />');

          reader.onload = function(e){
            image.attr('src',e.target.result);
          }

          image.on("error", function () {
            $(this).unbind("error").attr("src", missingImage);
          });

          $(this).hide().on('change',function(e){
            var f = e.target.files[0];
            if (f.type.startsWith('image/')) {
              reader.readAsDataURL(f);
            } else {
              image.attr('src',missingImage); 
          }
          }).after(image);
        });
    }
}( jQuery ));