var lang = document.documentElement.lang;

function initMap() {
  var mapDiv = document.getElementById('map');
  myLatLng = new google.maps.LatLng(45.581228, -73.455093);
  myLatLng2 = new google.maps.LatLng(45.581228, -73.555093);
  var map = new google.maps.Map(mapDiv, {
      center: myLatLng2,
      zoom: 13
  });
  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: ''
  });


map.setOptions({styles: styles});
}

function getMapLocality($data){
  var rsp = 'Unknown';
  for(i=0; i<$data['address_components'].length; i++){
    $c = $data['address_components'][i];
    if(
      $c['types'].indexOf('sublocality') != -1 ||
      $c['types'].indexOf('locality') != -1 ||
      $c['types'].indexOf('administrative_area_level_1') != -1 ||
      $c['types'].indexOf('country') != -1 ){
        rsp = $c.long_name;
        break;
    }
  }
  return rsp;
}

function initMapV2(){ jQuery(document).ready(function(){
  //console.log('map here');
  //console.log(jQuery('#map-store-locator').length);
  if(jQuery('#map-store-locator').length){
    initMapStoreLocator()
  }
  if(jQuery('#map-store-editor').length){
    initMapStoreEditor()
  }

  if(jQuery('.js-demo-map').length){
    initMapStoreDemo()
  }

  if(jQuery('#map-contact').length){
    initMapContactUs()
  }

  if(jQuery('.js-container-partial--change-location').length){
    var input = document.querySelector('.js-custom-location')
    var searchBox = new google.maps.places.SearchBox(input)

    searchBox.addListener('places_changed', function() {
      var places = searchBox.getPlaces();
      if(places.length){
        var place = places[0];
        var coord = {
          long:place.geometry.location.lng(),
          lat: place.geometry.location.lat()
        }
        $('form.js--add-a-location input[name="new_location[name]"]').val(getMapLocality(place));
        $('form.js--add-a-location input[name="new_location[coord][long]"]').val(coord.long);
        $('form.js--add-a-location input[name="new_location[coord][lat]"]').val(coord.lat);
        $('#new_location-max_distance').prop('disabled', false);
      }
    });

    if(!$('.js--geo-sources .js-row-location').length)
      navigator.geolocation.getCurrentPosition(function(pos) {
        coordinatesToLocation(pos.coords.latitude,pos.coords.longitude);
      },function(e){
        //LOCAL DEV OR TRACKING DISABLED FALL BACK
        //coordinatesToLocation(45.5012568, -73.4389937);

        //FALL BACK TO PHP IP LOACATOR
        /*$.ajax({
          url:'/ajax/addlocation',
          method:'post',
          data:{
            'is_IP':true,
          },
          dataType:'json',
          success:function(rsp){
            if(rsp.success == 1){
              var data = rsp.data;
              $('.js--geo-sources').html($('.js--geo-sources').data('text')+'<br> <span class="js-location-data vehicule-data">'+'<span class="js-row-location" data-lat="'+data.coord.lat+'" data-long="'+data.coord.long+'" data-name="'+data.short_name+'"><i class="fa fa fa-map-marker ml0 mr1"></i> '+data.name+'</span>'+'</span>');
              $('.js--geo-sources').find('.js-location-data').trigger('click');
            }
          }
        });*/

      });
   }
})};

function coordinatesToLocation(lat,long){
  var geocoder = new google.maps.Geocoder();
  var geolocate = new google.maps.LatLng(lat, long);
  geocoder.geocode({'latLng': geolocate}, function(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
      var city = false;
      var state = false;
      var country = false;
      results.forEach(function(results){
        results.address_components.forEach(function(componant){
          if(!city && componant.types.indexOf('locality') >=0)
            city = componant.long_name;
          else if(!city && componant.types.indexOf('sublocality_level_1') >=0)
            city = componant.long_name;
          else if(!state && componant.types.indexOf('administrative_area_level_1') >=0)
            state = componant.short_name;
          //else if (!country && componant.types.indexOf("administrative_area_level_2") >=0)
          //  country = componant.long_name;
          else if(!country && componant.types.indexOf('country') >=0)
            country = componant.long_name;
        })
        
      })
        addNewLocation(
          {lat:lat,long:long},
          (city?city+", ":'')+state+", "+country,
          city?city:(state+", "+country),
          50,
          true
        );
      }  
  });
}

function addNewLocation(coord,name,short_name,distance,is_geolookup){
  $.ajax({
    url:'/ajax/addlocation',
    method:'post',
    data:{
      coord:coord,
      name:name,
      short_name:short_name,
      distance:distance,
      is_geolookup: is_geolookup == true?1:0
    },
  });

  var element;
  if(is_geolookup == true){
    $('.js--geo-sources').html($('.js--geo-sources').data('text')+'<br> <span class="js-location-data vehicule-data">'+'<span class="js-row-location flex flex-no_wrap" data-lat="'+coord.lat+'" data-lng="'+coord.long+'" data-name="'+short_name+'" data-formated-address="'+name+'" data-distance="'+distance+'"><i class="fa fa fa-map-marker  ml0 mr1"></i> <span>'+name+'</span></span>'+'</span>');
    element = $('.js--geo-sources')
  }
  else{
    // Add to menu
    element  = $('<div class="js-locationrow vehiculerow flex flex-no_wrap">'+
    '<span class="js-location-data vehicule-data">'+
        '<span class="js-row-location flex flex-no_wrap" data-lat="'+coord.lat+'" data-lng="'+coord.long+'" data-name="'+short_name+'" data-formated-address="'+name+'" data-distance="'+distance+'"><i class="fa fa fa-map-marker  ml0 mr1"></i> <span><b>'+short_name+'</b><br>'+name+'</span></span>'+
    '</span>'+
    '<a href="javascript:void(0);" title="Remove Vehicle | Netcom" class="js-removeLocation removelocation" data-url="/ajax/removelocation/"><i class="fa fa-trash"></i></a>'+
    '</div>')

    $('.js-container-partial--change-location .js-rows-container').append(element);
  }
    element.find('.js-location-data').trigger('click');
}

function distanceBetween(lat1,lon1,lat2,lon2){
  //Base on https://www.movable-type.co.uk/scripts/latlong.html
  var R = 6371; // Earth Radius KM
  var φ1 = Math.radians(lat1);
  var φ2 = Math.radians(lat2);
  var Δφ = Math.radians(lat2-lat1);
  var Δλ = Math.radians(lon2-lon1);

  var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
}


function initMapStore(lat,lng) {
  var mapDiv = document.getElementById('map-store');
  myLatLng = new google.maps.LatLng(lat, lng);

  var map = new google.maps.Map(mapDiv, {
      center: myLatLng,
      zoom: 13
  });
  var marker = new google.maps.Marker({
  position: myLatLng,
  map: map,
  title: ''
});


map.setOptions({styles: styles});

}

function initMapContactUs() {
  var mapContact =  document.getElementById('map-contact');
  myLatLng = new google.maps.LatLng(45.447162, -73.4321956);   
  var size = new google.maps.Size(25, 25);
  var map = new google.maps.Map(mapContact, {
    center: myLatLng,
    zoom: 12,    
  });
  addMarkerContactUs(map,'NetCom',myLatLng,size);
  map.setOptions({styles: stylesContact});
}

function displayInfoWindowNetcom () {
    return new google.maps.InfoWindow({
      content: '<a target="_blank" href="https://www.google.com/maps/place/NetCom/@45.447162,-73.4321956,16z/data=!3m1!4b1!4m6!3m5!1s0x4cc905f556a33333:0x1c8be9ab6e39970d!8m2!3d45.447162!4d-73.4321956!16s%2Fg%2F11rq4170j9?entry=ttu" class="color--secondary">2871 Rue Star, Saint-Hubert, Quebec J3Y 3W5</a>' 
    });
}
var marker;
function addMarkerContactUs(map,title,latLon,size) {
  var marker = new google.maps.Marker({
    position: latLon,
    map: map,
    title: title,
    icon: {
        url: "/dist/images/netcom-logo-map.png",
        size: size,
        scaledSize: size
    }
  });
  var infowindow = displayInfoWindowNetcom();
  marker.addListener("click", function() {
    smoothZoom(map, 18, map.getZoom());
    infowindow.open(map,marker);
  });
  return marker;
}

function smoothZoom (map, max, cnt) {
  if (cnt >= max) {
      return;
  }
  else {
      z = google.maps.event.addListener(map, 'zoom_changed', function(event){
          google.maps.event.removeListener(z);
          smoothZoom(map, max, cnt + 1);
      });
      setTimeout(function(){map.setZoom(cnt)}, 150);
  }
}  

function setMarkersOpacity(lat,lng,distance){
  marker_array_yellow.forEach(function(marker){
    if( distanceBetween(lat,lng,marker.position.lat(),marker.position.lng())<= distance){
      try {
        marker.setIcon(marker_icon_url+'?zoned');
        marker.setOptions({labelClass:'marker-price-label zoned'});
      }catch (e) {console.log(e)}
    }
    else{
      try {
        marker.setIcon(marker_icon_url);
        marker.setOptions({labelClass:'marker-price-label'});
      }catch (e) {console.log(e)}
    }
  })

  marker_array_gray.forEach(function(marker){
    if( distanceBetween(lat,lng,marker.position.lat(),marker.position.lng())<= distance)
      marker.setOpacity(1);
    else
      marker.setOpacity(0.75)
  })
}


var circle = undefined;
var mii = undefined;
var ctaLayer = undefined;
var initMapStoreLocatorDone = false;

function initMapStoreLocator(partNumber, manufacturer, partType) {
  //if(initMapStoreLocatorDone) return;
  //initMapStoreLocatorDone = true;
  var input = document.getElementById('pac-input');
  var isDefault = false;
  if(input.dataset.lat && input.dataset.lng) {
        myLatLng2 = new google.maps.LatLng(input.dataset.lat, input.dataset.lng);
  }
  else  {myLatLng2 = new google.maps.LatLng(45.501228, -73.555093); isDefault = true}
  var zoom = document.querySelector('.js-range')? (12-Math.ceil((( parseInt(document.querySelector('.js-range').innerText)-10)/90)*4)):8;
  
  map = new google.maps.Map(document.getElementById('map-store-locator'), {
    center: myLatLng2,
    zoom: zoom,
    gestureHandling: 'greedy', 
    zoomControl: true,
    scaleControl: true,
    mapTypeControl: false,
    zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
    },
    streetViewControl: true,
    streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
    },
    fullscreenControl: false
  });
  map.setOptions({styles: styles});

  //LOAD GMAP ADDON
  var script = document.createElement('script');
  script.onload = function () {  setMarkers(map,partNumber,manufacturer,partType);};
  script.src = '/wp-content/themes/otto/dist/js/markerwithlabel.js';
  document.head.appendChild(script); //or something of the likes

  radius = 25;
  if(document.querySelector('.js-range')!=null){
    radius =  parseInt(document.querySelector('.js-range').innerText);
  }
  else if(document.querySelector('.js-container-range input[type=range]')!=null){
    radius =  parseInt(document.querySelector('.js-container-range input[type=range]').value);
  }

  if(input.dataset.lat && input.dataset.lng){
    circle = new google.maps.Circle({
      strokeColor: color_primary != ""?color_primary:'#F28D00',
      strokeOpacity: 0.25,
      strokeWeight: 2,
      fillColor: color_primary != ""?color_primary:'#F28D00',
      fillOpacity: 0.1,
      map: map,
      center: myLatLng2,
      radius:  1000 * radius
    })
    mii = new google.maps.Marker({
      position: myLatLng2,
      map: map,
      icon: '/dist/images/map-marker-mii.png',
      id: mii
    }); 
  }

    $('.js-full-screen-map').click(function(){
        document.body.requestFullscreen();

    });
    if (document.addEventListener)
    {
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
    }

    function exitHandler()
    {
        if (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null)
        {
            if(!$('#map-store-locator').hasClass('full-screen')){
                $('.js-full-screen-map').addClass('display-none');
                $('#map-store-locator').addClass('full-screen');
                $('.container-partial--step-breadcrumb').addClass('display-none');
                $('.map--tools').addClass('full-screen');
                $('.site-header ').addClass('display-none');
                $('.site-footer').addClass('display-none');
                $('#map-store-locator.full-screen').height($(window).height() - $('.map--tools').height() - 15);
                setTimeout(function(){
                    $('#map-store-locator.full-screen').height($(window).height() - $('.map--tools').height() - 15);
                },200);
            }
            else{
                $('#map-store-locator').removeClass('full-screen');
                $('.js-full-screen-map').removeClass('display-none');
                $('.container-partial--step-breadcrumb').removeClass('display-none');
                $('.map--tools').removeClass('full-screen');
                $('.site-header ').removeClass('display-none');
                $('.site-footer').removeClass('display-none');
                // $('#map-store-locator.full-screen').height($(window).height() - $('.map--tools').height() - 15);
            }
        }
    }
  // Create the search box and link it to the UI element.
  var searchBox = new google.maps.places.SearchBox(input);
 //map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  // Bias the SearchBox results towards current map's viewport.
  map.addListener('bounds_changed', function() {
    searchBox.setBounds(map.getBounds());
  }); 

  searchBox.addListener('places_changed', function() {
    var places = searchBox.getPlaces();

    if (places.length == 0) {
      return;
    }

 
    var bounds = new google.maps.LatLngBounds();
    places.forEach(function(place) {
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
        nearMarkers(place, marker_array_yellow);
        nearMarkers(place, marker_array_gray);
        bounds.extend(markers_near.position);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);


    var places = searchBox.getPlaces();
    if(places.length){
        var place = places[0];
        var coord = {
          long:place.geometry.location.lng(),
          lat: place.geometry.location.lat()
        }
        $('form.js--add-a-location .js-custom-location').val(input.value);
        $('form.js--add-a-location input[name="new_location[name]"]').val(place.formatted_address);
        $('form.js--add-a-location input[name="new_location[coord][long]"]').val(coord.long);
        $('form.js--add-a-location input[name="new_location[coord][lat]"]').val(coord.lat);
        $('#new_location-max_distance').prop('disabled', false);
        $('.js--btn-change--current-location').trigger('click')
    }

    //console.log("this one")
  });


  // geolocation
  if (isDefault && navigator.geolocation) {
   
    navigator.geolocation.getCurrentPosition(function(position) {
      
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      map.setCenter(pos);
   
    });
  } else {
    // Browser doesn't support Geolocation
  }

  
}

if (color_primary != "") {
  styler =  color_primary;
} else {
  styler =  ""; 
}


var styles = [

   {
        "stylers": [
            {
               "hue" : styler
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "lightness": 100
            },
            {
                "visibility": "simplified"
            }
        ]
    }
];

var stylesContact = [

  {
       "stylers": [
           {
              "hue" : styler
           }
       ]
   },
   {
       "v": "road",
       "elementType": "labels.icon",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "road",
       "elementType": "geometry",
       "stylers": [
           {
               "lightness": 100
           },
           {
               "visibility": "simplified"
           }
       ]
   },
];

function rad(x) {return x*Math.PI/180;}
function nearMarkers( place, marker_array ) {
 
    var lat = place.geometry.location.lat();
    var lng = place.geometry.location.lng();
    var R = 6371; // radius of earth in km
    var distances = [];
    var closest = -1;
    for( i=0;i<marker_array.length; i++ ) {
        var mlat = marker_array[i].position.lat();
        var mlng = marker_array[i].position.lng();
        var dLat  = rad(mlat - lat);
        var dLong = rad(mlng - lng);
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        distances[i] = d;
        if ( closest == -1 || d < distances[closest] ) {
            closest = i;
        }
    }
    markers_near = marker_array[closest];

    return markers_near;
}

var marker_array_yellow = new Array();
var marker_array_gray = new Array();
var marker_icon_url = '/wp-content/themes/netcom/inc/icon-location.php';

function getPosition(string, subString, index) {
   return string.split(subString, index).join(subString).length;
}



function setMarkers(map,part_number, manufacturer,part_type) {

  // Marker sizes are expressed as a Size of X,Y where the origin of the image
  // (0,0) is located in the top left of the image.

  // Origins, anchor positions and coordinates of the marker increase in the X
  // direction to the right and in the Y direction down.
  var image_yellow = {
    url: marker_icon_url,
    size: new google.maps.Size(60, 60),
    //origin: new google.maps.Point(0, 32),
    //anchor: new google.maps.Point(0, 32)
    labelOrigin: new google.maps.Point(30,-30),
  };

  var image_zoned = {
    url: marker_icon_url+'?zoned',
    size: new google.maps.Size(60, 60),
    //origin: new google.maps.Point(0, 32),
    //anchor: new google.maps.Point(0, 32) 
  };
  var image_gray = {
    url: '/dist/images/map-marker-gray.png',
    size: new google.maps.Size(60, 60),
    //origin: new google.maps.Point(0, 32),
    //anchor: new google.maps.Point(0, 32) 
  };
  infowindow  = new InfoBubble({
      map: map,
      content: '',
      position: new google.maps.LatLng(-32.0, 179.0),
      shadowStyle: 3,
      padding: 20,
      backgroundColor: 'rgb(255,255,255)',
      borderRadius: 0,
      arrowSize: 10,
      borderWidth: 0,
      minWidth: 50,
      maxWidth: 500,  
      minHeight: 60,
      borderColor: '#2c2c2c',
      disableAutoPan: true,
      hideCloseButton: true,
      arrowPosition: 30,
      backgroundClassName: 'transparent', 
      arrowStyle: 0
  });
  if (lang =='fr') {
    url = window.location.href;
    url = url.replace('fr/', '');
  } else {
   url = window.location.href;
  }

  if(typeof manufacturer != "string"){
    index_start = getPosition(url, '/', 4); 
    index_end = getPosition(url, '/', 5); 
    manufacturer = url.substring(index_start+1, index_end);
  }

  if(typeof part_type != "string"){
    index_start = getPosition(url, '/', 5); 
    index_end = getPosition(url, '/', 6); 
    part_type = url.substring(index_start+1, index_end);
  }

  if(typeof part_number != "string"){
    index_start = getPosition(url, '/', 6); 
    index_end = getPosition(url, '/', 7); 
    part_number = url.substring(index_start+1, index_end);
  }

  var load = {};
  if(mii != undefined){
    load.lat = mii.position.lat();
    load.lng = mii.position.lng()
  }
  loadStores(load).then(function(){
    marker_array_yellow = new Array();
    marker_array_gray = new Array();
    jQuery.ajax({
        //url: '/wp-content/themes/netcom/inc/store-ids.php', 
        url: '/ajax/loadStores', 
        type: 'POST', 
        data: {
          manufacturer : manufacturer,
          part_type : part_type,
          part_number : part_number
        }, 
        dataType:'json',
        success: function(data) {
          var store_ids = data;
          parseMarkers(store_ids,part_number,manufacturer,part_type);
        }
    })
  })


function parseMarkers(store_ids,part_number,manufacturer,part_type) {
  var input = document.getElementById('pac-input');
  for (var i = 0; i < stores.length; i++) {
      var store = stores[i];
      var price = 0;
      var max = $('.js-container-range input[type=range]').val()?$('.js-container-range input[type=range]').val():25;
      var distance = 0;
      var distanceSTR = 'Unknow';
      if(input.dataset.lat && input.dataset.lng){
        distance = distanceBetween(input.dataset.lat,input.dataset.lng, Number(store.lat),Number(store.long));
        distanceSTR = Math.round(distance) + ' km'
        if(distance<1){
          distanceSTR = Math.round(distance*1000) + ' m'
        }
      }


      if (store_ids[store.id] != undefined ) {
        var icon = distance<max?image_zoned:image_yellow;
        price = store_ids[store.id];
      } else {
        var icon = image_gray;
      }

      if(price){
        price.price = parseFloat(price.price);
        price.pricediscount = parseFloat(price.pricediscount);
        if(price.pricediscount >= price.price) price.pricediscount = 0;
        price.deposit = parseFloat(price.deposit)?parseFloat(price.deposit):0;
        price.tax = parseFloat(price.tax)?parseFloat(price.tax):0;
        price.stockleft = parseFloat(price.stockleft);

        var marker = new MarkerWithLabel({
          map: map,
          position: {lat: Number(store.lat), lng: Number(store.long)},
          icon: icon,
          id: store.id,
          place_id: store.gmap_id,
          labelContent: '<b>$'+(price.pricediscount?price.pricediscount:price.price).formatMoney()+'</b>',
          labelAnchor: new google.maps.Point(-5, 75),
          labelClass: "marker-price-label"+ (distance<max?' zoned':''), // the CSS class for the label
          labelInBackground: true,
          price: price
        });
        appendStoreMap(marker,store, price);
      }
      else{
        var marker = new google.maps.Marker({
          position: {lat: Number(store.lat), lng: Number(store.long)},
          map: map,
          icon: icon,
          id: store.id,
          place_id: store.gmap_id,
          title: store.name,
          version: new Date(store.updated_at).getTime(),
        });
      }

      (function (marker) {
        google.maps.event.addListener(marker, "click", function (e) {
          store_id = marker.id;
          data = {
            action: 'loadStoreData',
            manufacturer : manufacturer,
            part_number : part_number,
            part_type : part_type,
            store_id : store_id
          }
          if(!window.preventPushState)
            history.pushState(data, "Where to buy", window.location.href);
            $('.map-store-locator-popup>.js-close').addClass('fa-arrow-left').removeClass('fa-close')

          var params = 'store_id='+store_id;
          if(ctaLayer!=undefined) ctaLayer.setMap(null);
          ctaLayer = new google.maps.KmlLayer({
            url: window.location.protocol+"//"+window.location.hostname+"/wp-content/themes/netcom/inc/pos.kml.php?u=1&v="+marker.version+"&"+params,
            map: map,
            preserveViewport:true
          });

          console.log(data);
          
          /* remove part store*/
          //if(!$('.map-store-locator-popup').length){
            
            jQuery.ajax({
                url: '/ajax/loadStores', 
                type: 'POST', 
                data: data, 
                success: function(data) {
                  if (data != '') {
                    part_info = JSON.parse(data);
                    part_info_text = getPartInfoText(part_number,manufacturer,part_type,image_part, part_info)          
                    jQuery('.js-modal--content .js-part-info').html(part_info_text);
                  }
                }
            });
          //}

          var request = {placeId: marker.place_id};
          var service = new google.maps.places.PlacesService(map);
          if( marker.place_id)
          service.getDetails(request, function(place, status) {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
              if(place.opening_hours!=undefined){
                var today_text = '',
                 today = (new Date).getDay();
                place.opening_hours.periods.forEach(function(el){
                  if(el.open.day == today) today_text = '<span class="hide-open">: '+el.open.hours+'h'+(el.open.minutes<10?'0'+el.open.minutes:el.open.minutes)+' – '+el.close.hours+'h'+(el.close.minutes<10?'0'+el.close.minutes:el.close.minutes)+'</span> ';
                })
                var li = $('<li><i class="fa-absolute fa fa-clock-o" aria-hidden="true"></i><p class="js-dropdown">'+__(place.opening_hours.isOpen()?'Open':'Closed')+today_text+' <i class="fa fa-angle-down" aria-hidden="true"></i></p><ul style="display:none"></ul></li>')
                place.opening_hours.weekday_text.forEach(function(txt,index){
                  li.find('ul').append('<li class="ml1'+(today == index ?' text-bold':'')+'"><p>'+txt+'</p></li>')
                })
                li.on('click','.js-dropdown',function(){
                  $(this).find('.fa').toggleClass('fa-angle-down fa-angle-up');
                  li.find('ul').slideToggle(); 
                  li.find('.hide-open').toggleClass('hide'); 
                })
                $('.js-stores-attributes').append(li)
              }
              if(place.photos!=undefined){
                locationPict = place.photos[0].getUrl();
                //$('.js-images').append('<img src="'+locationPict+'" alt="'+'" />')
                $('.js-images img').attr('src', locationPict)
              }
              
              if(place.rating != undefined){
                $('.js-g_rating').html(place.rating + ' ' +place.rating.toStars()+' ('+place.user_ratings_total+')');
                var rhtml = '';
                for(i in place.reviews){
                  var review = place.reviews[i];
                  rhtml += '<li class="container-store-review"><img src="'+review.profile_photo_url+'" /><div><div class="rating">'+review.rating.toStars()+'</div>'+(review.text.trim()!=''?'<q>'+review.text+'</q>':'')+'<div class="text-right">'+review.author_name+'</div></div></li>';
                }
                $('.container-store-reviews').html(rhtml);
              }
              else{
                $('.js-g_rating').html('Aucun avis');
                $('.container-reviews-section').hide();
              }
            }
          })
        });
        
        google.maps.event.addListener(marker, 'visible_changed', function(){
          var clusterer;
          switch(marker.icon.url){
            case image_yellow.url:
            case image_zoned.url:
              clusterer = markerClusterYellow;
              break;
            default:
              clusterer = markerClusterGray;
          }
          clusterer.removeMarker(marker,true);
          if ( marker.getVisible() ) {
            clusterer.addMarker(marker,true);
          } else {
            clusterer.removeMarker(marker,true);
          }
          if(window.preventRepaint!=undefined){
            clearTimeout(window.preventRepaint);
          }
        });
    })(marker);

    if(marker.icon.url.indexOf('.php') !== -1) {
      marker_array_yellow.push(marker);
    } else {
      //console.log(marker.icon.url);
      marker_array_gray.push(marker);
    }
    var content = '<div class="infobubble-text js-infobubble-text">' + store.name + '</div>';
    var content2 = '<div class="banner-image js-images"><img src="https://maps.gstatic.com/tactile/pane/default_geocode-1x.png" /><div class="banner-image-popup '+ ($('.map-store-locator-popup').length?'':'left') +'">'+
    '<div class="banner-image-popup__foot"><button class="color--primary'+($('.map-store-locator-popup').length?'" onclick="window.history.back()"':' js-close-marker-info"')+'><i class="fa fa-'+($('.map-store-locator-popup').length?'arrow-left':'close')+'"></i> '+__($('.map-store-locator-popup').length?'Back':'')+'</button></div></div></div>'+
    '<div class="container-title"><h3 class="color--gray-base">' + store.name + '</h3><div class="js-g_rating"></div><div class="tagline">'+store.banner+'</div></div>' + 
    '<div class="js-part-info part-info container p0"></div>' +
    '<ul class="ml0 stores-attributes js-stores-attributes"><li><i class="fa-absolute fa fa-phone" aria-hidden="true"></i><span class="tel">' + store.phone + '</span><br><br>' + 
    '<li><i class="fa-absolute fa fa-map-marker" aria-hidden="true"></i><p>' + store.address + '<br>' + 
    store.city +  ', ' + 
    store.province + ' ' +
    store.postal_code + '<br>' +
    store.country + '</p></li>'+
    '<li><i class="fa-absolute fa fa-arrows-h color--primary"></i><p class="js-distance">'+distanceSTR+'</p></li>'+
    '</ul><br><br>';

    if (lang =='fr') {
      button_label_direction = 'Itinéraire';
      button_label_visit = 'Visitez ce magasin à '; 
      link = '/fr/magasin/';
    } else {
      button_label_direction = 'Directions';
      button_label_visit = 'Visit this store in ';
      link = '/store/';
    }

    //content2 +='<button class="btn" onclick="window.open(\'http://maps.google.com/maps?daddr=' + store.lat + ',' + store.long +'&amp;saddr=\')">' + button_label_direction + '</button>';
    content2 +='<button class="btn" onclick="window.location.href=\'' + link + escape(store.city) + '/' + escape(store.name)  + '/' + store.id + '\'">' + button_label_visit + store.city + '</button>'
    if(marker.place_id)
      content2 +='<br><br><section class="container-reviews-section"><h3 class="color--gray-base">Résumé des avis</h3><ul class="container-store-reviews"></ul></section>'
    google.maps.event.addListener(marker,'click', (function(marker,content,content2,infowindow){ 
      return function() {
         infowindow.setContent(content);
         jQuery('.js-infobubble-text').css('background-color','red');
         jQuery('.js-info-bubble-close').attr('src','/dist/images/close.png');
         jQuery('.js-modal--store-info').css('display', 'none');

        var input = document.getElementById('pac-input');
        var distanceSTR = 'Unknow';
        var max = $('.js-container-range input[type=range]').val()?$('.js-container-range input[type=range]').val():25;
        if(input.dataset.lat && input.dataset.lng){
          distance = distanceBetween(input.dataset.lat,input.dataset.lng,marker.position.lat(),marker.position.lng());
          distanceSTR = Math.round(distance) + ' km'
          if(distance<1){
            distanceSTR = Math.round(distance*1000) + ' m'
          }
        }
         
        if (content) {
          jQuery('.js-modal--content').html(content2);

         
          if(distance<max) jQuery('.js-modal--content').addClass('zoned');
          jQuery('.js-modal--content').on('click', '.js-close-marker-info', function(e){
            e.preventDefault();
            history.pushState(null, '', window.location.href);
            $('.js-modal--store-info').hide();
            infowindow.close();
            if(ctaLayer!=undefined) ctaLayer.setMap(null);
            //console.log('test')
          });
          jQuery('.js-modal--content').find('.js-distance').html(distanceSTR)
          jQuery('.js-modal--store-info').fadeIn()
        }
         
         map.panTo(marker.getPosition());
         infowindow.close();
         infowindow.open(map,marker);
         $('.js-map-stores-holder').empty();
        };
      })(marker,content,content2,infowindow));
    }

    var clusterStylesYellow = [
     {
        textColor: 'black',
        url: marker_icon_url, 
        height: 72,
        width: 60,
        textSize: 20,
        anchor:[12,-2]

      }
    ];
   
    var marker_style_yellow  = {
      gridSize: 50,
      styles: clusterStylesYellow,
      maxZoom: 15
    };

    var clusterStylesGray = [
     {
        textColor: 'black',
        url: '/dist/images/map-marker-gray.png',
        height: 72,
        width: 60,
        textSize: 20,
        anchor:[12,-2]

      }
    ];
   
    var marker_style_gray  = {
      gridSize: 50,
      styles: clusterStylesGray,
      maxZoom: 15
    };
    
    window.markerClusterGray = new MarkerClusterer(map, marker_array_gray, marker_style_gray);
    window.markerClusterYellow = new MarkerClusterer(map, marker_array_yellow, marker_style_yellow);
    setMarkersVisibility();


    var url = new URL(url_string);
    var pos_id = url.searchParams.get("pos_id");
    
    if(pos_id) {
      for (var i = 0; i < marker_array_yellow.length; i++) {
        if(marker_array_yellow[i].id == pos_id) {
          window.preventPushState = true;
          marker = marker_array_yellow[i];
          google.maps.event.trigger(marker, 'click');
          window.preventPushState = false;
        }
      }
    }

  }
  jQuery('.js-close--modal').on('click', function() {
     infowindow.close();
  });

  url_string = window.location.href;
  
} //end function parseMarkers




jQuery(document).ready(function(){
  jQuery('.js-close--modal').on('click', function() {
    jQuery('.js-modal--store-info').css('display', 'none');
  });


  jQuery('.js-slider').slider({
    value: document.querySelector('.js-range')!=undefined?Math.ceil((( parseInt(document.querySelector('.js-range').innerText)-10)/90)*4)+8: 8+2,
    min: 8,
    max: 12,
  
    //this updates the value of your hidden field when user stops dragging
    change: function(event, ui) {
      zoom = ui.value;
      var max = $(this).slider('option','max');
      var min = $(this).slider('option','min');
      var distance = 5;
      map.setZoom(max-zoom+min);

      switch(zoom){
        case min: distance=10; break;
        case min+1: distance=25; break;
        case min+2: distance=50; break;
        case min+3: distance=75; break;
        case min+4: distance=100; break;
      }
      if(circle!=undefined){
        circle.setRadius(distance * 1000);
        setMarkersOpacity(circle.center.lat(),circle.center.lng(),distance)
      }
      jQuery('.js-range').text(distance); 
    }
  });


    jQuery('.js-step3,.js-product-detail').on('click','.js-map-overlay',function(){
      //console.log( $('.js-front-page .js-model').val() );
      var data= {
        action: 'loadWhereToBuy',
        year: $('.js-front-page .js-year,.js-search-year').val(),
        make: $('.js-front-page .js-make,.js-search-make').val(),
        model: $('.js-front-page .js-model,.js-search-model').val(),
        part_number: $(this).closest('.js-part,.js-product-detail').data('partnumber').toString(),
        part_type: $(this).closest('.js-part,.js-product-detail').data('parttypename'),
        manufacturer: $(this).closest('.js-part,.js-product-detail').data('manufacturer'),
        description: $(this).closest('.js-part,.js-product-detail').data('description'),
        default_price: $(this).closest('.js-part,.js-product-detail').find('.js-container-price-default .js-container-price').html(),
        image_part: $(this).closest('.js-part,.js-product-detail').find('.image-gallery img').attr('src'),
        logo_url: $(this).closest('.js-part,.js-product-detail').find('.brand-logo img, .container-logo img').attr('src'),
        linecode: $(this).closest('.js-part,.js-product-detail').data('linecode'),
      }
      history.pushState(data, "Where to buy", window.location.href);
      loadWhereToBuy(data);
    })

    window.addEventListener('popstate', function(e) {
      if(ctaLayer!=undefined) ctaLayer.setMap(null);
      jQuery('.js-modal--content').removeClass('zoned');
      if(e.state == null){
        outloadWhereToBuy();
      }
      else if(e.state.action == 'loadWhereToBuy'){

        if(!$('.map-store-locator-popup').length){
          loadWhereToBuy(e.state);
          return;
        }
        //this.console.log(e.state)
        //this.console.log('Rebuild Where to buy!');
        jQuery('.js-modal--content').html('<div class="js-part-info part-info container p0">'+getPartInfoText(e.state['part_number'],e.state['manufacturer'],e.state['part_type'],e.state['image_part'],e.state,true)+'</div>');
        if(infowindow!= undefined) infowindow.close();
        $('.map-store-locator-popup>.js-close').removeClass('fa-arrow-left').addClass('fa-close');
        $('.js-map-stores-holder').html('');
        /*for(k in marker_array_yellow){
          var marker = marker_array_yellow[k];
          var store = null;
          for(s in stores){
            if(stores[s].id = marker.id){
              store = stores[s];
              break;
            }
          }
          appendStoreMap(marker,store, marker.price);
        }*/


        jQuery.ajax({
          //url: '/wp-content/themes/netcom/inc/store-ids.php', 
          url: '/ajax/loadStores', 
          type: 'POST', 
          data: {
            manufacturer : e.state.manufacturer,
            part_type : e.state.part_type,
            part_number : e.state.part_number
          }, 
          dataType:'json',
          success: function(data) {
            for (var i = 0; i < stores.length; i++) {
              var store = stores[i];
              var price = 0;
              if (data[store.id] != undefined ) {
                price = data[store.id];
              }
              if(price){
                price.price = parseFloat(price.price);
                price.pricediscount = parseFloat(price.pricediscount);
                if(price.pricediscount >= price.price) price.pricediscount = 0;
                price.deposit = parseFloat(price.deposit)?parseFloat(price.deposit):0;
                price.tax = parseFloat(price.tax)?parseFloat(price.tax):0;
                price.stockleft = parseFloat(price.stockleft);
                for(k in marker_array_yellow){
                  var marker = marker_array_yellow[k]
                  if(marker.id == store.id){
                    appendStoreMap(marker,store, data[store.id]);
                  }
                }
              }
            }
          }
        })
      }
      else if(e.state.action == 'loadStoreData'){
        window.preventPushState = true;
        markers = marker_array_yellow.concat(marker_array_gray);
        for(index in markers){
          if(markers[index].id == e.state.store_id){
            google.maps.event.trigger( markers[index], 'click' );
            break;
          }
        }
        window.preventPushState = undefined;
      }
    });
    


    jQuery('.js-modal--store-info').on('click', '.js-btn-addtocart', function(){
        var url = jQuery('#addtocart').val();
        var year = jQuery('#year').val();
        var make = jQuery('#make').val();
        var model = jQuery('#model').val();
        var quantityControl = jQuery('.js-txt-quantity');
        var manufacturer = jQuery('#manufacturer').val();
        var description = jQuery('#description').val();
        var parttype = jQuery('#parttype').val();
        var productCode = jQuery('#partnumber').val();
        var lineCode = jQuery('#linecode').val();
        var storeId = jQuery(this).data('store-id');
        var sid = jQuery('#sid').val();
        var unitPrice = jQuery(this).data('unitprice');
        var tax = jQuery(this).data('tax');
        var deposit = jQuery(this).data('deposit');
        var identifier = jQuery(this).data('ottoIdentifier');
        var buttonClicked = jQuery(this);
        
        quantityControl.removeClass('error');
        if (quantityControl.val() == 0) {
            quantityControl.addClass('error');
            return;
        }
        
        var successMsg = jQuery('.js-addtocard-msg.js-msg-success');
        var errorMsg = jQuery('.js-addtocard-msg.js-msg-success');
        var loading = buttonClicked.find('.js-loading');
        var text = buttonClicked.find('.js-text');
        
        successMsg.css('display', 'none');
        errorMsg.css('display', 'none');
        loading.removeAttr('style');
        text.css('display', 'none');
                    
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                quantity : quantityControl.val(),
                productcode : productCode,
                linecode : lineCode,
                storeid : storeId,
                sid : sid,
                year : year,
                make : make,
                model : model,
                manufacturer : manufacturer,
                description : description,
                parttype : parttype,
                unitprice : unitPrice,
                tax : tax,
                deposit : deposit,
                ottoIdentifier: identifier
            },
            success: function(data){
                if (data.error) {
                    successMsg.css('display', 'none');
                    errorMsg.removeAttr('style');
                    
                }  else if (data.count > 0) {
                    buttonClicked.css('background', '#313236');
                    successMsg.removeAttr('style');
                    jQuery('.js-menu-cart-count').html('&nbsp;(' + data.count + ')');
                    jQuery('.js-container-content-step-4').parent().removeClass('inactive');
                    loading.css('display', 'none');
                    text.removeAttr('style');
                    
                }
            }
        });
    });

    $(document).on('click', '.js-location-data',function(){
      var dataHold = $(this).find('.js-row-location')

      var data = {
        name: $(this).parent().hasClass('js--geo-sources')?'':dataHold.data('name'),
        parts:[],
      }

      //Check if store available. Not required
      if($('.js-step3').length){
        $('.js-part').each(function(){
          if($(this).data('stores'))
          data.parts.push({
            'manufacturer':$(this).data('manufacturer'),
            'number': $(this).data('partnumber'),
            'parttype': $(this).data('parttype'),
            'store_ids': $(this).data('stores').toString().split(',')
          });
        });

        $('.js-part .js-picto-delovery').removeClass('active');
        $('.js-part').removeClass('in-territory');
      }

      $.ajax({
        url:'/ajax/setlocation',
        method:'post',
        data:data,
        dataType:'json',
        success:function(rsp){
          //if step 3
          if($('.js-step3').length){
            if(rsp.data.parts)
            rsp.data.parts.forEach(function(el){
              $('.js-part[data-manufacturer="'+el.manufacturer+'"][data-partnumber="'+el.number+'"]').addClass('in-territory');
              $('.js-part[data-manufacturer="'+el.manufacturer+'"][data-partnumber="'+el.number+'"] .js-picto-delovery').addClass('active');
            });
            FilterStoreDeliveryOption.recalibrate();
          }
          if($('.container-loading-interface').length){
            $('.js--loading').removeClass('hide');
            window.location.reload()
          }
          if($('.map-store-locator-popup').length){
            setMarkersVisibility(true);
          }
        }
      });
      $('.js--where-to-buy').html($('.js--where-to-buy').data('replace')+ dataHold.data('name'));
      $('.js--get-address').html(dataHold.data('formated-address'));
      $('.js--change-location span').html($('.js--change-location span').data('replace')+ dataHold.data('name'));
      $('.js-segment .js--change-location').html('<i class="fa fa-map-marker"></i> '+ dataHold.data('name'));
      $('.js-container-partial--change-location .fa-map-marker').removeClass('color--primary');
      dataHold.find('.fa-map-marker').addClass('color--primary');
      if($("#pac-input").length){
        $("#pac-input").val(dataHold.data('name'));
        $("#pac-input")[0].dataset.lat = dataHold.data('lat');
        $("#pac-input")[0].dataset.lng = dataHold.data('lng');
      }
      if(typeof  map != 'undefined'){
        $("#slider").slider('value', 8 +Math.ceil((( parseInt(dataHold.data('distance'))-10)/90)*4));
        if(circle!=undefined)
          circle.setMap(null);
        var myLatLng2 = new google.maps.LatLng(dataHold.data('lat'), dataHold.data('lng'))
        circle = new google.maps.Circle({
            strokeColor: color_primary != ""?color_primary:'#F28D00',
            strokeOpacity: 0.25,
            strokeWeight: 2,
            fillColor: color_primary != ""?color_primary:'#F28D00',
            fillOpacity: 0.1,
            map: map,
            center: myLatLng2,
            radius:  1000 * parseInt(dataHold.data('distance'))
        })

        if(mii!=undefined) mii.setMap(null);
        mii = new google.maps.Marker({
          position: myLatLng2,
          map: map,
          icon: '/dist/images/map-marker-mii.png',
          id: mii,
          zIndex:1000,
        });

        if($('.js-container-store').length){
          $('.js-container-store').each(function(){
            distance = distanceBetween(myLatLng2.lat(),myLatLng2.lng(),parseFloat(this.dataset['lat']),parseFloat(this.dataset['lng']));
            distanceSTR = Math.round(distance) + ' km'
            if(distance<1){
              distanceSTR = Math.round(distance*1000) + ' m'
            }
            this.dataset['distance'] = distance;
            $(this).find('.js-distance').html(distanceSTR);
          });
          $('.js-container-sort-store select').trigger('change');
        }
        

        /*mii = new MarkerWithLabel({
          position: homeLatLng,
          map: map,
          draggable: true,
          raiseOnDrag: true,
          labelContent: "ABCD",
          labelAnchor: new google.maps.Point(15, 65),
          labelClass: "labels", // the CSS class for the label
          labelInBackground: false,
          icon: pinSymbol('red')
        });*/
        map.setCenter( myLatLng2 );
      }

      $('.js-close-change-location').trigger('click');
      $('.js-container-range input[type=range]').trigger('change');
    });

    $(document).on('click','.js-removeLocation',function(){
      $.ajax({
        url:$(this).data('url'),
        method:'post',
        data:{
          name:$(this).parent().find('.js-row-location').data('name'),
        },
      });
      $(this).parent().remove()
    });

    $('form.js--add-a-location').on('submit',function(e){
      e.preventDefault();
    });

    /*$('form.js--add-a-location input[type=range]')[0].addListener("change", function() {
      $('.output').val(this.value +"km" );
    })*/
    $('form.js--add-a-location input[type=range]').on("input", function() {
      $('form.js--add-a-location .output').val(this.value +"km" );
      }).trigger("change");

    

    $('form.js--add-a-location').on('click','.js--btn-change',function(e){
        e.preventDefault();
        if( $('form.js--add-a-location input[name="new_location[name]"]').val() &&
          $('form.js--add-a-location input[name="new_location[coord][long]"]').val() &&
          $('form.js--add-a-location input[name="new_location[coord][lat]"]').val())
        {
          /*console.log($('.js-custom-location').val());
          console.log($('form.js--add-a-location input[name="new_location[name]"]').val());

          return;*/
            addNewLocation(
              {long:$('form.js--add-a-location input[name="new_location[coord][long]"]').val(),lat: $('form.js--add-a-location input[name="new_location[coord][lat]"]').val()},
              $('.js-custom-location').val(),
              $('form.js--add-a-location input[name="new_location[name]"]').val(),
              $('form.js--add-a-location input[name="new_location[max_distance]"]').val(),
              true
            );
        }
    })

    $('form.js--add-a-location').on('click','.js--btn-add',function(e){
      e.preventDefault();
      if( $('form.js--add-a-location input[name="new_location[name]"]').val() &&
        $('form.js--add-a-location input[name="new_location[coord][long]"]').val() &&
        $('form.js--add-a-location input[name="new_location[coord][lat]"]').val())
      {
          form = $('<form class="container-form">Fill in with the name of location. <input class="full" type="text" name="new_location["short_name"]" placeholder="Ex: Home, Work, City, ect" /></form>')
          new popupForm(form[0],'Save',{success:function(e){
            if(form.find('input').val()){
              addNewLocation(
                {long:$('form.js--add-a-location input[name="new_location[coord][long]"]').val(),lat: $('form.js--add-a-location input[name="new_location[coord][lat]"]').val()},
                //$('form.js--add-a-location input[name="new_location[name]"]').val(),
                $('.js-custom-location').val(),
                form.find('input').val(),
                $('form.js--add-a-location input[name="new_location[max_distance]"]').val()
              );
              $('input.js-custom-location').val("");
              $('form.js--add-a-location input[name="new_location[name]"]').val("");
              $('form.js--add-a-location input[name="new_location[coord][long]"]').val("");
              $('form.js--add-a-location input[name="new_location[coord][lat]"]').val("");
              $('form.js--add-a-location input[name="new_location[max_distance]"]').val(25).trigger('input').prop("disabled",true);
              this.close();
              this.die();
            }
          }})
      }
    });

    if(history.state.action == 'loadWhereToBuy'){

      if(!$('.map-store-locator-popup').length){
        loadWhereToBuy(history.state);
        return;
      }
    }
    
});



function loadWhereToBuy(data){
  window.logo_url = data['logo_url'];
  window.label = "";
  window.page_type =  1;
  window.image_part =  data['image_part'];

  $('html').css({'overflow' : 'hidden'});
  $('body').append(
    '<div class="tpl--store-locator map-store-locator-popup">'+
      '<div id="map-store-locator" class="map"></div>'+
      '<div id="map-store-information" class="modal--store-info"><div class="container-scrollable">'+
        '<div class="js-modal--content"><div class="js-part-info part-info container p0">'+getPartInfoText(data['part_number'],data['manufacturer'],data['part_type'],data['image_part'],data,true)+'</div></div>'+      
        '<div class="js-map-stores-holder"></div>'+
        '<div class="no-result hide"><img src="/dist/images/no_result.png" /><div class="title"> '+__('Whoops!')+'</div><p>'+__('Try changing location or expanding your shopping area.')+'</p></div>'+
        '</div></div>'+
      '<button class="close-map js-close-map color--primary"></button>'+
    '</div>'
    );

    /*if(!Tools.IsMobile()){
      $('.js-toolbar.js-fixed-element').addClass('fixed-element');
    }*/
    stickyThing.fixed();
    $(window).on('resize',resizeWhereToBuy);
    resizeWhereToBuy();

    $('.js--parts_list').addClass('hide');
    $('.js--map_list').removeClass('hide');
    $( ".map-store-locator-popup .js-close-map, button.js--map_list" ).on('click', function(){
      history.pushState(null, '', window.location.href);
      outloadWhereToBuy();
      $( ".map-store-locator-popup .js-close-map, button.js--map_list" ).off();
    })

    $( ".map-store-locator-popup" ).on('click', '.js-btn-addtocart', function(){
      var url = jQuery('#addtocart').val();
      var buttonClicked = jQuery(this);
      var quantityControl = buttonClicked.parent().find('.js-txt-quantity');
      var post ={
          quantity : quantityControl.val(),
          productcode : data.part_number,
          linecode : data.linecode,
          storeid : jQuery(this).data('storeId'),
          sid : jQuery('#sid').val(),
          year : data.year,
          make : data.make,
          model : data.model,
          manufacturer : data.manufacturer,
          description : data.description,
          parttype : data.part_type,
          unitprice : jQuery(this).data('unitprice'),
          tax : jQuery(this).data('tax'),
          deposit : jQuery(this).data('deposit'),
          ottoIdentifier : jQuery(this).data('ottoIdentifier'),
      }
      
      quantityControl.removeClass('error');
      if (quantityControl.val() == 0) {
          quantityControl.addClass('error');
          return;
      }
      
      var successMsg = jQuery('.js-addtocard-msg.js-msg-success');
      /*var errorMsg = jQuery('.js-addtocard-msg.js-msg-success');
      var loading = buttonClicked.find('.js-loading');
      var text = buttonClicked.find('.js-text');*/
      
      successMsg.css('display', 'none');
      //errorMsg.css('display', 'none');
      //loading.removeAttr('style');
      //text.css('display', 'none');
      buttonClicked.find('.fa').toggleClass('fa-shopping-cart fa-cog fa-spin');
                  
      $.ajax({
          url:url,
          dataType: 'JSON',
          type: 'POST',
          data: post,
          success: function(data){
              if (data.error) {
                  successMsg.css('display', 'none');
              }  else if (data.count > 0) {
                  buttonClicked.css('background', '#313236');
                  successMsg.removeAttr('style');
                  jQuery('.js-menu-cart-count').html('&nbsp;(' + data.count + ')');
                  jQuery('.js-container-content-step-4').parent().removeClass('inactive');
                  buttonClicked.find('.fa').toggleClass('fa-shopping-cart fa-cog fa-spin');
              }
          }
      });
  });

    initMapStoreLocator(data['part_number'], data['manufacturer'], data['part_type'])
    $( ".map-store-locator-popup" ).animate({
      left: "0%",
    }, 500 );
}
function resizeWhereToBuy(){
  sh = stickyThing.height();
  $('.map-store-locator-popup').css({
    top: sh,
    height: window.innerHeight-sh
  })
  /*if(!Tools.IsMobile()){
    $('.map-store-locator-popup').css({
      top:$('.js-toolbar.js-fixed-element').outerHeight(),
      height: window.innerHeight-$('.js-toolbar.js-fixed-element').outerHeight()
    })
  }
  else{
    $('.map-store-locator-popup').css({
      top:0,
      height: window.innerHeight
    })
  }*/
}

function outloadWhereToBuy(data){
  $('.js-modal--store-info').hide();
  //if(infowindow!= undefined) infowindow.close();
  if(ctaLayer!=undefined) ctaLayer.setMap(null);
  $( ".map-store-locator-popup" ).off().animate({
    left: "100%",
  }, 500,  function(){
    $(window).off('resize',resizeWhereToBuy);
    //$('.js-toolbar.js-fixed-element').removeClass('fixed-element');
    stickyThing.sticky();
    $('html').css({'overflow' : ''});
    $(window).trigger('scroll');
    $(this).remove();
    $('.js--parts_list').removeClass('hide');
    $('.js--map_list').addClass('hide');
  } );
  
}

function loadStores(data,update){
  return new Promise(function(resolve, reject) {
    if(window.stores == undefined || update === true)
      jQuery.ajax({
        url: '/wp-content/themes/netcom/inc/store-ids.php', 
        type: 'POST',
        data:data,
        dataType: 'json',
        success: function(data) {
          window.stores = data;
          resolve(data);
        },
        error: function(e){
          reject('An error occured');
        }
      })
    else resolve(window.stores);
  });
}

function appendStoreMap(marker, store, price ){

  var input = document.getElementById('pac-input');
  var max = $('.js-container-range input[type=range]').val()?$('.js-container-range input[type=range]').val():25;
  var distance = 'Unknow';
  var distanceSTR = 'Unknow';
  if(input.dataset.lat && input.dataset.lng){
    distance = distanceBetween(input.dataset.lat,input.dataset.lng,marker.position.lat(),marker.position.lng());
    distanceSTR = Math.round(distance) + ' km'
    if(distance<1){
      distanceSTR = Math.round(distance*1000) + ' m'
    }
  }

  /*place.opening_hours.periods.forEach(function(el){
    if(el.open.day == today) today_text = '<span class="hide-open">: '+el.open.hours+'h'+(el.open.minutes<10?'0'+el.open.minutes:el.open.minutes)+' – '+el.close.hours+'h'+(el.close.minutes<10?'0'+el.close.minutes:el.close.minutes)+'</span> ';
  })*/

  var html = '<div class="container container-store js-part js-container-store" data-store-id="'+store.id+'" data-price="'+(price.pricediscount?price.pricediscount:price.price).formatMoney()+'" data-lat="'+store.lat+'" data-lng="'+store.long+'" data-distance="'+distance+'" data-sortprice="'+(price.pricediscount?price.pricediscount:price.price)+'">'+
    '<div class="col-md-12 js-store-name text-bold color--primary"><a class="js-select-store" href="#" title="'+store.name+' | Google">'+store.name+'</a></div>'+
    '<hr/>'+
    '<div class="col-md-6 store-info">'+
      '<div class="container-store-row "><i class="fa fa-main fa-phone color--primary"></i>'+store.phone+'</div>'+
      '<div class="container-store-row"><i class="fa fa-main fa-map-marker color--primary"></i>'+store.address+'<br>'+store.city+', '+store.province+' '+store.country+'</div>'+
      '<div class="container-store-row color--primary">'+'<i class="fa fa-main fa-arrows-h color--primary"></i><span class="js-distance"><b>'+distanceSTR+'</b></span></div>'+
      '<div class="container-store-row color--primary">'+'<i class="fa-absolute fa fa-clock-o fa-main"></i><span class="js-open-hours"></span></div>'+
      (marker.place_id?'<div class="container-store-row js-g_rating js-select-store">'+'<i class="fa fa-cog fa-spin"></i>'+'</div>':'')+
    '</div>'+
    '<div class="col-md-6 part-info">'+
      '<div class="unit-price color--primary"><span itemprop="priceCurrency" content="CAD">$</span>'+(price.pricediscount?price.pricediscount:price.price).formatMoney()+'</div><br>'+
      (price.pricediscount?('Regular price: $'+price.price.formatMoney()+
      '<div class="save color--primary">You save: $'+(price.price-price.pricediscount).formatMoney()+'</div>'):'')+
      (price.tax?'<div class="tax text-right"><span class="fa fa-leaf"></span>Env. tax: +$'+price.tax.formatMoney()+'</div>':'')+
      (price.deposit?'<div class="deposit text-right"><span class="fa fa-recycle"></span>Deposit: +$'+price.deposit.formatMoney()+'</div>':'');
      //if(parseInt(price.is_user_logged_in)){
      html+='<div class="quantity-control js--fake_input_number"><label>Qté: </label><input type="number" class="qty-num js-txt-quantity" size="3" min="1" value="1"><div class="js--fake_input_control"><i class="more"></i><i class="less"></i></div></div>'+
      '<button class="js-btn-addtocart btn addtocart-button" data-store-id="'+store.id+'" data-unitprice="'+(price.pricediscount?price.pricediscount:price.price)+'" data-tax="'+price.tax+'" data-deposit="'+price.deposit+'" data-otto-identifier="'+price.ottoIdentifier+'"><i class="fa fa-shopping-cart"></i></button>';
      //}
      html+='<div class="js-addtocard-msg js-msg-success addtocard-msg" style="display:none">Item added to cart</div>';
     

      html+='<div class="listint-pictos">'+
        '<div class="listint-pictos__block js-picto-instore"><span class="icon-instore listint-pictos__block__picto"></span></div>'+
        '<div class="listint-pictos__block js-picto-delovery'+(store.delivery == true?' active':'') +'"><span class="icon-delivery listint-pictos__block__picto"></span></div>'+
      '</div>';
      html+'</div>';
    html+='</div>';
  html = $(html);
  $('.js-map-stores-holder').append(html);

  if(distance < max)
    html.addClass('zoned').find('.js-picto-instore').addClass('active');
  else if(store.delivery == true) html.addClass('zoned');


  if(typeof store.gmap_id == 'string'){
    var request = {
      placeId: store.gmap_id
    };
    var service = new google.maps.places.PlacesService(map);
    service.getDetails(request, function(place, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        if(place.opening_hours != undefined){
          var today_text = '',
              today = (new Date).getDay();
          place.opening_hours.periods.forEach(function(el){
            if(el.open.day == today) today_text = ': '+el.open.hours+'h'+(el.open.minutes<10?'0'+el.open.minutes:el.open.minutes)+' – '+el.close.hours+'h'+(el.close.minutes<10?'0'+el.close.minutes:el.close.minutes);
          })
          html.find('.js-open-hours').html(__(place.opening_hours.isOpen()?'Open':'Closed')+today_text);
        }
        else{
          html.find('.js-open-hours').parent().remove();
        }
        if(place.rating != undefined){
          html.find('.js-g_rating').html(place.rating.toStars()+' ('+place.user_ratings_total+')');
          /*var rhtml = '';
          for(i in place.reviews){
            var review = place.reviews[i];
            rhtml += '<li class="container-store-review"><img src="'+review.profile_photo_url+'" /><div><q>'+review.text+'</q></div></li>';
          }
          html.find('.container-store-reviews').html(rhtml);*/
        }
        else{
          html.find('.js-g_rating').html('Aucun avis Google');
        }
      }
    });
  }
  else{
    html.find('.js-g_rating').html('');
  }

  $(html).mouseover(function(){
    marker.setOptions({
      labelContent: store.name+'<br><b>$'+(price.pricediscount?price.pricediscount:price.price).formatMoney()+'</b>',
    });
    map.setCenter(marker.getPosition());
  });

  $(html).mouseout(function(){
    marker.setOptions({
      labelContent: '<b>$'+(price.pricediscount?price.pricediscount:price.price).formatMoney()+'</b>',
    })
  });
  $(html).find('.js-select-store').on('click',function(e){
    e.preventDefault();
    marker.setOptions({
      labelContent: '<b>$'+(price.pricediscount?price.pricediscount:price.price).formatMoney()+'</b>',
    })
    google.maps.event.trigger( marker, 'click' );
  })

  jQuery('.js-container-sort-store select').trigger('change');
}

function getPartInfoText(part_number,manufacturer,part_type,image_part,part_info,hide_add_to_cart){
  var part_info_text = "<div class='col-md-12 col-xs-12 p0'>";
  part_info_text += "<h3 class='color--primary'>" + part_number + " | " + decodeURI(manufacturer.split('+').join(' ')) + " " /*+ decodeURI(label.split('+').join(' '))*/ + " " +  decodeURI(part_type.split('+').join(' ')) +   "</h3></div>";
  if (image_part) {
    part_info_text += "<div class='col-md-6 col-xs-6 p0 text-center'><img src='"+ image_part +"'>"; 
  } else {
    part_info_text += "<div class='col-md-6 col-xs-6 p0 text-center'><img src='https://netcomstorage.com/Image/no-image.webp'>";
  }
  
  part_info_text += "</div>";
  part_info_text += "<div class='text-right  col-md-6 col-xs-6 p0'>";

  if(hide_add_to_cart != true){ 
  if (part_info.default_price != undefined ) {
    part_info_text += part_info.default_price;
  }
  else{
    part_info_text += "<div class='unit-price color--primary text-bold'>";
    if (part_info.price_discount > 0) {
      
      if (lang =='fr') {
        part_info_text += part_info.price_discount.toFixed(2) + "$";
        part_info_text += "</div>";
        part_info_text += "<br>Prix régulier: " + part_info.price.toFixed(2) + "$";
        part_info_text += "<br><div class='color--primary'>";
        part_info_text += "Vous sauvez: $" + Number(part_info.price.toFixed(2) - part_info.price_discount.toFixed(2)).toFixed(2) + "$";
      } else {
        part_info_text += "$" + part_info.price_discount.toFixed(2);
        part_info_text += "</div>";
        part_info_text += "<br>Regular price: $" + part_info.price.toFixed(2);
        part_info_text += "<br><div class='color--primary'>";
        part_info_text += "You save: $" + Number(part_info.price.toFixed(2) - part_info.price_discount.toFixed(2)).toFixed(2); 

      }
      
    } else {

      if (lang =='fr') {
        part_info_text += part_info.price.toFixed(2) + "$";
      } else {
        part_info_text += "$" + part_info.price.toFixed(2);
      }

    }

    part_info_text += "</div>";
    

  if (part_info.tax > 0) {
        part_info_text += '<div class="tax"><span class="fa fa-leaf"></span> ';
      if (lang =='fr') {
        part_info_text += "Frais env.: +" + part_info.tax.toFixed(2) + "$"; 
      } else {
        part_info_text += "Env. tax: + $" + part_info.tax.toFixed(2)+ "</div>";
      }
  }

    if (part_info.deposit > 0) {
    part_info_text += '<div class="deposit"><span class="fa fa-recycle"></span> ';
      if (lang =='fr') {
        part_info_text += part_info.deposit.toFixed(2) + "$<br>";
      } else {
        part_info_text += "$" + part_info.deposit.toFixed(2) + "</div>";
      }
    }
  }

//start add to cart

part_info_text += '<div class="quantity-control js--fake_input_number">'; 
    if (lang =='fr') {
      part_info_text += '<label>Qty: </label>';
    } else {
      part_info_text += '<label>Qté: </label>';
    }
part_info_text += '<input type="number" class="qty-num js-txt-quantity" size="3" min="1" value="1">';
part_info_text += '<div class="js--fake_input_control"><i class="more"></i><i class="less"></i></div>';
part_info_text += '</div>';
part_info_text += '<button class="js-btn-addtocart btn addtocart-button" data-store-id="' + store_id + '" data-unitprice="' + part_info.price + '" data-tax="' + part_info.tax + '" data-deposit="' + part_info.deposit + '" data-otto-identifier="'+part_info.ottoIdentifier+'">';
  if (page_type == 1 || page_type == 2 || page_type == 3) {
      part_info_text += '<i class="fa fa-shopping-cart"></i>';
  } else {
      part_info_text += '<i class="fa fa-star"></i>';
  }
part_info_text += '</button>';
part_info_text += '<div class="js-addtocard-msg js-msg-success addtocard-msg" style="display:none">';
if (lang =='fr') {
      if (page_type == 1 || page_type == 2 || page_type == 3) {
        part_info_text += 'Item ajouté au panier';
      } else {
        part_info_text += 'Item ajouté à vos favoris';
      }
    } else {
        if (page_type == 1 || page_type == 2 || page_type == 3) {
        part_info_text += 'Item added to cart';
      } else {
        part_info_text += 'Item added to wishlist';
      }
    }
part_info_text += '</div>';
  }
//end add to cart


part_info_text += "<div><img src='"+ logo_url +"' class='fifty mt2'>" +(part_info.delivery?"<div class=\"listint-pictos__block\"><span class=\"icon-delivery listint-pictos__block__picto \"></span><p class=\"listint-pictos__block__text\">"+(lang =='fr'?'Livré à domicile':'Delivery at home')+"</p></div>":'')+ "</div>";
part_info_text += '</div>'; 
return part_info_text; 
}

function setMarkersVisibility(update){
  if(typeof update != 'boolean') update = false;
  if(!$("#map-store-locator").length) return;

  var markers_array = marker_array_yellow.concat(marker_array_gray),
      condition = {
        availableOnly : $('.js-filter-map-available').is(':checked'),
        delivery : $('.js-filter-delivery').is(':checked'),
        pickup: $('.js-filter-pikup').is(':checked'),
      },
      load = {};
    
  if(mii != undefined){
    load.lat = mii.position.lat();
    load.lng = mii.position.lng()
  }

  loadStores(load,update).then(function(stores_data){
    markers_array.forEach(function(marker){
      var pos = {
            lat: marker.position.lat(),
            lng: marker.position.lng()
          },
          input = document.getElementById('pac-input'),
          distance = distanceBetween(parseFloat(input.dataset.lat), parseFloat(input.dataset.lng),marker.position.lat(), marker.position.lng()),
          limit = parseInt(document.querySelector('.js-container-range input[type=range]').value),
          store = undefined
          visible = false;

      for(var i=0; i<stores_data.length; i++ ){
        if(stores_data[i].id == marker.id){
         store = stores_data[i];
        break;
        }
      }
      $a = false;
      $b = false;
      $c = false;
      if((!condition.delivery && !condition.pickup) || (condition.pickup && distance<=limit))
        $a = true;
      if((!condition.delivery && !condition.pickup) || (condition.delivery && store!=undefined && store.delivery==true ))
        $b = true;
      if(!condition.availableOnly || marker.icon.url != '/dist/images/map-marker-gray.png')
        $c = true;
      visible = $c && ($a || $b);
      marker.setVisible(visible);
      if(visible){
        $('.js-container-store[data-store-id="'+marker.id+'"]').removeClass('hide');
        //if(distance<= limit) marker.setOpacity(1);
        //else marker.setOpacity(0.75);
      }
      else{
        $('.js-container-store[data-store-id="'+marker.id+'"]').addClass('hide');
      }
    });
    
    if($('.js-map-stores-holder .js-part').length == $('.js-map-stores-holder .js-part.hide').length ) $('.tpl--store-locator .no-result').removeClass('hide');
    else $('.tpl--store-locator .no-result').addClass('hide');
    markerClusterYellow.repaint();
    markerClusterGray.repaint();
  });
}








/* MAP DEMO */

function initMapStoreDemo(){
  //var input = document.getElementById('pac-input');

  var _Layer = $('.js--layer-demo');
  _Layer.remove().removeClass('js--layer-demo');
  var _new = 0;
  var _pos = null;
  var _url = $('.js--url-kml').attr("href");

  
  myLatLng2 = new google.maps.LatLng(50.501228, -73.555093);
  var map = new google.maps.Map(document.querySelector('.js-demo-map'), {
  center: myLatLng2,
  zoom: 6,
  gestureHandling: 'greedy', 
  scrollwheel: false,
  zoomControl: true,
  zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
  },
  streetViewControl: true,
  streetViewControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
  },
  mapTypeControl: false,
  });
  map.setOptions({styles: styles});
  var icon = {
      url: '/dist/images/map-marker-yellow.png',
      size: new google.maps.Size(60, 60),
  };
  var markers = [];
  var clusterStyles = [
   {
      textColor: 'black',
      url: '/dist/images/map-marker-yellow.png', 
      height: 72,
      width: 60,
      textSize: 20,
      anchor:[12,-2]

    }
  ];
  var marker_style  = {
    gridSize: 50,
    styles: clusterStyles,
    maxZoom: 15
  };
  var markerCluster = new MarkerClusterer(map, [], marker_style);
  loadStores({}).then(function(data){
    data.forEach(function(store) {
      var marker = new google.maps.Marker({
        position: {lat: Number(store.lat), lng: Number(store.long)},
        map: map,
        icon: icon,
        id: store.id,
        place_id: store.gmap_id,
        title: store.name,
        version: new Date(store.updated_at).getTime(),
      });

      markerCluster.addMarker(marker,true);
    })

  })

  navigator.geolocation.getCurrentPosition(function(pos) {
    map.setCenter(new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude))
  },function(e){});
}