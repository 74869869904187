var NetcomSegment = new function(){
    var segment = {
        'light-vehicles' : [
            'Car',
            'Truck',
            'Van',
        ],
        'heavy-trucks' : [
            'Medium/Heavy Truck'
        ],
        'recreational-vehicles' : [
            'ATV',
            'Offroad Motorcycle',
            'Snowmobile',
            'Utility Vehicle',
            'Scooter'
        ],
        default: [
            'Car',
            'Truck',
            'Van',
            'Medium/Heavy Truck',
            'ATV',
            'Offroad Motorcycle',
            'Snowmobile',
            'Utility Vehicle',
            'Scooter'
        ],
    }

    this.getSegmentById = function(segmentId){
        var val = segment.default
        if(segment[segmentId]) val = segment[segmentId];
        return val.join(',');
    }

    this.getActualSegment = function(){
        var segmentId = $('.js-segment-tab.active').attr('id');
        return this.getSegmentById(segmentId)

    }
}