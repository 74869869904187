//State
var editingStructureName;
var modalBodyState;

var oldAssignedClientIds;

function refreshModalBodyState() {
  modalBodyState = $(
    ".template-container .structure-association-modal-body"
  ).clone();
}

function setOldAssignedClientIDsState() {
  oldAssignedClientIds = getClientIdsFromCheckBoxes($(".structure-clientID-check:checked"));
}
//Handlers
function showPricingStructureAssociationModal(structureName) {
  editingStructureName = structureName;
  refreshModalBodyState();
  setupModalBody();
  new popupForm(modalBodyState[0], "Save", {
    success: pricingStructureModalSaveClickedHandler,
  });
  setOldAssignedClientIDsState();
}

function pricingStructureModalSaveClickedHandler() {
  var assignedClientIds = getClientIdsFromCheckBoxes($(".structure-clientID-check:checked"));
  var associations = associateClientIdsWithStructureName(assignedClientIds, editingStructureName);

  var newUnAssignedClientIDs = getItemsFromArr1ThatAreNotInArr2(oldAssignedClientIds, assignedClientIds, function (item1, item2) {
    return item1 == item2;
  });
  var unassociations = associateClientIdsWithStructureName(newUnAssignedClientIDs, null);

  var updatedAssociation = associations.concat(unassociations);
  if (updatedAssociation !== null && updatedAssociation.length) {
    UpdateStructureForClientsAJAX(updatedAssociation);
    updateStructureForClientsSESSION(updatedAssociation);
  }

  this.close();
  this.die();
}
function structureClientCheckboxChangedHandler() {
  getCheckBoxByClientID($(this).val()).prop("checked", this.checked);
}

//DOM GET
function getCheckBoxesThatMatchesCurrStructureAssociation() {
  var selector = getStructuresByClientIDForCurrentStructure()
    .map(function (x) {
      return getValueSelectorForClientCheckBox(x.clientID);
    })
    .join(",");
  return modalBodyState.find(selector);
}
function getCheckBoxByClientID(clientID) {
  return modalBodyState.find(getValueSelectorForClientCheckBox(clientID));
}
//DAL
function getStructuresByClientID() {
  return getItemFromSession("structures-by-clientID");
}
function updateStructureForClientsSESSION(associations) {
  var newAssociations = concatArraysWithNoDuplicate(
    associations,
    getStructuresByClientID(),
    function (item1, item2) {
      return (
        item1.clientID == item2.clientID ||
        item1.pricingStructure.pricingStructureName ==
          item2.pricingStructure.pricingStructureName
      );
    }
  ).filter(function (association) { return !isNullOrWhiteSpace(association.pricingStructure.pricingStructureName) });
  storeItemInSession("structures-by-clientID", newAssociations);
}
function getStructuresByClientIDForCurrentStructure() {
  return getStructuresByClientID().filter(function (x) {
    return x.pricingStructure.pricingStructureName == editingStructureName;
  });
}

//LOGIC
function associateClientIdsWithStructureName(clientIds, structureName) {
  return clientIds.map(function (clientId) {
    return getPricingStructureClientMatchObject(clientId, structureName);
  });
}

function getClientIdsFromCheckBoxes(checkboxes) {
  var clientIds = checkboxes
    .map(function () {
      return $(this).val();
    })
    .toArray();
  return getUniqueEntrysFromArray(clientIds);
}
function setupModalBody() {
  getCheckBoxesThatMatchesCurrStructureAssociation()
    .attr("checked", true)
    .change();

  modalBodyState.find(".structure-name-container").html(editingStructureName);
  modalBodyState
    .find(".structure-clientID-check")
    .change(structureClientCheckboxChangedHandler);
}

function getValueSelectorForClientCheckBox(value) {
  return ".structure-clientID-check[value='" + value + "']";
}
