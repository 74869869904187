jQuery('document').ready(function(){

    
     jQuery('.breadcrumb').on('mouseover', '.js-container-content-step', function(){
        jQuery(this).siblings('.step-number').addClass('step-number-hover');
     });
      jQuery('.breadcrumb').on('mouseleave', '.js-container-content-step', function(){
        jQuery(this).siblings('.step-number').removeClass('step-number-hover'); 
     });
    
    /*************************************************
    * BRANDS PAGE (listing)
    ************************************************/
    jQuery('.js-brands').on('click', '.js-letter-page', function(){
        if (jQuery(this).hasClass('selected')){
            return false;
        }
        var brands = jQuery('.js-brands');
        var value = jQuery(this).data('value');
        
        if (value == 'all')
        {
            brands.find('.js-brand').removeClass('hide');
            brands.find('.js-letter-separator').removeClass('hide');
        }
        else
        {
            brands.find('.js-brand').addClass('hide');
            brands.find('.js-letter-separator').addClass('hide');
            brands.find('.js-brand[data-category=' + value + '], .js-letter-separator[data-category=' + value + ']').removeClass('hide');
        }
        
        var active = 0;
        brands.find('.js-brand').each(function(){
            if (!jQuery(this).hasClass('hide'))
            {
                active++;
            }
        });
        
        if (active === 0)
        {
            brands.find('.js-no-brands').removeClass('hide');
        }
        else
        {
            brands.find('.js-no-brands').addClass('hide');
        }
        
        brands.find('.js-letter-page').removeClass('selected');
        jQuery(this).addClass('selected');
    });
    /************************************************/
});