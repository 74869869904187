function concatArraysWithNoDuplicate(arr1, arr2, equalityFunction) {
  var tmp = arr2.filter(function (matrix1) {
    return !arr1.some(function (matrix2) {
      return equalityFunction(matrix1, matrix2);
    });
  });
  return arr1.concat(tmp);
}

function innerJoinArrays(arr1, arr2, equalityFunction) {
  return arr1.map(function (item1) {
    var matchingItem2 = getmatchingItem(item1, arr2, equalityFunction);
    return Object.assign(item1, matchingItem2);
  });
}

function getItemsFromArr1ThatAreNotInArr2(arr1, arr2, equalityFunction) {
  return arr1.filter(function (item1) {
    return !arr2.some(function (item2) {
      return equalityFunction(item1, item2)
    })
  })
}

function getUniqueEntrysFromArray(array) {
  return array.filter(function (value, index, self) {
    return self.indexOf(value) === index;
  });
}

//private usage
function getmatchingItem(item1, arr2, equalityFunction) {
  return arr2.find(function (item2) {
    return equalityFunction(item1, item2);
  });
}
