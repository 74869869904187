jQuery(document).ready(function(){

    jQuery(document).on('click','.js-toggle-menu', function(e) {
        e.preventDefault();
        jQuery(this).toggleClass('is-open');
        jQuery('.js-nav__container').toggleClass('is-open').css({opacity: '1'});
        jQuery('.js-container-partial--my-garage, .js-container-partial--subscription, .js-container-partial--help').css('display', 'none');
        toggleOverlay();
    });
    
    jQuery(document).on('click','.js--change-location, .js-close-change-location', function(e) {
        e.preventDefault();
            jQuery('.js-container-partial--subscription, .js-container-partial--help').fadeOut(); 
        if(window.auto_redirect != undefined) window.clearTimeout(window.auto_redirect);
        if($(this).hasClass('js-close-change-location')){
            jQuery('.js-container-partial--change-location').fadeOut(); 
            jQuery('.js-mobile-overlay').fadeOut(); 
        }
        else if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var my_garage_offset;

            my_garage_offset = jQuery(".js--change-location").offset();
            var left = my_garage_offset.left;
            if(left+jQuery('.js-container-partial--change-location').outerWidth()>window.innerWidth)
            left = window.innerWidth-jQuery('.js-container-partial--change-location').outerWidth();
            jQuery('.js-container-partial--change-location').css( { left:left });

            jQuery('.js-container-partial--change-location').fadeOut(); 
            jQuery('.js-mobile-overlay').fadeOut(); 
            
            if(jQuery('.js-container-partial--change-location').is(":hidden")){
                jQuery('.js-container-partial--change-location').stop().fadeIn(); 
                jQuery('.js-mobile-overlay').stop().fadeIn(); 
            }
            else{
                
            }
            //jQuery('.js-mobile-overlay').fadeIn(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--change-location').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });
    if($.urlParam('q') && $('#'+$.urlParam('q')).hasClass('js--change-location')){
        $('#'+$.urlParam('q')).trigger('click')
    }

    jQuery(document).on('click','.js-link-my-garage,.js-link-my-garage-only, .js-close-my-garage', function(e) {
            e.preventDefault();
            jQuery('.js-container-partial--subscription, .js-container-partial--help').fadeOut(); 
        if($(this).hasClass('js-close-my-garage')){
            jQuery('.js-container-partial--my-garage, .js-container-partial--my-profile').fadeOut(); 
        }
        else if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var my_garage_offset;

            if($(this).hasClass('js-link-my-garage-only'))
                my_garage_offset = jQuery(".js-link-my-garage-only").offset();
            else
                my_garage_offset = jQuery(".js-link-my-garage").offset();
            var left = my_garage_offset.left;
            if(left+jQuery('.js-container-partial--my-garage').outerWidth()>window.innerWidth-60)
            left = window.innerWidth-60-jQuery('.js-container-partial--my-garage').outerWidth();
            if(left < 0) left = 0

            if(!$('.js-link-my-garage-only').length || $(this).hasClass('js-link-my-garage-only'))
                jQuery('.js-container-partial--my-garage').css( { left:left }); 
            my_garage_width = jQuery('.js-container-partial--my-garage').outerWidth();
            my_garage_height = jQuery('.js-container-partial--my-garage').outerHeight();
            if (!my_garage_width)
                my_garage_width = 0;
            if($('.js-link-my-garage-only').length && jQuery(".js-link-my-garage").length)
                jQuery('.js-container-partial--my-profile').css( { left: jQuery(".js-link-my-garage").offset().left, height: my_garage_height });
            else
                jQuery('.js-container-partial--my-profile').css( { left: left - my_garage_width, height: my_garage_height });
            
            if($(this).hasClass('js-link-my-garage-only')){
                //console.log('my-garage')
                jQuery('.js-container-partial--my-garage').fadeToggle(); 
                jQuery('.js-container-partial--my-profile').fadeOut(); 
            }
            else if($('.js-link-my-garage-only').length){
                jQuery('.js-container-partial--my-profile').fadeToggle(); 
                jQuery('.js-container-partial--my-garage').fadeOut(); 
            }
            else
                jQuery('.js-container-partial--my-garage, .js-container-partial--my-profile').fadeToggle(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--my-garage').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });

      jQuery(document).on('click','.js-link-help, .js-close-help', function(e) {
            e.preventDefault();
            jQuery('.js-container-partial--subscription,  .js-container-partial--my-garage, .js-container-partial--my-profile').fadeOut(); 
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            jQuery('.js-container-partial--help').css( 'right', '50px');  
            jQuery('.js-container-partial--help').fadeToggle(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--help').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });

    $( window ).resize(function() {
        jQuery('.js-toggle-menu').removeClass('is-open'); 
        jQuery('.js-container-partial--subscription,  .js-container-partial--my-garage, .js-container-partial--my-profile, .js-container-partial--help').hide(); 
        jQuery('.js-container-partial--subscription,  .js-container-partial--my-garage, .js-container-partial--my-profile, .js-container-partial--help').css({display: '', right: '', left: '', opacity: ''})
        jQuery('.nav__container').css({opacity:''}).removeClass('is-open'); ;
        toggleOverlay();
    })

     jQuery(document).on('click','.js-link-speed-entry, .js-close-speed-entry', function(e) {
        event.preventDefault();
        jQuery('.js-container-partial--subscription, .js-container-partial--my-garage, .js-container-partial--help, .js-container-partial--my-profile').fadeOut();
        //jQuery('.js-container-speed-entry').fadeToggle();
      });

  

    jQuery(document).scroll(function(e){
        if(jQuery('.js-container-partial--change-location').css('display') == 'block' && !Tools.IsMobile()){
            jQuery('.js-container-partial--change-location').fadeOut();
        }

        if(jQuery('.js-container-partial--my-garage').css('display') == 'block' && !Tools.IsMobile()){
            jQuery('.js-container-partial--my-garage, .js-container-partial--my-profile').fadeOut();
        }
          if(jQuery('.js-container-partial--help').css('display') == 'block' ){
            jQuery('.js-container-partial--help').fadeOut();
        }
        if(jQuery('.js-nav__container').hasClass('is-open') && Tools.IsMobile()){
            if(jQuery('.js-nav__container').height()>=jQuery('.js-nav__container>nav').height()){
                jQuery('.js-nav__container, .js-toggle-menu').removeClass('is-open');
                jQuery('.js-mobile-overlay').fadeOut();
            }
        }
         if(jQuery('.js-container-partial--subscription').css('display') == 'block' ){
            jQuery('.js-container-partial--subscription').fadeOut();
        }
            
    });

    jQuery(document).on('click','.js-button-back-menu',function() {
        container_parent = jQuery(this).parent();
       jQuery('.nav__container').animate({opacity:'1', left: '0'}, { duration: 200, queue: false }); 
       container_parent .animate({right:'-100%'},  function(){
       		container_parent .css({display: 'none'});
       }); 
    });  

    jQuery(document).on('click','.js-link-subscription, .js-close-subscription', function(e) {
        e.preventDefault();
        jQuery('.js-container-partial--my-garage, .js-container-partial--my-profile, .js-container-partial--help').fadeOut();
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var offset_subscription = jQuery(".js-link-subscription").offset();
            var width = jQuery('.js-container-partial--subscription').outerWidth();
            if(offset_subscription.left -40 + width > document.documentElement.clientWidth-50)
                jQuery('.js-container-partial--subscription').css( { right : 50 }); 
            else
                jQuery('.js-container-partial--subscription').css( { left: offset_subscription.left - 40 }); 
            jQuery('.js-container-partial--subscription').fadeToggle();
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--subscription').css({display: 'inline-block', opacity: '1'}).animate({ right: '0%'}, { duration: 200, queue: false }); 
        }

    });


    jQuery('body').click(function(event) {
        if (!Tools.IsMobile()) {
            var target = jQuery(event.target);
             if (!target.parents(".js-container-partial--subscription").length && !target.parents(".js-nav__container").length && !target.parents(".js-container-partial--help").length  && !target.parents(".js-container-speed-entry").length && !target.parents(".js-container-partial--my-garage").length && !target.parents(".js-container-partial--change-location").length) {
                   jQuery('.js-container-partial--change-location, .js-container-partial--my-garage, .js-container-partial--my-profile, .js-container-partial--help,  .js-container-partial--subscription').fadeOut();
            }
        }
    });


    //btn impersonate
    jQuery(document).on('click','.js--impersonate', function(e) {
        e.preventDefault();
        var url = $(this).data('action');
        if(!url) return;

        var form = $('<form class="container-form p1" action="'+url+'" method="GET" novalidate="novalidate" style="">'+
            '<div class="form-group" method="post">'+
                '<label class="c--popup-title text-left" for="c--popup-title registration_corporative_name">'+ __('Select a customer to impersonate') + '</label>'+
                // '<p class="color--secondary my1">'+__('Type the first digits of the business name or provide the full customer code or phone number.')+'</p>'+
                '<div class="selectbox selectbox__model">'+
                    '<select data-default-value="" class="model js-select2-ajax" data-url="'+url+'" name="set"><option value="'+$(this).data('actual-customer-id')+'">'+__('Search')+'</option></select>'+'</div>'+
                '<div class="help-block"></div>'+
            '</div>'+
        '</form>')[0];

        var popup = new popupForm(form,__('Impersonate'),{
            autoclose: false,
            language: window.Translator.getLang(),
            beforeOpen:function(){
                $(this).find('.js-select2-ajax').select2({
                    //dropdownParent: $(this),
                    ajax: {
                        dataType: 'json',
                        url: function(param){
                            return this.data('url');
                        },
                        processResults: function (data) {
                            return data;
                        }
                    }
                });
            },
            success:function(e){
                //console.log($(form));
                getCustomerID = $(form).find('.js-select2-ajax').val();
                if(getCustomerID != '' && getCustomerID != '__RESET__'){
                    getCustomerName = ($(form).find('.js-select2-ajax option[value="'+getCustomerID+'"]').html());
                }
                else{
                    getCustomerID = '';
                    getCustomerName = '';
                }
                if (! sessionStorage.tabID ) {
                    sessionStorage.tabID = ('TAB' + Math.round(Math.random() * 1000000000000));
                }
                document.cookie = "customerID" + sessionStorage.tabID + "=" + getCustomerID + "; path=/";
                document.cookie = "customerName" + sessionStorage.tabID + "=" + getCustomerName + "; path=/";
                popup.close();
                window.location.reload()
            },
            inputs:{
                default:{
                    error: function(e){
                        $(e.target).parent().addClass("error").children(".help-block").html(__(e.error));
                    },
                    success: function(e){
                        $(e.target).parent().removeClass("error").children(".help-block").empty();
                    }
                }
            }
        });
        //return form;
    });


    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = jQuery('.js-site-header').outerHeight();

    jQuery(window).scroll(function(event){
     
        didScroll = true;
    });
    
    setInterval(function() {

        if (didScroll && jQuery(window).width() < 768 && Tools.IsMobile()) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);


    function hasScrolled() {
        var st = jQuery(this).scrollTop();
        
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;
        
        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            jQuery('.js-site-header').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + jQuery(window).height() < jQuery(document).height()) {
                jQuery('.js-site-header').removeClass('nav-up').addClass('nav-down');
            }
        }
        
        lastScrollTop = st;
    }


    function toggleOverlay() { 
        if (jQuery('.js-toggle-menu').hasClass('is-open')) {
            jQuery('.js-mobile-overlay').fadeIn();
        } else {
            jQuery('.js-mobile-overlay').fadeOut(); 
        }
    }

  

});

$.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
}