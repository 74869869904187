//to use spinner functionnality, your button's text must be in a label
function turnOnSpinner(btn) {
  var spinner = getSpinnerFromBtn(btn);
  if (!spinner.length) spinner = appendSpinnerAndFetchIt(btn);

  var label = getLabelFromBtn(btn);
  disableBtn(btn);

  label.hide();
  spinner.show();
  spinner.removeClass("hide");
}

function turnOffSpinner(btn) {
  var spinner = getSpinnerFromBtn(btn);
  if (!spinner.length) spinner = appendSpinnerAndFetchIt(btn);

  var label = getLabelFromBtn(btn);

  enableButton(btn);
  label.show();
  spinner.hide();
}

function enableButton(btn) {
  btn.removeClass("disabled");
}
function disableBtn(btn) {
  btn.addClass("disabled");
}

function getSpinnerFromBtn(btn) {
  return btn.find("i");
}

function getLabelFromBtn(btn) {
  return btn.find("label");
}

function appendSpinnerAndFetchIt(btn) {
  btn.append('<i class="fa fa-cog fa-spin"></i>');
  return getSpinnerFromBtn(btn);
}
